import { Component, Input, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'email-field',
  templateUrl: './email-field.component.html'
})
export class EmailFieldComponent implements OnChanges {
  @Input() field: FormControl;
  @Input() focus: boolean;
  @Input() disabled: boolean;
  @Input() placeholder = 'name@domain.com';
  @Input() label = 'Email';
  @Input() inputId = 'mail';

  public showDone = false;

  constructor() {}

  ngOnChanges() {
    if (this.disabled) {
      this.field.disable();
    }
  }
}
