"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./account-form.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../to-publish/a-forms/general-error-message/general-error-message.component.ngfactory");
var i3 = require("../to-publish/a-forms/general-error-message/general-error-message.component");
var i4 = require("@angular/common");
var i5 = require("../../core/services/form.service/form.service");
var i6 = require("../../core/services/api.service/api.service");
var i7 = require("../../core/services/bug-tracker.service/bug-tracker.service");
var i8 = require("./account-form.component");
var styles_AccountFormComponent = [i0.styles];
var RenderType_AccountFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountFormComponent, data: {} });
exports.RenderType_AccountFormComponent = RenderType_AccountFormComponent;
function View_AccountFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_AccountFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "general-error-message", [], null, null, null, i2.View_GeneralErrorMessageComponent_0, i2.RenderType_GeneralErrorMessageComponent)), i1.ɵdid(1, 114688, null, 0, i3.GeneralErrorMessageComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AccountFormComponent_2)), i1.ɵdid(3, 802816, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.errors; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AccountFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "form_page_wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "form_page"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { wide: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "ico_atlaz"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [["class", "form_page_wrap_h3 center"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountFormComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "form_page"; var currVal_1 = _ck(_v, 3, 0, _co.wideForm); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.isError; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 6, 0, currVal_2); }); }
exports.View_AccountFormComponent_0 = View_AccountFormComponent_0;
function View_AccountFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "account-form", [], null, null, null, View_AccountFormComponent_0, RenderType_AccountFormComponent)), i1.ɵprd(131584, null, i5.FormService, i5.FormService, [i6.AtlazApiService, i7.BugTrackerService]), i1.ɵdid(2, 114688, null, 0, i8.AccountFormComponent, [i5.FormService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
exports.View_AccountFormComponent_Host_0 = View_AccountFormComponent_Host_0;
var AccountFormComponentNgFactory = i1.ɵccf("account-form", i8.AccountFormComponent, View_AccountFormComponent_Host_0, { title: "title", isError: "isError", errors: "errors", wideForm: "wideForm" }, {}, ["*"]);
exports.AccountFormComponentNgFactory = AccountFormComponentNgFactory;
