export const ROUTER_PATH = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  CHOOSE_COMPANY: 'companies',
  SIGN_UP: 'sign-up',
  CREATE_ACCOUNT: 'account',
  CREATE_COMPANY: 'company',
  CHECK_EMAIL: 'check-email',
  FORGOT: 'restore-password',
  SEND_EMAIL: 'send-email',
  SUCCESS: 'success',
  API_ACCESS: 'api-access',
  INVITE: 'invite',
  PROFILE: 'profile',
  CREATE_DEMO: 'create-demo',
  CONFIRM_EMAIL: 'confirm-email',
  INVITE_LINK: 'invite-by-link',
  GOOGLE_SSO: 'google-sso'
};
