"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var KEY_ESCAPE = 27;
var APopupComponent = /** @class */ (function () {
    function APopupComponent(_eref) {
        this._eref = _eref;
        this.close = new core_1.EventEmitter();
    }
    APopupComponent.prototype.onCancelContextPopup = function () {
        this.close.emit(null);
    };
    APopupComponent.prototype.onClick = function (event) {
        if (event.target === this._eref.nativeElement) {
            event.stopPropagation();
            event.stopImmediatePropagation();
            this.onCancelContextPopup();
            return false;
        }
    };
    APopupComponent.prototype.onKeyUP = function (event) {
        return this.onKeyHandler(event);
    };
    APopupComponent.prototype.onKeyHandler = function (event) {
        if (event.keyCode === KEY_ESCAPE) {
            if (event.preventDefault) {
                event.preventDefault();
            }
            event.stopPropagation();
            event.stopImmediatePropagation();
            this.onCancelContextPopup();
            return false;
        }
    };
    return APopupComponent;
}());
exports.APopupComponent = APopupComponent;
