import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  template: '<a-payment-required [message]="message"></a-payment-required>'
})
export class PaymentRequiredPageComponent implements OnInit {
  public message: string;
  constructor(private _route: ActivatedRoute) { }

  ngOnInit() {
    this.message = this._route.snapshot.queryParams['message'];
  }
}
