"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var GeneralErrorMessageComponent = /** @class */ (function () {
    function GeneralErrorMessageComponent() {
    }
    GeneralErrorMessageComponent.prototype.ngOnInit = function () {
    };
    return GeneralErrorMessageComponent;
}());
exports.GeneralErrorMessageComponent = GeneralErrorMessageComponent;
