"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("../40x-page.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./a-bad-request.component");
var styles_ABadRequestComponent = [i0.styles];
var RenderType_ABadRequestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ABadRequestComponent, data: {} });
exports.RenderType_ABadRequestComponent = RenderType_ABadRequestComponent;
function View_ABadRequestComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "error_page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "error_page_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "error_page_img"], ["src", "/assets/img/404-error.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }); }
exports.View_ABadRequestComponent_0 = View_ABadRequestComponent_0;
function View_ABadRequestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a-bad-request", [], null, null, null, View_ABadRequestComponent_0, RenderType_ABadRequestComponent)), i1.ɵdid(1, 114688, null, 0, i2.ABadRequestComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ABadRequestComponent_Host_0 = View_ABadRequestComponent_Host_0;
var ABadRequestComponentNgFactory = i1.ɵccf("a-bad-request", i2.ABadRequestComponent, View_ABadRequestComponent_Host_0, { message: "message" }, {}, []);
exports.ABadRequestComponentNgFactory = ABadRequestComponentNgFactory;
