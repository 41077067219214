export const sortBy = <T>(prop: (item: T) => any) => (items: T[]) => (
  items.sort(function (a, b) {
    const aField = prop(a);
    const bField = prop(b);

    if (aField > bField) {
      return 1;
    }
    if (aField < bField) {
      return -1;
    }
    // a должно быть равным b
    return 0;
  })
);
