"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var root_path_guard_service_1 = require("./services/guards/root-path-guard.service");
var login_form_guard_serivce_1 = require("./services/guards/login-form-guard.serivce");
var logout_guard_service_1 = require("./services/guards/logout-guard.service");
var create_account_guard_service_1 = require("./services/guards/create-account-guard.service");
var choose_company_guard_1 = require("./services/guards/choose-company.guard");
var AccountModule = /** @class */ (function () {
    function AccountModule() {
    }
    AccountModule.forRoot = function () {
        return {
            ngModule: AccountModule,
            providers: [
                root_path_guard_service_1.RootPathGuard,
                logout_guard_service_1.LogoutGuard,
                login_form_guard_serivce_1.LoginFormGuard,
                create_account_guard_service_1.CreateAccountGuard,
                choose_company_guard_1.ChooseCompanyGuard
            ]
        };
    };
    return AccountModule;
}());
exports.AccountModule = AccountModule;
