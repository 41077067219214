"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var account_form_component_1 = require("./account-form/account-form.component");
var form_select_component_1 = require("./form-select/form-select.component");
var password_field_component_1 = require("./account-form/password-field/password-field.component");
var email_field_component_1 = require("./account-form/email-field/email-field.component");
var check_email_component_1 = require("./check-email/check-email.component");
var focus_directive_1 = require("./focus.directive");
var firstname_field_component_1 = require("./account-form/firstname-field/firstname-field.component");
var lastname_field_component_1 = require("./account-form/lastname-field/lastname-field.component");
var left_container_component_1 = require("./containers/left-container/left-container.component");
var header_container_component_1 = require("./containers/header-container/header-container.component");
var a_not_found_component_1 = require("./40x-page/a-not-found/a-not-found.component");
var a_forbidden_component_1 = require("./40x-page/a-forbidden/a-forbidden.component");
var a_bad_request_component_1 = require("./40x-page/a-bad-request/a-bad-request.component");
var a_payment_required_component_1 = require("./40x-page/a-payment-required/a-payment-required.component");
var privacy_agree_field_component_1 = require("./account-form/privacy-agree-field/privacy-agree-field.component");
var click_out_directive_1 = require("./click-out/click-out.directive");
var SHARED_DECLARATIONS = [
    account_form_component_1.AccountFormComponent,
    form_select_component_1.FormSelectComponent,
    email_field_component_1.EmailFieldComponent,
    password_field_component_1.PasswordFieldComponent,
    check_email_component_1.CheckEmailComponent,
    focus_directive_1.FocusDirective,
    click_out_directive_1.ClickOutDirective,
    firstname_field_component_1.FirstnameFieldComponent,
    lastname_field_component_1.LastNameFieldComponent,
    left_container_component_1.LeftContainerComponent,
    header_container_component_1.HeaderContainerComponent,
    a_not_found_component_1.NotFoundComponent,
    a_forbidden_component_1.ForbiddenComponent,
    a_payment_required_component_1.PaymentRequiredComponent,
    a_bad_request_component_1.ABadRequestComponent,
    privacy_agree_field_component_1.PrivacyAgreeFieldComponent
];
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    SharedModule.forRoot = function () {
        return {
            ngModule: SharedModule
        };
    };
    return SharedModule;
}());
exports.SharedModule = SharedModule;
