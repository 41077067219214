"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ng_gapi_1 = require("ng-gapi");
var auth_service_1 = require("../../core/services/auth.service/auth.service");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var api_service_1 = require("../../core/services/api.service/api.service");
var url_1 = require("../../util/url");
var router_1 = require("@angular/router");
var segment_service_1 = require("../../core/services/intergations/segment/segment.service");
var GoogleSsoComponent = /** @class */ (function () {
    function GoogleSsoComponent(_ngZone, _googleAuthService, _authService, _apiService, _route, _segment) {
        this._ngZone = _ngZone;
        this._googleAuthService = _googleAuthService;
        this._authService = _authService;
        this._apiService = _apiService;
        this._route = _route;
        this._segment = _segment;
        this.activeUser$ = this._authService.activeUser$;
    }
    GoogleSsoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.companyId = Number(this._route.snapshot.queryParams['companyId']);
        this._googleAuthService.getAuth().subscribe(function (auth) {
            auth.signIn().then(function (googleUser) {
                _this._ngZone.run(function () {
                    _this.updateAuthenticationMode({
                        company: _this.companyId,
                        authenticationMode: 1,
                        googleToken: googleUser.getAuthResponse().id_token
                    });
                });
            }, function (error) {
                _this._ngZone.run(function () {
                    console.warn('GoogleSsoComponent: Google authentication error');
                    console.error(error);
                });
            });
        });
    };
    GoogleSsoComponent.prototype.updateAuthenticationMode = function (payload) {
        var _this = this;
        this.activeUser$
            .pipe(operators_1.map(function (user) { return user.attributes.companyDomainId; }), operators_1.switchMap(function (id) { return rxjs_1.of(id); }), operators_1.take(1), operators_1.switchMap(function (id) {
            return _this._apiService.patch(['company-domains', id], payload);
        }))
            .subscribe(function (resp) {
            _this._segment.track('AuthenticationMode', { source: 'google' });
            _this.redirect();
        }, function (error) {
            console.warn('CHANGE AUTHENTICATION MODE', payload);
            console.log('PAYLOAD: ', payload);
            console.error(error);
            _this.redirect();
        });
    };
    GoogleSsoComponent.prototype.redirect = function () {
        var _this = this;
        this.activeUser$
            .pipe(operators_1.switchMap(function (user) { return user.getCompanies(); }), operators_1.filter(function (company) { return company.id === _this.companyId; }), operators_1.take(1))
            .subscribe(function (company) {
            url_1.goToAtlazWeb(company, '/company-settings/general');
        });
    };
    return GoogleSsoComponent;
}());
exports.GoogleSsoComponent = GoogleSsoComponent;
