"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var api_service_1 = require("../api.service/api.service");
var object_1 = require("../../../util/object");
var bug_tracker_service_1 = require("../bug-tracker.service/bug-tracker.service");
var operators_1 = require("rxjs/operators");
var FormStatuses;
(function (FormStatuses) {
    FormStatuses[FormStatuses["available"] = 1] = "available";
    FormStatuses[FormStatuses["pending"] = 2] = "pending";
    FormStatuses[FormStatuses["error"] = 3] = "error";
})(FormStatuses = exports.FormStatuses || (exports.FormStatuses = {}));
var FormSaveType;
(function (FormSaveType) {
    FormSaveType[FormSaveType["add"] = 1] = "add";
    FormSaveType[FormSaveType["edit"] = 2] = "edit";
})(FormSaveType = exports.FormSaveType || (exports.FormSaveType = {}));
var FormService = /** @class */ (function () {
    function FormService(_atlazApi, _bugTracker) {
        var _this = this;
        this._atlazApi = _atlazApi;
        this._bugTracker = _bugTracker;
        this.errors$ = new rxjs_1.BehaviorSubject([]);
        this.requestDetails = {};
        /**
         * general list of errors
         */
        this._errors = [];
        this.formServiceObserver = {
            next: function (x) { },
            error: function (error) {
                // mark as error
                _this._errors = [];
                _this.markAsError();
                _this.setFormFieldsError(error);
                _this._form.valueChanges.pipe(operators_1.take(1)).subscribe(function (_) {
                    _this.removeError('commonError');
                    _this.removeError('authorize_params');
                    _this.removeError('users_not_provided_register_token');
                });
                _this._errors
                    .map(object_1.prop('field'))
                    .filter(function (field) { return field !== 'commonError'; })
                    .map(function (field) { return [field, _this._form.get(field)]; })
                    .filter(function (_a) {
                    var field = _a[0], formControl = _a[1];
                    return !!formControl;
                })
                    .forEach(function (_a) {
                    var field = _a[0], formControl = _a[1];
                    return formControl.valueChanges
                        .pipe(operators_1.take(1))
                        .subscribe(function (_) { return _this.removeError(field); });
                });
            },
            complete: function () {
                // somtething
                _this.markAsAvailable();
                console.log('form observer complete');
            }
        };
        this.observerContainer = [this.formServiceObserver];
        this._formStatus$ = new rxjs_1.BehaviorSubject(FormStatuses.available);
    }
    FormService.prototype.ngOnDestroy = function () {
        console.log('FormService Destroy');
    };
    FormService.prototype.initFormParams = function (form, formServiceParams) {
        this._form = form;
        this._formParams = formServiceParams;
        this.resetObserversToDefault();
        this.registerObserver(formServiceParams.formObserver);
    };
    Object.defineProperty(FormService.prototype, "isPending$", {
        get: function () {
            return this._formStatus$.pipe(operators_1.map(this.isPending));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormService.prototype, "isError$", {
        get: function () {
            return this._formStatus$.pipe(operators_1.map(this.isError));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormService.prototype, "isAvailable$", {
        get: function () {
            return this._formStatus$.pipe(operators_1.map(this.isAvailable));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormService.prototype, "pendingSnapshot", {
        get: function () {
            return this.isPending(this._formStatus$.getValue());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormService.prototype, "availableSnapshot", {
        get: function () {
            return this.isAvailable(this._formStatus$.getValue());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormService.prototype, "errorSnapshot", {
        get: function () {
            return this.isError(this._formStatus$.getValue());
        },
        enumerable: true,
        configurable: true
    });
    FormService.prototype.submit = function () {
        this.markAsPending();
        var submitData$ = this.sendRequest().pipe(operators_1.publishReplay(1), operators_1.refCount());
        // we don't need unsubscribe because submitData$ stream will be complete after receiving response
        this.observerContainer.forEach(function (observer) { return submitData$.subscribe(observer); });
    };
    FormService.prototype.registerObserver = function (observer) {
        this.observerContainer = this.observerContainer.concat([observer]);
    };
    FormService.prototype.unRegisterObserver = function (observer) {
        this.observerContainer = this.observerContainer.filter(function (item) { return item !== observer; });
    };
    FormService.prototype.resetObserversToDefault = function () {
        this.observerContainer = [this.formServiceObserver];
    };
    FormService.prototype.markAsAvailable = function () {
        this.markAs(FormStatuses.available);
    };
    FormService.prototype.markAsError = function () {
        this.markAs(FormStatuses.error);
    };
    FormService.prototype.markAsPending = function () {
        this.markAs(FormStatuses.pending);
    };
    FormService.prototype.markAsDirty = function () {
        var _this = this;
        Object.keys(this._form.controls).map(function (controlName) {
            _this._form.get(controlName).markAsDirty();
            _this._form.get(controlName).markAsTouched();
            _this._form.get(controlName).updateValueAndValidity();
        });
    };
    FormService.prototype.sendRequest = function () {
        var value = this.prepareFormValue();
        var httpQueryParams = this._formParams.httpQueryParams || {};
        var path = api_service_1.patchPath(this._formParams.entityToEdit, httpQueryParams);
        switch (this._formParams.saveType) {
            case FormSaveType.add: {
                this.requestDetails = {
                    request: 'post',
                    url: path,
                    payload: value
                };
                return this._atlazApi.post(path, value, this._formParams.responseParser);
            }
            case FormSaveType.edit: {
                try {
                    if (!value.hasOwnProperty('id')) {
                        throw new Error("Invalid form fields. Form must contain id property");
                    }
                    this.requestDetails = {
                        request: 'patch',
                        url: path,
                        payload: value
                    };
                    return this._atlazApi.patch(path, value, this._formParams.responseParser, this._formParams.requestParser);
                }
                catch (e) {
                    console.error(e);
                    return rxjs_1.throwError(e);
                }
            }
            default: {
                return rxjs_1.throwError(new Error('unexpected value of saveType property. Only "add" "edit" types allowed'));
            }
        }
    };
    FormService.prototype.prepareFormValue = function () {
        return this._formParams.hasOwnProperty('prepareFormValue')
            ? this._formParams.prepareFormValue(this._form.value)
            : this._form.value;
    };
    FormService.prototype.isPending = function (status) {
        return FormStatuses.pending === status;
    };
    FormService.prototype.isError = function (status) {
        return FormStatuses.error === status;
    };
    FormService.prototype.isAvailable = function (status) {
        return FormStatuses.available === status;
    };
    FormService.prototype.markAs = function (status) {
        this._formStatus$.next(status);
    };
    FormService.prototype.logError = function (err, message) {
        try {
            console.log('API RESPONSE: ', JSON.stringify(err));
        }
        catch (e) { }
        console.warn('requestDetails', this.requestDetails);
        var msg = [message, err.toString()].join('\n');
        this._bugTracker.warn(msg);
    };
    FormService.prototype.normalizeServerErrorResponse = function (originalError) {
        if (!originalError && !originalError.error) {
            // front error
            this.logError(originalError, 'FrontEnd message');
            return {
                message: 'Unknown error. Please contact support if the problem persists.'
            };
        }
        var error;
        try {
            error = originalError.error;
        }
        catch (e) { }
        if (!error || !object_1.isObject(error)) {
            // failed to parse error code
            this.logError(originalError, 'Internal Server error');
            return {
                message: 'Internal Server error. Please contact support if the problem persists.'
            };
        }
        if (!error.errors && !error.message) {
            this.logError(originalError, 'Unknown Server error');
            return {
                message: 'Unknown error. Please contact support if the problem persists.'
            };
        }
        return error;
    };
    FormService.prototype.setGeneralError = function (message) {
        this.removeError('commonError');
        this.addError({ message: message, field: 'commonError' });
    };
    FormService.prototype.setFormFieldsError = function (originalError) {
        var error = this.normalizeServerErrorResponse(originalError);
        if (error.hasOwnProperty('errors')) {
            this.markAsInvalidFormFields(error['errors']);
        }
        else if (error.hasOwnProperty('message') && error['message']) {
            this.addError(__assign({}, error, { field: 'commonError' }));
        }
    };
    FormService.prototype.addError = function (error) {
        this._errors = this._errors.concat([error]);
        this.errors$.next(this._errors.map(function (e) { return e.message; }));
    };
    FormService.prototype.removeError = function (field) {
        this._errors = this._errors.filter(function (error) { return error.field !== field; });
        this.errors$.next(this._errors.map(function (e) { return e.message; }));
    };
    FormService.prototype.markAsInvalidFormFields = function (errors) {
        errors.forEach(this.markAsInvalidFormField.bind(this));
    };
    FormService.prototype.markAsInvalidFormField = function (error) {
        var field = this._form.get(error.field);
        if (!!field) {
            this.addError(error);
            field.markAsTouched();
            field.setErrors({ serverError: true });
        }
        else {
            this.addError(__assign({}, error, { field: error.code }));
        }
    };
    return FormService;
}());
exports.FormService = FormService;
