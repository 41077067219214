"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./login-form.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../shared/account-form/account-form.component.ngfactory");
var i3 = require("../../core/services/form.service/form.service");
var i4 = require("../../core/services/api.service/api.service");
var i5 = require("../../core/services/bug-tracker.service/bug-tracker.service");
var i6 = require("../../shared/account-form/account-form.component");
var i7 = require("@angular/common");
var i8 = require("@angular/forms");
var i9 = require("../../shared/account-form/email-field/email-field.component.ngfactory");
var i10 = require("../../shared/account-form/email-field/email-field.component");
var i11 = require("../../shared/focus.directive");
var i12 = require("../../shared/account-form/password-field/password-field.component.ngfactory");
var i13 = require("../../shared/account-form/password-field/password-field.component");
var i14 = require("../../atlaz-gui/components/a-button/a-button.component.ngfactory");
var i15 = require("../../atlaz-gui/components/a-button/a-button.component");
var i16 = require("@angular/router");
var i17 = require("./login-form.component");
var i18 = require("../../core/services/auth.service/auth.service");
var i19 = require("../services/guards/login-form-guard.serivce");
var i20 = require("ng-gapi/lib/GoogleAuthService");
var i21 = require("../../core/services/intergations/segment/segment.service");
var styles_LoginFormComponent = [i0.styles];
var RenderType_LoginFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginFormComponent, data: {} });
exports.RenderType_LoginFormComponent = RenderType_LoginFormComponent;
function View_LoginFormComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "account-form", [], null, null, null, i2.View_AccountFormComponent_0, i2.RenderType_AccountFormComponent)), i1.ɵprd(131584, null, i3.FormService, i3.FormService, [i4.AtlazApiService, i5.BugTrackerService]), i1.ɵdid(2, 114688, null, 0, i6.AccountFormComponent, [i3.FormService], { title: [0, "title"], isError: [1, "isError"], errors: [2, "errors"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "button", [["class", "google_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGoogleSignIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "google_logo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Log in with Google "])), (_l()(), i1.ɵeld(8, 0, null, 0, 13, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i8.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(10, 540672, null, 0, i8.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i8.ControlContainer, null, [i8.FormGroupDirective]), i1.ɵdid(12, 16384, null, 0, i8.NgControlStatusGroup, [[4, i8.ControlContainer]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "email-field", [], null, null, null, i9.View_EmailFieldComponent_0, i9.RenderType_EmailFieldComponent)), i1.ɵdid(14, 573440, null, 0, i10.EmailFieldComponent, [], { field: [0, "field"], focus: [1, "focus"] }, null), i1.ɵdid(15, 4210688, null, 0, i11.FocusDirective, [i1.ElementRef], { focus: [0, "focus"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "password-field", [["placeholder", "Password"]], null, null, null, i12.View_PasswordFieldComponent_0, i12.RenderType_PasswordFieldComponent)), i1.ɵdid(17, 49152, null, 0, i13.PasswordFieldComponent, [], { field: [0, "field"], hintType: [1, "hintType"], placeholder: [2, "placeholder"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 3, "button", [["a-button", ""], ["class", "btn_primary"]], [[2, "loader", null], [1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).clickBtnEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onSubmit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_AButtonComponent_0, i14.RenderType_AButtonComponent)), i1.ɵdid(19, 49152, null, 0, i15.AButtonComponent, [], { pending: [0, "pending"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, 0, [" Log In "])), (_l()(), i1.ɵeld(22, 0, null, 0, 2, "button", [["class", "text_btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 16384, null, 0, i16.RouterLink, [i16.Router, i16.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Sign Up"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Log In to Hygger"; var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_co._formService.isError$)); var currVal_2 = i1.ɵunv(_v, 2, 2, i1.ɵnov(_v, 4).transform(_co._formService.errors$)); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_10 = _co.form; _ck(_v, 10, 0, currVal_10); var currVal_11 = _co.form.get("email"); var currVal_12 = true; _ck(_v, 14, 0, currVal_11, currVal_12); var currVal_13 = true; _ck(_v, 15, 0, currVal_13); var currVal_14 = _co.form.get("password"); var currVal_15 = "forgot"; var currVal_16 = "Password"; _ck(_v, 17, 0, currVal_14, currVal_15, currVal_16); var currVal_19 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform(_co._formService.isPending$)); _ck(_v, 19, 0, currVal_19); var currVal_20 = _co.toSignUp; _ck(_v, 23, 0, currVal_20); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 12).ngClassValid; var currVal_8 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_17 = i1.ɵnov(_v, 19).isPending; var currVal_18 = i1.ɵnov(_v, 19).isDisabled; _ck(_v, 18, 0, currVal_17, currVal_18); }); }
exports.View_LoginFormComponent_0 = View_LoginFormComponent_0;
function View_LoginFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ng-component", [], null, null, null, View_LoginFormComponent_0, RenderType_LoginFormComponent)), i1.ɵprd(131584, null, i3.FormService, i3.FormService, [i4.AtlazApiService, i5.BugTrackerService]), i1.ɵdid(2, 114688, null, 0, i17.LoginFormComponent, [i3.FormService, i18.AuthService, i19.LoginFormGuard, i1.NgZone, i8.FormBuilder, i16.Router, i4.AtlazApiService, i20.GoogleAuthService, i21.SegmentService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
exports.View_LoginFormComponent_Host_0 = View_LoginFormComponent_Host_0;
var LoginFormComponentNgFactory = i1.ɵccf("ng-component", i17.LoginFormComponent, View_LoginFormComponent_Host_0, {}, {}, []);
exports.LoginFormComponentNgFactory = LoginFormComponentNgFactory;
