<div class="header_wrapper">
  <div class="center">
    <div class="logo"></div>
  </div>
  <div class="right_block">
    <div class="user_menu_block" (click)="onToggleMenu()">
      <div class="user_avatar">
        <a-avatar [user]="user"></a-avatar>
      </div>
      <div tabindex="-1" (close)="onToggleMenu()" *ngIf="isUserMenuVisible" a-context-menu class="profile_menu">
        <div a-context-menu-item [routerLink]="toUserProfile" routerLinkActive="active">Profile</div>
        <div a-context-menu-item [routerLink]="toLogout">Log Out</div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</div>
