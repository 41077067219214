"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var searchText = function (text, key) {
    return key !== 'backspace' ? text + key : text.slice(0, -1);
};
var ɵ0 = searchText;
exports.ɵ0 = ɵ0;
var isBackspace = function (keyCode) { return keyCode === 8; };
var ɵ1 = isBackspace;
exports.ɵ1 = ɵ1;
var printableCode = function (keyCode) {
    return (keyCode > 47 && keyCode < 58) || // number keys
        keyCode == 32 || // spacebar & return key(s) (if you want to allow carriage returns)
        (keyCode > 64 && keyCode < 91) || // letter keys
        (keyCode > 95 && keyCode < 112) || // numpad keys
        (keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
        (keyCode > 218 && keyCode < 223);
}; // [\]' (in order)
var ɵ2 = printableCode;
exports.ɵ2 = ɵ2;
var FormSelectComponent = /** @class */ (function () {
    function FormSelectComponent(_eref) {
        this._eref = _eref;
        this.items = [];
        this.tabIndex = -1;
        this.selectItem = new core_1.EventEmitter();
        this.tabindex = this.tabIndex;
        this.searchString$ = new rxjs_1.Subject();
        this.showDropdown = false;
        this.filterFn = function (n) { return n; };
    }
    FormSelectComponent.prototype.onFilter = function (event) {
        if (printableCode(event.keyCode) || isBackspace(event.keyCode)) {
            this.searchString$.next(event.key.toLowerCase());
        }
    };
    FormSelectComponent.prototype.onClick = function (event) {
        if (event.target === this._eref.nativeElement ||
            this.selectTitle.nativeElement.contains(event.target)) {
            this.onToggleDropdown();
        }
    };
    FormSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchString$.pipe(operators_1.scan(searchText, '')).subscribe(function (searchText) {
            console.log(searchText, 'sText');
            _this.visibleItems = _this.filterFn(_this.items, searchText);
        });
    };
    FormSelectComponent.prototype.ngOnChanges = function () {
        this.visibleItems = this.items || [];
    };
    FormSelectComponent.prototype.ngAfterViewInit = function () {
        // don't use me yet
        // this._eref.nativeElement.focus(true);
    };
    FormSelectComponent.prototype.onSelectItem = function (item) {
        this.selectedItem = item;
        this.selectItem.emit(this.selectedItem);
        this.showDropdown = false;
        return false;
    };
    FormSelectComponent.prototype.onToggleDropdown = function () {
        this.showDropdown = !this.showDropdown;
    };
    return FormSelectComponent;
}());
exports.FormSelectComponent = FormSelectComponent;
