"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var api_service_1 = require("./core/services/api.service/api.service");
var auth_service_1 = require("./core/services/auth.service/auth.service");
var user_1 = require("./core/models/user");
var object_1 = require("./util/object");
var constants_1 = require("./constants");
var operators_1 = require("rxjs/operators");
var environment_1 = require("../environments/environment");
function redirectToSignUp(router) {
    return function (route) {
        router.navigate(['sign-up', 'invite'], __assign({}, route.queryParams));
        return true;
    };
}
exports.redirectToSignUp = redirectToSignUp;
function redirectToProfileEmail(router, atlazApi, authService) {
    return function (route) {
        var token = route.queryParams['changeEmailToken'];
        return authService.activeUser$.pipe(operators_1.filter(object_1.isPresent), operators_1.pluck('id'), operators_1.take(1), operators_1.switchMap(function (userId) {
            return atlazApi.patch([
                'users',
                userId,
                { expand: ['usersCompanies', 'usersCompanies.company'] }
            ], token, api_service_1.bodyParser, api_service_1.confirmNewEmail);
        }), operators_1.tap(function (res) {
            authService.activeUser$.next(new user_1.User(res));
            router.navigate([constants_1.ROUTER_PATH.PROFILE, 'email'], {
                fragment: 'changeSuccess'
            });
        }));
    };
}
exports.redirectToProfileEmail = redirectToProfileEmail;
var ɵ0 = {
    client_id: environment_1.environment.googleClientId,
    discoveryDocs: [
        'https://analyticsreporting.googleapis.com/$discovery/rest?version=v4'
    ],
    ux_mode: 'popup',
    scope: ['https://www.googleapis.com/auth/userinfo.profile'].join(' ')
};
exports.ɵ0 = ɵ0;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
