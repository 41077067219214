import { Component, Input, OnInit } from '@angular/core';
import { FormService } from '../../core/services/form.service/form.service';

@Component({
  selector: 'account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
  providers: [FormService]
})
export class AccountFormComponent implements OnInit {
  @Input() title: string;
  @Input() isError = false;
  @Input() errors: string[] = [];
  @Input() wideForm: boolean;

  constructor(public _formService: FormService) {}

  ngOnInit() {}
}
