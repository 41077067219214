import * as Rollbar from 'rollbar';
import { Injectable, InjectionToken } from '@angular/core';

export const ROLLBAR_TOKEN = new InjectionToken('AtlazRollbar');

const logger = (level, history: any[]) => (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
  switch (level) {
    case 'warn':
    case 'warning':
      args.forEach(v => console.warn(v));
      break;

    case 'critical':
      console.warn('critical');
      args.forEach(v => console.error(v));
      break;
    case 'error':
      args.forEach(v => console.error(v));
      break;
    default:
      args.forEach(v => console.log(v));
  }
  const logResult = {
    level,
    uuid: 'u',
    args
  };
  history.push(logResult);
  return logResult;
};

@Injectable()
export class RollbarDymmy implements Rollbar {
  private history = [];
  constructor(options?: Rollbar.Configuration) {}
  static init(options: Rollbar.Configuration): Rollbar {
    return new RollbarDymmy(options);
  }

  public global(options: Rollbar.Configuration): Rollbar {
    return this;
  }
  public configure(options: Rollbar.Configuration): Rollbar {
    return this;
  }
  public lastError(): Rollbar.MaybeError {
    if (this.history.length) {
      return this.history[this.history.length - 1].args[0];
    }
    return null;
  }

  public log = logger('log', this.history);
  public debug = logger('debug', this.history);
  public info = logger('info', this.history);
  public warn = logger('warn', this.history);
  public warning = logger('warning', this.history);
  public error = logger('error', this.history);
  public critical = logger('critical', this.history);

  public captureEvent(metadata: object, level: Rollbar.Level): Rollbar.TelemetryEvent {
    return {
      level: level,
      type: 'dymmy',
      timestamp_ms: 0,
      body: {},
      source: ''
    };
  }

  public lambdaHandler(handler: Rollbar.LambdaHandler): Rollbar.LambdaHandler {
    return (event: object, context: object, callback: Rollbar.Callback) => undefined;
  }
}
