import { Component, Input } from '@angular/core';

@Component({
  selector: 'a-forbidden',
  templateUrl: './a-forbidden.component.html',
  styleUrls: ['../40x-page.component.scss'],
})
export class ForbiddenComponent {

  @Input() message: string

}
