"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("../40x-page.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./a-not-found.component");
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
exports.RenderType_NotFoundComponent = RenderType_NotFoundComponent;
function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "error_page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "error_page_name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "error_page_img"], ["src", "/assets/img/404-error.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "error_page_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Whoops!"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "error_page_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The page you are looking for is not available!"]))], null, null); }
exports.View_NotFoundComponent_0 = View_NotFoundComponent_0;
function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i2.NotFoundComponent, [], null, null)], null, null); }
exports.View_NotFoundComponent_Host_0 = View_NotFoundComponent_Host_0;
var NotFoundComponentNgFactory = i1.ɵccf("a-not-found", i2.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
exports.NotFoundComponentNgFactory = NotFoundComponentNgFactory;
