<account-form [title]="'Log In to Hygger'"
              [isError]="_formService.isError$ | async"
              [errors]="_formService.errors$ | async"
>

    <button class="google_button" (click)="onGoogleSignIn()">
        <span class="google_logo">
        </span>
        Log in with Google
    </button>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <email-field [focus]="true" [field]="form.get('email')"></email-field>

      <password-field [field]="form.get('password')" [hintType]="'forgot'" placeholder="Password"></password-field>
      <button a-button class="btn_primary" [pending]="_formService.isPending$ | async" (click)="onSubmit()">
        Log In
      </button>
    </form>
    <button class="text_btn" [routerLink]="toSignUp">Sign Up</button>
</account-form>
