"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../atlaz-gui/components/a-loader/a-loader.component.ngfactory");
var i2 = require("../../../atlaz-gui/components/a-loader/a-loader.component");
var i3 = require("../../../shared/40x-page/a-bad-request/a-bad-request.component.ngfactory");
var i4 = require("../../../shared/40x-page/a-bad-request/a-bad-request.component");
var i5 = require("@angular/common");
var i6 = require("./create-demo.component");
var i7 = require("@angular/router");
var i8 = require("../../services/api.service/api.service");
var styles_CreateDemoComponent = [];
var RenderType_CreateDemoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateDemoComponent, data: {} });
exports.RenderType_CreateDemoComponent = RenderType_CreateDemoComponent;
function View_CreateDemoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a-loader", [], null, null, null, i1.View_ALoaderComponent_0, i1.RenderType_ALoaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.ALoaderComponent, [], null, null)], null, null); }
function View_CreateDemoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a-bad-request", [], null, null, null, i3.View_ABadRequestComponent_0, i3.RenderType_ABadRequestComponent)), i0.ɵdid(1, 114688, null, 0, i4.ABadRequestComponent, [], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error.message; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CreateDemoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateDemoComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["errorMessage", 2]], null, 0, null, View_CreateDemoComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.error === undefined); var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
exports.View_CreateDemoComponent_0 = View_CreateDemoComponent_0;
function View_CreateDemoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "create-demo", [], null, null, null, View_CreateDemoComponent_0, RenderType_CreateDemoComponent)), i0.ɵdid(1, 114688, null, 0, i6.CreateDemoComponent, [i7.ActivatedRoute, i8.AtlazApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CreateDemoComponent_Host_0 = View_CreateDemoComponent_Host_0;
var CreateDemoComponentNgFactory = i0.ɵccf("create-demo", i6.CreateDemoComponent, View_CreateDemoComponent_Host_0, {}, {}, []);
exports.CreateDemoComponentNgFactory = CreateDemoComponentNgFactory;
