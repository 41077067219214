"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var throwError_1 = require("rxjs/internal/observable/throwError");
var http_1 = require("@angular/common/http");
var object_1 = require("../../../util/object");
var environment_1 = require("../../../../environments/environment");
var operators_1 = require("rxjs/operators");
exports.CHECK_EMAIL = 'check-email';
var getExperimentalApiUrl = function () { return environment_1.environment.apiUrl; };
var ɵ0 = getExperimentalApiUrl;
exports.ɵ0 = ɵ0;
exports.patchPath = function (path, tail) {
    path = Array.isArray(path) ? path.slice() : [path];
    if (tail) {
        path.push(tail);
    }
    return path;
};
var pathFromArray = function (slugs) {
    var clearSlugs = [];
    var params = {};
    slugs.forEach(function (slug) {
        object_1.isObject(slug) ? Object.assign(params, slug) : clearSlugs.push(slug);
    });
    var strParams = '?' +
        Object.keys(params)
            .map(function (key) {
            return Array.isArray(params[key])
                ? key === 'expand'
                    ? key + '=' + encodeURIComponent(params[key].join(','))
                    : params[key]
                        .map(function (value) { return key + '[]=' + encodeURIComponent(value); })
                        .join('&')
                : key + '=' + encodeURIComponent(params[key]);
        })
            .join('&');
    return clearSlugs.join('/') + (strParams.length > 1 ? strParams : '');
};
var ɵ1 = pathFromArray;
exports.ɵ1 = ɵ1;
exports.bodyParser = function (res) {
    if (res && res['included'] && !Array.isArray(res['included'])) {
        return __assign({}, res, { included: object_1.objectToArray(res['included']) });
    }
    return res;
};
exports.noParse = function (res) { return res; };
exports.commonPatchParser = function (payload) {
    return Object.keys(payload).reduce(function (acc, prop) {
        var value = payload[prop];
        var replaceValue = true;
        if (object_1.isObject(value)) {
            if (Array.isArray(value['add'])) {
                replaceValue = false;
                value['add'].forEach(function (v) {
                    return acc.push({ op: 'add', path: '/' + prop + '/-', value: v });
                });
            }
            if (Array.isArray(value['remove'])) {
                replaceValue = false;
                value['remove'].forEach(function (v) {
                    return acc.push({ op: 'remove', path: '/' + prop + '/' + v });
                });
            }
        }
        if (replaceValue) {
            acc.push({ op: 'replace', path: '/' + prop, value: value });
        }
        return acc;
    }, []);
};
exports.removeFieldParser = function (fieldName) { return [
    {
        op: 'remove',
        path: '/' + fieldName
    }
]; };
exports.replaceWithTest = function (testFields, replaceFields) { return function (payload) {
    testFields = Array.isArray(testFields) ? testFields : [testFields];
    var testOp = testFields.reduce(function (acc, fieldName) {
        var value = payload[fieldName];
        acc.push({ op: 'test', path: '/' + fieldName, value: value });
        return acc;
    }, []);
    replaceFields = Array.isArray(replaceFields)
        ? replaceFields
        : [replaceFields];
    var replaceOp = replaceFields.reduce(function (acc, fieldName) {
        var value = payload[fieldName];
        acc.push({ op: 'replace', path: '/' + fieldName, value: value });
        return acc;
    }, []);
    testFields.forEach(function (name) { return delete payload[name]; });
    replaceFields.forEach(function (name) { return delete payload[name]; });
    return testOp.concat(replaceOp, exports.commonPatchParser(payload));
}; };
exports.onlyTestPayload = function (field) { return function (value) { return [
    {
        op: 'test',
        path: '/' + field,
        value: value
    }
]; }; };
exports.confirmNewEmail = exports.onlyTestPayload('newEmailConfirmToken');
exports.changeEmailPatchParser = exports.replaceWithTest(['password', 'recaptcha'], 'email');
/**
 * @param path
 * @param params
 * @returns {string}
 */
var createUrl = function (path, params) {
    if (params === void 0) { params = {}; }
    if (params['id']) {
        path = exports.patchPath(path, params['id']);
    }
    path = exports.patchPath(path, {});
    return getExperimentalApiUrl() + pathFromArray(path);
};
var ɵ2 = createUrl;
exports.ɵ2 = ɵ2;
/**
 * @experimental
 *
 * @version it's draft version for task
 *
 * should be full implemented in the future
 * @link https://hq.atlaz.io/boards/1(popup:tasks/65867)
 */
var AtlazApiService = /** @class */ (function () {
    function AtlazApiService(_http) {
        this._http = _http;
        this.accessToken = '';
        this.catcher = function (method, payload) { return function (response) {
            console.log('API RESPONSE: ', JSON.stringify(response));
            if (response.statusCode === 401) {
                payload
                    ? console.warn('API', method.toUpperCase(), payload)
                    : console.warn('API', method.toUpperCase());
            }
            else {
                payload
                    ? console.error('API', method.toUpperCase(), payload)
                    : console.error('API', method.toUpperCase());
            }
            return throwError_1.throwError(response);
        }; };
    }
    AtlazApiService.prototype.get = function (path, params) {
        if (params === void 0) { params = {}; }
        path = exports.patchPath(path);
        var queryParams = Object.assign({}, params);
        if (queryParams.hasOwnProperty('id')) {
            delete queryParams['id'];
        }
        path.push(queryParams);
        var url = createUrl(path, queryParams);
        return this._http
            .get(url, this.getOptions({
            'Content-Type': 'application/json',
            'X-HTTP-Method-Override': 'GET'
        }))
            .pipe(operators_1.catchError(this.catcher('get')));
    };
    AtlazApiService.prototype.post = function (path, payload, parseFn, parseRequestFn) {
        if (payload === void 0) { payload = {}; }
        if (parseFn === void 0) { parseFn = exports.noParse; }
        if (parseRequestFn === void 0) { parseRequestFn = exports.noParse; }
        var url = createUrl(path);
        var postPayload = parseRequestFn(payload);
        return this._http
            .post(url, postPayload, this.getOptions({
            'Content-Type': 'application/json',
            'X-HTTP-Method-Override': 'POST'
        }))
            .pipe(operators_1.catchError(this.catcher('post', postPayload)), operators_1.map(parseFn));
    };
    AtlazApiService.prototype.patch = function (path, payload, parseResponseFn, parseRequestFn) {
        if (payload === void 0) { payload = {}; }
        if (parseResponseFn === void 0) { parseResponseFn = exports.noParse; }
        if (parseRequestFn === void 0) { parseRequestFn = exports.commonPatchParser; }
        var url = createUrl(path, payload);
        if (payload.hasOwnProperty('id')) {
            payload = __assign({}, payload);
            delete payload.id;
        }
        var patchPayload = parseRequestFn(payload);
        return this._http
            .patch(url, patchPayload, this.getOptions({
            'Content-Type': 'application/json-patch+json',
            'X-HTTP-Method-Override': 'PATCH'
        }))
            .pipe(operators_1.catchError(this.catcher('patch', patchPayload)), operators_1.map(parseResponseFn));
    };
    AtlazApiService.prototype.deleteRequest = function (path, parseFn) {
        if (parseFn === void 0) { parseFn = exports.noParse; }
        var url = createUrl(path);
        return this._http
            .delete(url, this.getOptions({
            'Content-Type': 'application/json',
            'X-HTTP-Method-Override': 'DELETE'
        }))
            .pipe(operators_1.catchError(this.catcher('delete')), operators_1.map(parseFn));
    };
    AtlazApiService.prototype.getMock = function (path) {
        var url = "assets/" + path;
        return this._http.get(url);
    };
    AtlazApiService.prototype.getOptions = function (customHeaders) {
        if (customHeaders === void 0) { customHeaders = {}; }
        var headers = new http_1.HttpHeaders(__assign({ 'Atlaz-API-Version': '2016-02-29' }, customHeaders));
        return { headers: headers, withCredentials: true };
    };
    return AtlazApiService;
}());
exports.AtlazApiService = AtlazApiService;
