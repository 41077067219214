import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../../../core/models/user';

@Component({
  selector: 'a-avatar',
  templateUrl: './a-avatar.component.html',
  styleUrls: ['./a-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AAvatarComponent {
  @Input() user: User;
  @Input() customPath: string;

  get photoUrl() {
    const userAv =
      this.user &&
      this.user.attributes.photo &&
      this.user.attributes.photo.previews &&
      this.user.attributes.photo.previews.w100
        ? this.user.attributes.photo.previews.w100.path
        : '';
    return this.customPath || userAv;
  }
  get initials() {
    if (this.user) {
      return (
        (this.user.attributes.firstname
          ? this.user.attributes.firstname[0]
          : '') +
        (this.user.attributes.lastname ? this.user.attributes.lastname[0] : '')
      );
    }
    return '';
  }
}
