"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var api_service_1 = require("../../services/api.service/api.service");
var company_1 = require("../../models/company");
var url_1 = require("../../../util/url");
var operators_1 = require("rxjs/operators");
var CreateDemoComponent = /** @class */ (function () {
    function CreateDemoComponent(_route, _atlazApi) {
        this._route = _route;
        this._atlazApi = _atlazApi;
    }
    CreateDemoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._route.queryParams
            .pipe(operators_1.pluck('token'), operators_1.take(1), operators_1.defaultIfEmpty({ token: 'blablabla' }), operators_1.switchMap(function (token) { return _this._atlazApi.post('demo', { token: token }); }
        // use for test redirect
        // token => this._atlazApi.getMock('company-jsonapi.json')
        ))
            .subscribe(function (resp) {
            return url_1.goToAtlazWeb(new company_1.Company(resp));
        }, function (error) {
            var serverError = error;
            console.log(serverError, 'serverError');
            _this.error = serverError;
        });
    };
    return CreateDemoComponent;
}());
exports.CreateDemoComponent = CreateDemoComponent;
