"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var segment_base_service_1 = require("./segment-base.service");
var SegmentService = /** @class */ (function (_super) {
    __extends(SegmentService, _super);
    function SegmentService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SegmentService.prototype.getInstance = function () {
        if (!window['analytics']) {
            console.warn('Segment is missed');
            window['analytics'] = {};
            ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"]
                .forEach(function (mehtod) {
                window['analytics'][mehtod] = function () { return null; };
            });
        }
        return window['analytics'];
    };
    return SegmentService;
}(segment_base_service_1.SegmentBaseService));
exports.SegmentService = SegmentService;
