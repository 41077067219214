import { Injectable } from '@angular/core';

@Injectable()
export class TaboolaService {

  push(value: any) {
    window['_tfa'] = window['_tfa'] || [];
    window['_tfa'].push(value);
  }
}
