"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("../../../core/services/auth.service/auth.service");
var CreateAccountGuard = /** @class */ (function () {
    function CreateAccountGuard(_authService) {
        this._authService = _authService;
    }
    CreateAccountGuard.prototype.canActivate = function (route, state) {
        var userToken = route.params['token'] || '';
        return this._authService.checkSignUp(userToken);
    };
    return CreateAccountGuard;
}());
exports.CreateAccountGuard = CreateAccountGuard;
