import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'privacy-agree-field',
  templateUrl: './privacy-agree-field.component.html',
  styleUrls: ['./privacy-agree-field.component.scss']
})
export class PrivacyAgreeFieldComponent {
  @Input() field: FormControl;
  constructor() {}
}
