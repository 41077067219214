"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["@-webkit-keyframes dinamic_t{0%{-webkit-transform:translate(80px,80px) rotate(1.823867deg);transform:translate(80px,80px) rotate(1.823867deg);-webkit-animation-timing-function:cubic-bezier(.553289,.254604,.514412,.766855);animation-timing-function:cubic-bezier(.553289,.254604,.514412,.766855)}100%{-webkit-transform:translate(80px,80px) rotate(360deg);transform:translate(80px,80px) rotate(360deg)}}@keyframes dinamic_t{0%{-webkit-transform:translate(80px,80px) rotate(1.823867deg);transform:translate(80px,80px) rotate(1.823867deg);-webkit-animation-timing-function:cubic-bezier(.553289,.254604,.514412,.766855);animation-timing-function:cubic-bezier(.553289,.254604,.514412,.766855)}100%{-webkit-transform:translate(80px,80px) rotate(360deg);transform:translate(80px,80px) rotate(360deg)}}"];
exports.styles = styles;
