"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AButtonComponent = /** @class */ (function () {
    function AButtonComponent() {
    }
    Object.defineProperty(AButtonComponent.prototype, "isPending", {
        get: function () {
            return this.pending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AButtonComponent.prototype, "isDisabled", {
        get: function () {
            return this.disabled ? '' : undefined;
        },
        enumerable: true,
        configurable: true
    });
    AButtonComponent.prototype.clickBtnEvent = function (event) {
        if (this.disabled || this.pending) {
            event.stopPropagation();
            event.stopImmediatePropagation();
            return false;
        }
    };
    return AButtonComponent;
}());
exports.AButtonComponent = AButtonComponent;
