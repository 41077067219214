import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LoginFormComponent } from './login-form/login-form.component';
import { RootPathGuard } from './services/guards/root-path-guard.service';
import { appRoutes } from '../app.routing';
import { LoginFormGuard } from './services/guards/login-form-guard.serivce';
import { ChooseCompanyComponent } from './choose-compnay/choose-compnay.component';
import { LogoutGuard } from './services/guards/logout-guard.service';
import { CreateAccountGuard } from './services/guards/create-account-guard.service';
import { SharedModule } from '../shared/shared.module';
import { ChooseCompanyGuard } from './services/guards/choose-company.guard';
import { GoogleSsoComponent } from './google-sso/google-sso.component';

@NgModule({
  declarations: [
    LoginFormComponent,
    ChooseCompanyComponent,
    GoogleSsoComponent
  ],
  imports: [RouterModule.forRoot(appRoutes), SharedModule],
  exports: [LoginFormComponent]
})
export class AccountModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AccountModule,
      providers: [
        RootPathGuard,
        LogoutGuard,
        LoginFormGuard,
        CreateAccountGuard,
        ChooseCompanyGuard
      ]
    };
  }
}
