"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var RollbarErrorHandler = /** @class */ (function () {
    function RollbarErrorHandler(rollbar) {
        this._rollbar = rollbar;
    }
    RollbarErrorHandler.prototype.handleError = function (err) {
        console.error(err.originalError || err);
        this._rollbar.error(err.originalError || err);
    };
    return RollbarErrorHandler;
}());
exports.RollbarErrorHandler = RollbarErrorHandler;
