"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_1 = require("../../core/models/user");
var constants_1 = require("../../constants");
var AppHeaderComponent = /** @class */ (function () {
    function AppHeaderComponent() {
        this.isUserMenuVisible = false;
        this.toUserProfile = ['/', constants_1.ROUTER_PATH.PROFILE];
        this.toLogout = ['/', constants_1.ROUTER_PATH.LOGOUT];
    }
    AppHeaderComponent.prototype.onToggleMenu = function () {
        this.isUserMenuVisible = !this.isUserMenuVisible;
    };
    return AppHeaderComponent;
}());
exports.AppHeaderComponent = AppHeaderComponent;
