import {
  Directive,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  HostListener,
  HostBinding,
  AfterViewInit,
  OnDestroy
} from '@angular/core';

const getEventPath = (event: Event): HTMLElement[] => {
  if (event['path']) {
    return event['path'];
  }
  if (event['composedPath']) {
    return event['composedPath']();
  }
  const path = [];
  let node = <HTMLElement>event.target;
  do {
    path.push(node);
  } while ((node = node.parentElement));
  return path;
};

const clickOutStack = [];

@Directive({
  selector: '[clickOut]'
})
export class ClickOutDirective implements AfterViewInit, OnDestroy {
  @Input() clickOut: boolean;

  @Output()
  clickOutEvent: EventEmitter<MouseEvent | KeyboardEvent> = new EventEmitter<
    MouseEvent | KeyboardEvent
  >();

  @HostBinding('attr.tabindex') tabindex = -1;

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (
      this.clickOut &&
      !getEventPath(event).includes(this._element.nativeElement)
    ) {
      this.clickOutEvent.emit(event);
    }
  }

  @HostListener('keydown.esc', ['$event'])
  onEsc(event: KeyboardEvent) {
    if (!this.clickOut) {
      return false;
    }

    if (
      this._element.nativeElement.contains(event.target) &&
      clickOutStack[clickOutStack.length - 1] === this
    ) {
      this.clickOutEvent.emit(event);
    } else {
      this.setFocus();
    }
  }

  constructor(private _element: ElementRef) {}

  ngAfterViewInit(): void {
    clickOutStack.push(this);
    this.setFocus();
  }

  ngOnDestroy() {
    const i = clickOutStack.findIndex(item => item === this);
    clickOutStack.splice(i, 1);
  }

  private setFocus() {
    this._element.nativeElement.focus();
  }
}
