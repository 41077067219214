import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  NgZone
} from '@angular/core';
import { Router } from '@angular/router';
import {
  FormSaveType,
  FormService,
  FormServiceParams
} from '../../core/services/form.service/form.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observer, of } from 'rxjs';
import { catchError, publishReplay, refCount } from 'rxjs/operators';

import { JsonApiSingeModelResponse } from '../../core/services/api.service/http-response';
import {
  AtlazApiService,
  patchPath
} from '../../core/services/api.service/api.service';
import { AuthService } from '../../core/services/auth.service/auth.service';
import { LoginFormGuard } from '../services/guards/login-form-guard.serivce';
import { ROUTER_PATH } from '../../constants';
import { emailValidator, passwordValidator } from '../../util/validators';
import { AUTH } from '../../core/services/api.service/api-path';
import { GoogleAuthService } from 'ng-gapi/lib/GoogleAuthService';
import GoogleUser = gapi.auth2.GoogleUser;
import { SegmentService } from '../../core/services/intergations/segment/segment.service';

const isErrorWithToken = error =>
  ['one_off_authorization_token_invalid'].includes(error.code);

@Component({
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  providers: [FormService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent implements OnInit {
  public form: FormGroup;
  public toSignUp = ['/' + ROUTER_PATH.SIGN_UP];
  private formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._authService.authorize(resp);
      this._segment.track('Login', { source: 'normal' });
      setTimeout(() => {
        this._loginFormGuard.authHook();
      }, 400);
    },
    error: error => {},
    complete: () => {}
  };
  public formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: [
      AUTH,
      { expand: ['usersCompanies', 'usersCompanies.company'] }
    ],
    formObserver: this.formObserver
  };
  constructor(
    public _formService: FormService,
    private _authService: AuthService,
    private _loginFormGuard: LoginFormGuard,
    private _ngZone: NgZone,
    private _fb: FormBuilder,
    private _router: Router,
    private _atlazApi: AtlazApiService,
    private _googleAuthService: GoogleAuthService,
    private _segment: SegmentService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      email: ['', emailValidator],
      password: ['', passwordValidator(5)]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onGoogleSignIn() {
    this._googleAuthService.getAuth().subscribe(auth => {
      auth
        .signIn()
        .then(
          res => this.googleSignInSuccessHandler(res),
          err => this.googleSignInErrorHandler(err)
        );
    });
    return false;
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.valid) {
      this._formService.submit();
    }
    return false;
  }

  private googleSignInSuccessHandler(googleUser: GoogleUser) {
    this._ngZone.run(() => {
      this._formService.markAsPending();

      const creds = {
        credentialsType: 'googleToken',
        googleToken: googleUser.getAuthResponse().id_token
      };

      const path = patchPath(this.formServiceParams.entityToEdit);

      this._atlazApi
        .post(path, creds)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError((e: Response) => {
            try {
              console.log(JSON.stringify(e));
            } catch (e) {}
            const error = e['errors']
              ? e['errors'].find(isErrorWithToken)
              : e['error']['errors']
                ? e['error']['errors'].find(isErrorWithToken)
                : null;
            const message = error ? error.detail : 'Access Denied';
            this._router.navigate(['403'], { queryParams: { message } });
            return of(null);
          })
        )
        .subscribe((resp: JsonApiSingeModelResponse<any>) => {
          if (resp) {
            if (resp.data.attributes.newUser) {
              this._segment.track('Login', { source: 'google' });
              this._router.navigate(
                [ROUTER_PATH.SIGN_UP, ROUTER_PATH.CREATE_ACCOUNT],
                {
                  queryParams: {
                    type: 'googleToken',
                    googleToken: googleUser.getAuthResponse().id_token,
                    firstName: googleUser.getBasicProfile().getGivenName(),
                    lastName: googleUser.getBasicProfile().getFamilyName()
                  }
                }
              );
            } else {
              this._authService.authorize(resp);
              this._segment.track('Login', { source: 'google' });
              setTimeout(() => {
                this._loginFormGuard.authHook();
              }, 400);
            }
          }
        });
    });
  }

  private googleSignInErrorHandler(err) {
    console.warn(err);
    this._ngZone.run(() => {
      this._formService.markAsAvailable();
    });
  }
}
