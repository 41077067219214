import {
  throwError as observableThrowError,
  BehaviorSubject,
  Observable,
  Observer
} from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import {
  patchPath,
  AtlazApiService,
  ResponseParser
} from '../api.service/api.service';
import { HttpQueryParam, ServerError } from '../../interfaces/http';
import { isObject, prop } from '../../../util/object';
import { BugTrackerService } from '../bug-tracker.service/bug-tracker.service';
import { map, publishReplay, refCount, take } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { JsonApiResponse } from '../api.service/http-response';

export enum FormStatuses {
  available = 1,
  pending = 2,
  error = 3
}

export enum FormSaveType {
  add = 1,
  edit = 2
}

export interface FormServiceParams {
  formObserver: Observer<any>;
  saveType: FormSaveType | '';
  entityToEdit: string | any[];

  prepareFormValue?: (formValue: {}) => any;
  httpQueryParams?: HttpQueryParam;
  responseParser?: ResponseParser;
  requestParser?: (param: any) => any;
}

export interface FormComponent {
  _formService: FormService;

  form: FormGroup;
  formServiceParams: FormServiceParams;

  onSubmit: () => any;
}

@Injectable()
export class FormService implements OnDestroy {
  readonly errors$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  protected requestDetails = {};

  /**
   * general list of errors
   */
  private _errors: { message: string; field: string }[] = [];

  public formServiceObserver: Observer<any> = {
    next: x => {},

    error: (error: HttpResponse<JsonApiResponse<any>>) => {
      // mark as error
      this._errors = [];
      this.markAsError();
      this.setFormFieldsError(error);

      this._form.valueChanges.pipe(take(1)).subscribe(_ => {
        this.removeError('commonError');
        this.removeError('authorize_params');
        this.removeError('users_not_provided_register_token');
      });
      this._errors
        .map(prop('field'))
        .filter(field => field !== 'commonError')
        .map((field: string) => [field, this._form.get(field)])
        .filter(([field, formControl]) => !!formControl)
        .forEach(([field, formControl]: [string, FormControl]) =>
          formControl.valueChanges
            .pipe(take(1))
            .subscribe(_ => this.removeError(field))
        );
    },

    complete: () => {
      // somtething
      this.markAsAvailable();
      console.log('form observer complete');
    }
  };

  protected observerContainer = [this.formServiceObserver];

  protected _formStatus$: BehaviorSubject<number> = new BehaviorSubject(
    FormStatuses.available
  );

  protected _form: FormGroup;
  protected _formParams: FormServiceParams;

  constructor(
    private _atlazApi: AtlazApiService,
    private _bugTracker: BugTrackerService
  ) {}

  ngOnDestroy() {
    console.log('FormService Destroy');
  }

  initFormParams(form: FormGroup, formServiceParams: FormServiceParams) {
    this._form = form;
    this._formParams = formServiceParams;
    this.resetObserversToDefault();
    this.registerObserver(formServiceParams.formObserver);
  }

  get isPending$(): Observable<boolean> {
    return this._formStatus$.pipe(map(this.isPending));
  }

  get isError$(): Observable<boolean> {
    return this._formStatus$.pipe(map(this.isError));
  }

  get isAvailable$(): Observable<boolean> {
    return this._formStatus$.pipe(map(this.isAvailable));
  }

  get pendingSnapshot() {
    return this.isPending(this._formStatus$.getValue());
  }

  get availableSnapshot() {
    return this.isAvailable(this._formStatus$.getValue());
  }

  get errorSnapshot() {
    return this.isError(this._formStatus$.getValue());
  }

  submit() {
    this.markAsPending();
    const submitData$ = this.sendRequest().pipe(publishReplay(1), refCount());

    // we don't need unsubscribe because submitData$ stream will be complete after receiving response
    this.observerContainer.forEach(observer => submitData$.subscribe(observer));
  }

  registerObserver(observer: Observer<any>) {
    this.observerContainer = [...this.observerContainer, observer];
  }

  unRegisterObserver(observer: Observer<any>) {
    this.observerContainer = this.observerContainer.filter(
      item => item !== observer
    );
  }

  resetObserversToDefault() {
    this.observerContainer = [this.formServiceObserver];
  }

  markAsAvailable() {
    this.markAs(FormStatuses.available);
  }

  markAsError() {
    this.markAs(FormStatuses.error);
  }

  markAsPending() {
    this.markAs(FormStatuses.pending);
  }

  markAsDirty() {
    Object.keys(this._form.controls).map(controlName => {
      this._form.get(controlName).markAsDirty();
      this._form.get(controlName).markAsTouched();
      this._form.get(controlName).updateValueAndValidity();
    });
  }

  protected sendRequest() {
    const value = this.prepareFormValue();
    const httpQueryParams = this._formParams.httpQueryParams || {};

    const path = patchPath(this._formParams.entityToEdit, httpQueryParams);

    switch (this._formParams.saveType) {
      case FormSaveType.add: {
        this.requestDetails = {
          request: 'post',
          url: path,
          payload: value
        };
        return this._atlazApi.post(
          path,
          value,
          this._formParams.responseParser
        );
      }

      case FormSaveType.edit: {
        try {
          if (!value.hasOwnProperty('id')) {
            throw new Error(
              `Invalid form fields. Form must contain id property`
            );
          }
          this.requestDetails = {
            request: 'patch',
            url: path,
            payload: value
          };
          return this._atlazApi.patch(
            path,
            value,
            this._formParams.responseParser,
            this._formParams.requestParser
          );
        } catch (e) {
          console.error(e);
          return observableThrowError(e);
        }
      }

      default: {
        return observableThrowError(
          new Error(
            'unexpected value of saveType property. Only "add" "edit" types allowed'
          )
        );
      }
    }
  }

  protected prepareFormValue() {
    return this._formParams.hasOwnProperty('prepareFormValue')
      ? this._formParams.prepareFormValue(this._form.value)
      : this._form.value;
  }

  protected isPending(status): boolean {
    return FormStatuses.pending === status;
  }

  protected isError(status): boolean {
    return FormStatuses.error === status;
  }

  protected isAvailable(status): boolean {
    return FormStatuses.available === status;
  }

  protected markAs(status: number) {
    this._formStatus$.next(status);
  }

  protected logError(err, message) {
    try {
      console.log('API RESPONSE: ', JSON.stringify(err));
    } catch (e) {}

    console.warn('requestDetails', this.requestDetails);

    const msg = [message, err.toString()].join('\n');
    this._bugTracker.warn(msg);
  }

  public normalizeServerErrorResponse(originalError) {
    if (!originalError && !originalError.error) {
      // front error
      this.logError(originalError, 'FrontEnd message');
      return {
        message:
          'Unknown error. Please contact support if the problem persists.'
      };
    }
    let error;
    try {
      error = originalError.error;
    } catch (e) {}
    if (!error || !isObject(error)) {
      // failed to parse error code
      this.logError(originalError, 'Internal Server error');
      return {
        message:
          'Internal Server error. Please contact support if the problem persists.'
      };
    }
    if (!error.errors && !error.message) {
      this.logError(originalError, 'Unknown Server error');
      return {
        message:
          'Unknown error. Please contact support if the problem persists.'
      };
    }

    return error;
  }

  public setGeneralError(message: string) {
    this.removeError('commonError');
    this.addError({ message, field: 'commonError' });
  }

  protected setFormFieldsError(
    originalError: HttpResponse<JsonApiResponse<any>>
  ) {
    const error = this.normalizeServerErrorResponse(originalError);

    if (error.hasOwnProperty('errors')) {
      this.markAsInvalidFormFields(error['errors']);
    } else if (error.hasOwnProperty('message') && error['message']) {
      this.addError({ ...error, field: 'commonError' });
    }
  }

  private addError(error: ServerError | { message: string; field: string }) {
    this._errors = [...this._errors, error];
    this.errors$.next(this._errors.map(e => e.message));
  }

  private removeError(field: string) {
    this._errors = this._errors.filter(error => error.field !== field);
    this.errors$.next(this._errors.map(e => e.message));
  }

  protected markAsInvalidFormFields(errors: ServerError[]) {
    errors.forEach(this.markAsInvalidFormField.bind(this));
  }

  protected markAsInvalidFormField(error: ServerError) {
    const field = this._form.get(error.field);
    if (!!field) {
      this.addError(error);
      field.markAsTouched();
      field.setErrors({ serverError: true });
    } else {
      this.addError({ ...error, field: error.code });
    }
  }
}
