"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./a-context-menu.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./a-context-menu.component");
var styles_AContextMenuComponent = [i0.styles];
var RenderType_AContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AContextMenuComponent, data: {} });
exports.RenderType_AContextMenuComponent = RenderType_AContextMenuComponent;
function View_AContextMenuComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { contextElement: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["context", 1]], null, 1, "div", [["class", "content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
exports.View_AContextMenuComponent_0 = View_AContextMenuComponent_0;
function View_AContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["a-context-menu", ""]], null, [[null, "click"], ["document", "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_AContextMenuComponent_0, RenderType_AContextMenuComponent)), i1.ɵdid(1, 1097728, null, 0, i2.AContextMenuComponent, [i1.ElementRef], null, null)], null, null); }
exports.View_AContextMenuComponent_Host_0 = View_AContextMenuComponent_Host_0;
var AContextMenuComponentNgFactory = i1.ɵccf("[a-context-menu]", i2.AContextMenuComponent, View_AContextMenuComponent_Host_0, { position: "position" }, { close: "close" }, ["*"]);
exports.AContextMenuComponentNgFactory = AContextMenuComponentNgFactory;
