import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'header-container',
  template: '<ng-content></ng-content>',
  styleUrls: ['./header-container.component.scss']
})
export class HeaderContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
