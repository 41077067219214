"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../../../../environments/environment");
var ɵ0 = function () { return null; };
exports.ɵ0 = ɵ0;
var fakeInstance = {
    push: ɵ0
};
var ɵ1 = function (event) { return console.log('GoogleAnalyticsService', event); };
exports.ɵ1 = ɵ1;
var debugInstance = {
    push: ɵ1
};
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService() {
        if (environment_1.environment.production) {
            if (window['dataLayer']) {
                this.instance = window['dataLayer'];
            }
            else {
                console.warn('dataLayer is missed');
                this.instance = fakeInstance;
            }
        }
        else {
            this.instance = debugInstance;
        }
    }
    Object.defineProperty(GoogleAnalyticsService.prototype, "getInstance", {
        get: function () {
            return this.instance;
        },
        enumerable: true,
        configurable: true
    });
    return GoogleAnalyticsService;
}());
exports.GoogleAnalyticsService = GoogleAnalyticsService;
