import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service/auth.service';
import { AtlazApiService } from './services/api.service/api.service';
import { AuthorizedUserGuard } from './services/guards/authorized-user-guard.service';
import { ChooseCompanyResolver } from './services/resolvers/choose-company-resolver.service';
import { GeoipService } from './services/geoip.service';
import { InviteUserResolver } from './services/resolvers/invite-user-resolver.service';
import { ActiveUserResolver } from './services/resolvers/active-user-resolver.service';
import { SegmentService } from './services/intergations/segment/segment.service';
import { environment } from '../../environments/environment';
import { SegmentDummyService } from './services/intergations/segment/segment-dummy.service';
import { SharedModule } from '../shared/shared.module';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { ForbiddenPageComponent } from './components/forbidden-page/forbidden-page.component';
import { CreateDemoComponent } from './components/create-demo/create-demo.component';
import { UploadFileService } from './services/upload-file.service';
import { GoogleAnalyticsService } from './services/intergations/google-analytics/google-analytics.service';
import { FbPixelService } from './services/intergations/fb-pixel/fb-pixel.service';
import { TaboolaService } from './services/intergations/taboola/taboola.service';
import * as Rollbar from 'rollbar';
import {
  ROLLBAR_TOKEN,
  RollbarDymmy
} from './services/bug-tracker.service/rollbar';
import { RollbarErrorHandler } from './services/bug-tracker.service/rollbar-error-handler';
import { BugTrackerService } from './services/bug-tracker.service/bug-tracker.service';
import { InviteLinkService } from './services/invite-link/invite-link.service';
import { InviteLinkGuard } from './services/guards/invite-link.guard';
import { PaymentRequiredPageComponent } from './components/payment-required-page/payment-required-page.component';
import { JobStatusService } from './services/job-status.service/job-status.service';

export function RollbarFactory() {
  return environment.rollbarConfig
    ? new Rollbar(<Rollbar.Configuration>environment.rollbarConfig)
    : new RollbarDymmy();
}

@NgModule({
  declarations: [
    NotFoundPageComponent,
    ForbiddenPageComponent,
    PaymentRequiredPageComponent,
    CreateDemoComponent
  ],
  imports: [CommonModule, SharedModule],
  providers: [
    {
      provide: ROLLBAR_TOKEN,
      useFactory: RollbarFactory
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    BugTrackerService,
    ActiveUserResolver,
    AtlazApiService,
    AuthService,
    AuthorizedUserGuard,
    JobStatusService,
    ChooseCompanyResolver,
    GeoipService,
    GoogleAnalyticsService,
    FbPixelService,
    TaboolaService,
    {
      provide: SegmentService,
      useClass: environment.production ? SegmentService : SegmentDummyService
    },
    InviteUserResolver,
    UploadFileService,
    InviteLinkService,
    InviteLinkGuard
  ],
  exports: [NotFoundPageComponent, ForbiddenPageComponent]
})
export class CoreModule {}
