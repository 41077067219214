import { FormControl, ValidatorFn, Validators } from '@angular/forms';

// it's function because when you save pass, min length = 6, when try to login = 5
export const passwordValidator = (minLength = 6) => Validators.compose([
  Validators.required,
  Validators.pattern(/^\S+$/),
  Validators.minLength(minLength)
]);

export const emailValidator = Validators.compose([Validators.required, Validators.email]);

export const firstNameValidator = Validators.compose([
  Validators.required,
  Validators.maxLength(50)
]);
export const lastNameValidator = firstNameValidator;

export function callbackValidator(callbackFn): ValidatorFn {
  return (c: FormControl) => {

    return callbackFn(c.value)
      ? null
      : { compare: {
          valid: false
        }
      };
  };
}

export const notEqual = a => b => a !== b;

export const notEqualValidator = valueToCompare => callbackValidator(notEqual(valueToCompare));
