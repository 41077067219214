"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var form_service_1 = require("../../core/services/form.service/form.service");
var forms_1 = require("@angular/forms");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var api_service_1 = require("../../core/services/api.service/api.service");
var auth_service_1 = require("../../core/services/auth.service/auth.service");
var login_form_guard_serivce_1 = require("../services/guards/login-form-guard.serivce");
var constants_1 = require("../../constants");
var validators_1 = require("../../util/validators");
var api_path_1 = require("../../core/services/api.service/api-path");
var GoogleAuthService_1 = require("ng-gapi/lib/GoogleAuthService");
var segment_service_1 = require("../../core/services/intergations/segment/segment.service");
var isErrorWithToken = function (error) {
    return ['one_off_authorization_token_invalid'].includes(error.code);
};
var ɵ0 = isErrorWithToken;
exports.ɵ0 = ɵ0;
var LoginFormComponent = /** @class */ (function () {
    function LoginFormComponent(_formService, _authService, _loginFormGuard, _ngZone, _fb, _router, _atlazApi, _googleAuthService, _segment) {
        var _this = this;
        this._formService = _formService;
        this._authService = _authService;
        this._loginFormGuard = _loginFormGuard;
        this._ngZone = _ngZone;
        this._fb = _fb;
        this._router = _router;
        this._atlazApi = _atlazApi;
        this._googleAuthService = _googleAuthService;
        this._segment = _segment;
        this.toSignUp = ['/' + constants_1.ROUTER_PATH.SIGN_UP];
        this.formObserver = {
            next: function (resp) {
                _this._authService.authorize(resp);
                _this._segment.track('Login', { source: 'normal' });
                setTimeout(function () {
                    _this._loginFormGuard.authHook();
                }, 400);
            },
            error: function (error) { },
            complete: function () { }
        };
        this.formServiceParams = {
            saveType: form_service_1.FormSaveType.add,
            entityToEdit: [
                api_path_1.AUTH,
                { expand: ['usersCompanies', 'usersCompanies.company'] }
            ],
            formObserver: this.formObserver
        };
    }
    LoginFormComponent.prototype.ngOnInit = function () {
        this.form = this._fb.group({
            email: ['', validators_1.emailValidator],
            password: ['', validators_1.passwordValidator(5)]
        });
        this._formService.initFormParams(this.form, this.formServiceParams);
    };
    LoginFormComponent.prototype.onGoogleSignIn = function () {
        var _this = this;
        this._googleAuthService.getAuth().subscribe(function (auth) {
            auth
                .signIn()
                .then(function (res) { return _this.googleSignInSuccessHandler(res); }, function (err) { return _this.googleSignInErrorHandler(err); });
        });
        return false;
    };
    LoginFormComponent.prototype.onSubmit = function () {
        this._formService.markAsDirty();
        if (this.form.valid) {
            this._formService.submit();
        }
        return false;
    };
    LoginFormComponent.prototype.googleSignInSuccessHandler = function (googleUser) {
        var _this = this;
        this._ngZone.run(function () {
            _this._formService.markAsPending();
            var creds = {
                credentialsType: 'googleToken',
                googleToken: googleUser.getAuthResponse().id_token
            };
            var path = api_service_1.patchPath(_this.formServiceParams.entityToEdit);
            _this._atlazApi
                .post(path, creds)
                .pipe(operators_1.publishReplay(1), operators_1.refCount(), operators_1.catchError(function (e) {
                try {
                    console.log(JSON.stringify(e));
                }
                catch (e) { }
                var error = e['errors']
                    ? e['errors'].find(isErrorWithToken)
                    : e['error']['errors']
                        ? e['error']['errors'].find(isErrorWithToken)
                        : null;
                var message = error ? error.detail : 'Access Denied';
                _this._router.navigate(['403'], { queryParams: { message: message } });
                return rxjs_1.of(null);
            }))
                .subscribe(function (resp) {
                if (resp) {
                    if (resp.data.attributes.newUser) {
                        _this._segment.track('Login', { source: 'google' });
                        _this._router.navigate([constants_1.ROUTER_PATH.SIGN_UP, constants_1.ROUTER_PATH.CREATE_ACCOUNT], {
                            queryParams: {
                                type: 'googleToken',
                                googleToken: googleUser.getAuthResponse().id_token,
                                firstName: googleUser.getBasicProfile().getGivenName(),
                                lastName: googleUser.getBasicProfile().getFamilyName()
                            }
                        });
                    }
                    else {
                        _this._authService.authorize(resp);
                        _this._segment.track('Login', { source: 'google' });
                        setTimeout(function () {
                            _this._loginFormGuard.authHook();
                        }, 400);
                    }
                }
            });
        });
    };
    LoginFormComponent.prototype.googleSignInErrorHandler = function (err) {
        var _this = this;
        console.warn(err);
        this._ngZone.run(function () {
            _this._formService.markAsAvailable();
        });
    };
    return LoginFormComponent;
}());
exports.LoginFormComponent = LoginFormComponent;
