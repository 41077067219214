<label class="form_page_wrap_label" for="{{inputId}}">{{label}}</label>
<div class="form_page_input_wrap"
     [class.error]="field.invalid && field.touched && field.dirty">
  <div class="form_page_input_container">
    <input [formControl]="field" autocomplete="off"
           [ngClass]="{ form_page_input: true, forgot: hintType === 'forgot'}"
           [attr.type]="passwordType" id="{{inputId}}"
           [attr.placeholder]="placeholder"
           [attr.disabled]="disabled"
           [focus]="focus"
           checkTouch
    />
    <ng-container [ngSwitch]="hintType">
      <ng-container *ngSwitchCase="'forgot'">
        <ng-template [ngTemplateOutlet]="forgot"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'visibility'">
        <ng-template [ngTemplateOutlet]="visibility"></ng-template>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-template [ngTemplateOutlet]="forgot"></ng-template>
      </ng-container>
    </ng-container>

  </div>
  <div class="error_text">Password must be at least 6 characters long</div>
</div>

<ng-template #forgot>
  <a [routerLink]="toResetPass" class="form_page_input_link">Forgot?</a>
</ng-template>
<ng-template #visibility>
  <div [ngClass]="{ico_password_hide: isHiddenPassword, ico_password_show: !isHiddenPassword}"
       (click)="onTogglePassword()"></div>
</ng-template>
