import { BaseModel, getMany, Model, OneToMany } from './index';
import { Company } from './company';
import { UsersCompany } from './usersCompany';

export class User extends BaseModel implements UserModel {
  getCompanies(confirmedOnly = true): Company[] {
    return this.getUsersCompanies()
      .filter(
        uc =>
          confirmedOnly
            ? uc.attributes.confirmed && !uc.attributes.deleted
            : !uc.attributes.deleted
      )
      .map(uc => uc.getCompany());
  }

  getUsersCompanies(): UsersCompany[] {
    return <UsersCompany[]>getMany(
      this.originalResponse,
      'usersCompanies',
      UsersCompany
    );
  }
}

export interface UserModel extends Model<'user'> {
  attributes: UserAttributes;
  relationships: UserRelationships;
}

export interface UserPhoto {
  original: {
    path: null;
  };
  previews: {
    w100: {
      path: null;
    };
    w200: {
      path: null;
    };
  };
}

export interface UserAttributes {
  email: string;
  firstname: string;
  lastname: string;
  fullname: string;
  timezone: string;
  newEmail: string;

  deleted: 0 | 1;
  emailNotificationsMode: 0 | 1;
  isPasswordEmpty: 0 | 1;
  createdAt: number;
  updatedAt: number;

  photo: UserPhoto;

  companyDomainId?: number;
}

export interface UserRelationships {
  company: OneToMany<'company'>;
}
