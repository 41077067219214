"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".logo[_ngcontent-%COMP%]{margin-left:105px}.error_page[_ngcontent-%COMP%]{display:flex;align-items:center;flex-direction:column;justify-content:center;padding-top:200px}.error_page_name[_ngcontent-%COMP%]{font:300 90px Roboto,sans-serif;color:#0686d8;text-align:center;padding:0;margin-bottom:15px}.error_page_img[_ngcontent-%COMP%]{height:143px;width:326px;margin-bottom:40px}.error_page_title[_ngcontent-%COMP%]{font:300 64px/75px Roboto,sans-serif;color:#546e7a;text-align:center;padding:0 0 5px}.error_page_text[_ngcontent-%COMP%]{font:400 14px/16px Roboto,sans-serif;color:rgba(84,110,122,.5);text-align:center;padding:0;margin-bottom:50px}.error_page_btn[_ngcontent-%COMP%]{position:relative;font-size:14px;font-family:Roboto;height:36px;line-height:36px;cursor:pointer;padding:0 39px 0 25px;border-radius:3px;border:1px solid transparent;background:0 0;transition:background .2s,padding .2s;color:#0686d8;font-weight:500}.error_page_btn[_ngcontent-%COMP%]:hover{background:#eceff1}.error_page_btn[_ngcontent-%COMP%]:active{background:#cfd8dc}.error_page_btn[_ngcontent-%COMP%]:focus{background:#f3f6f8}.error_page_btn[_ngcontent-%COMP%]:disabled{color:#b1b1b6}.error_page_btn[_ngcontent-%COMP%]:after{content:'';position:absolute;width:24px;height:24px;top:50%;margin-top:-12px;cursor:pointer;right:0;background:url(/assets/img/ico-right-btn.png) center/18px no-repeat;padding-right:25px}"];
exports.styles = styles;
