import { Company } from '../core/models/company';
import { environment } from '../../environments/environment';

export const companyPortalUrl = (company: Company) =>
  'https://' + company.attributes.domain + '.' + environment.domain;

export const preparePath = (path: string | string[]) =>
  Array.isArray(path) ? path.join('/') : '/' + path;

export const goToAtlazWeb = (
  company: Company,
  path: string | string[] = ''
) => {
  window.location.href = companyPortalUrl(company) + preparePath(path);
};

export const getUtmFromCurrentUri = () =>
  window.location.search
    .substr(1)
    .split('&')
    .reduce((acc, param) => {
      let [key, value] = param.split('=');
      if (key && value && key.indexOf('utm') > -1) {
        acc[key] = decodeURIComponent(value);
      }
      return acc;
    }, {});
