"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("../40x-page.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./a-forbidden.component");
var styles_ForbiddenComponent = [i0.styles];
var RenderType_ForbiddenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForbiddenComponent, data: {} });
exports.RenderType_ForbiddenComponent = RenderType_ForbiddenComponent;
function View_ForbiddenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "error_page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "error_page_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "error_page_img"], ["src", "/assets/img/403-error.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "error_page_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry!"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "error_page_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The access to the page is forbidden"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }); }
exports.View_ForbiddenComponent_0 = View_ForbiddenComponent_0;
function View_ForbiddenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a-forbidden", [], null, null, null, View_ForbiddenComponent_0, RenderType_ForbiddenComponent)), i1.ɵdid(1, 49152, null, 0, i2.ForbiddenComponent, [], null, null)], null, null); }
exports.View_ForbiddenComponent_Host_0 = View_ForbiddenComponent_Host_0;
var ForbiddenComponentNgFactory = i1.ɵccf("a-forbidden", i2.ForbiddenComponent, View_ForbiddenComponent_Host_0, { message: "message" }, {}, []);
exports.ForbiddenComponentNgFactory = ForbiddenComponentNgFactory;
