"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var PaymentRequiredPageComponent = /** @class */ (function () {
    function PaymentRequiredPageComponent(_route) {
        this._route = _route;
    }
    PaymentRequiredPageComponent.prototype.ngOnInit = function () {
        this.message = this._route.snapshot.queryParams['message'];
    };
    return PaymentRequiredPageComponent;
}());
exports.PaymentRequiredPageComponent = PaymentRequiredPageComponent;
