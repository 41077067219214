"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("../auth.service/auth.service");
var operators_1 = require("rxjs/operators");
var ActiveUserResolver = /** @class */ (function () {
    function ActiveUserResolver(_authService) {
        this._authService = _authService;
    }
    ActiveUserResolver.prototype.resolve = function (route, state) {
        return this._authService.activeUser$.pipe(operators_1.take(1));
    };
    return ActiveUserResolver;
}());
exports.ActiveUserResolver = ActiveUserResolver;
