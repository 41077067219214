import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

import { AtlazApiService } from '../api.service/api.service';
import {
  JsonApiModelsResponse,
  JsonApiSingeModelResponse
} from '../api.service/http-response';
import { User, UserModel } from '../../models/user';
import { HTTP_CODES } from '../../interfaces/http';
import { Credentials } from '../../models/credentials';
import { getModeList } from '../../models';
import { BugTrackerService } from '../bug-tracker.service/bug-tracker.service';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

function getStoredAuthData() {
  const cache = localStorage.getItem('auth');
  let data;
  try {
    data = JSON.parse(cache);
  } finally {
    if (!data) {
      data = {};
    }
  }
  return data;
}

@Injectable()
export class AuthService {
  public accessToken: string;
  public isLoggedIn$: Subject<boolean> = new ReplaySubject(1);
  public isLoggedIn = false;

  public activeUser$ = new ReplaySubject<User>(1);
  public activeUser: User = null;
  public authorizedUsers$ = new ReplaySubject(1);
  public companyUsers = [];

  constructor(
    private _atlazApi: AtlazApiService,
    private _bagTracker: BugTrackerService
  ) {
    this.fetchAuthorizedUsers();
    this.isLoggedIn$.subscribe(x => (this.isLoggedIn = x));
    this.activeUser$.subscribe(x => (this.activeUser = x));
    this.activeUser$.subscribe(activeUser => {
      this._bagTracker.updateUserData({
        person: { ...activeUser.attributes, id: activeUser.id }
      });
    });
  }

  storeAuthData(user: User) {
    localStorage.setItem(
      'auth',
      JSON.stringify({
        accessToken: this.accessToken,
        userId: user.id
      })
    );
    this.setLoggedUser(user);
  }

  authorize(resp: JsonApiSingeModelResponse<UserModel>) {
    const cred = new Credentials(resp);
    this.accessToken = cred.attributes.accessToken;

    this.storeAuthData(cred.getUser());
  }

  fetchAuthorizedUsers() {
    this._atlazApi
      .get('users', {
        expand: ['usersCompanies', 'usersCompanies.company']
      })
      // .retryWhen((errors) => errors.filter((error: Response) => error.status !== HTTP_CODES.UNAUTHORIZED).delay(1000))
      .pipe(
        map((resp: JsonApiModelsResponse<UserModel>) => getModeList(resp, User))
      )
      .subscribe(
        (users: User[]) => {
          this.authorizedUsers$.next(users);
          this.storeAuthData(users[0]);
          this.companyUsers = users[0].included.filter(
            inc => inc.type === 'usersCompany'
          );
        },
        (error: Response) => {
          if (error.status === HTTP_CODES.UNAUTHORIZED) {
            this.isLoggedIn$.next(false);
          } else {
            console.error(error);
          }
        }
      );
  }

  public setLoggedUser(user: User) {
    this.activeUser$.next(user);
    this.isLoggedIn$.next(true);
  }

  checkSignUp(token: string) {
    return of(true);
  }
}
