"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_service_1 = require("../../../core/services/auth.service/auth.service");
var constants_1 = require("../../../constants");
var object_1 = require("../../../util/object");
var operators_1 = require("rxjs/operators");
var LoginFormGuard = /** @class */ (function () {
    function LoginFormGuard(_authService, _router) {
        this._authService = _authService;
        this._router = _router;
        this.returnUrl = '';
        this.returnPath = [constants_1.ROUTER_PATH.CHOOSE_COMPANY];
    }
    LoginFormGuard.prototype.authHook = function () {
        var _this = this;
        this._authService.isLoggedIn$
            .pipe(operators_1.filter(object_1.isPresent), operators_1.take(1))
            .subscribe(function (authorized) { return _this.redirect(); });
    };
    LoginFormGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this._authService.isLoggedIn$.pipe(operators_1.take(1), operators_1.tap(function (_) { return _this.initReturn(route); }), operators_1.map(function (isLogged) { return (isLogged ? _this.redirect() : true); }));
    };
    LoginFormGuard.prototype.redirect = function () {
        if (this.returnUrl) {
            window.location.href = this.returnUrl;
        }
        else {
            this._router.navigate(this.returnPath, {
                queryParams: this.furtherPathQP
            });
        }
        return false;
    };
    LoginFormGuard.prototype.initReturn = function (route) {
        if (route.queryParams &&
            route.queryParams['return_url'] &&
            !route.queryParams['return_url'].includes('javascript')) {
            var returnPath = route.queryParams['return_path'];
            this.returnUrl = route.queryParams['return_url'];
            if (returnPath) {
                this.returnUrl +=
                    (this.returnUrl.indexOf('#') > -1 ? '&' : '#') +
                        'return_path=' +
                        encodeURIComponent(returnPath);
            }
        }
        else if (route.queryParams && route.queryParams['return_path']) {
            this.returnPath = [route.queryParams['return_path']];
        }
        else if (route.queryParams && route.queryParams['furtherPath']) {
            this.furtherPathQP = { furtherPath: route.queryParams['furtherPath'] };
        }
    };
    return LoginFormGuard;
}());
exports.LoginFormGuard = LoginFormGuard;
