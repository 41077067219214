"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./a-context-menu-item.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./a-context-menu-item.component");
var styles_AContextMenuItemComponent = [i0.styles];
var RenderType_AContextMenuItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AContextMenuItemComponent, data: {} });
exports.RenderType_AContextMenuItemComponent = RenderType_AContextMenuItemComponent;
function View_AContextMenuItemComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_AContextMenuItemComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AContextMenuItemComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.divider; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_AContextMenuItemComponent_0 = View_AContextMenuItemComponent_0;
function View_AContextMenuItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["a-context-menu-item", ""]], [[2, "divider", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AContextMenuItemComponent_0, RenderType_AContextMenuItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.AContextMenuItemComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).getDivider; _ck(_v, 0, 0, currVal_0); }); }
exports.View_AContextMenuItemComponent_Host_0 = View_AContextMenuItemComponent_Host_0;
var AContextMenuItemComponentNgFactory = i1.ɵccf("[a-context-menu-item]", i3.AContextMenuItemComponent, View_AContextMenuItemComponent_Host_0, { divider: "divider" }, {}, ["*"]);
exports.AContextMenuItemComponentNgFactory = AContextMenuItemComponentNgFactory;
