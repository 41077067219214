"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var isPresent = function (a) { return !!a; };
var ɵ0 = isPresent;
exports.ɵ0 = ɵ0;
var CheckTouchDirective = /** @class */ (function () {
    function CheckTouchDirective(element, control) {
        this.element = element;
        this.control = control;
    }
    CheckTouchDirective.prototype.onFocus = function () {
        var _this = this;
        if (this.control.valid) {
            this.control.control.markAsUntouched();
        }
        else {
            this.sub = this.control.control.valueChanges
                .pipe(operators_1.map(function () { return _this.control.valid; }), operators_1.filter(isPresent), operators_1.take(1))
                .subscribe(function () {
                _this.control.control.markAsUntouched();
                _this.sub.unsubscribe();
            });
        }
    };
    CheckTouchDirective.prototype.onBlur = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    return CheckTouchDirective;
}());
exports.CheckTouchDirective = CheckTouchDirective;
