"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".divider[_nghost-%COMP%]{height:1px;width:100%;margin:0;padding:10px 0}.divider[_nghost-%COMP%]:before{content:'';width:100%;height:1px;display:block;background:#ebeff2}[_nghost-%COMP%]:not(.divider){font:400 16px/20px Roboto;padding:10px 15px;color:#000;display:block;width:100%;box-sizing:border-box;cursor:pointer;margin:0;text-align:left;position:relative}[_nghost-%COMP%]:not(.divider).delete{color:#b71c1c}[_nghost-%COMP%]:not(.divider):hover{background:#eceff1}[_nghost-%COMP%]:not(.divider).active:before{content:'';width:18px;height:18px;position:absolute;right:10px;top:50%;margin-top:-9px;background:url(/assets/img/ico-check.png) center/18px no-repeat}[_nghost-%COMP%]:not(.divider).checked:before{content:'';width:18px;height:18px;position:absolute;right:10px;top:50%;margin-top:-9px;background-size:18px}"];
exports.styles = styles;
