"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BugTrackerService = /** @class */ (function () {
    function BugTrackerService(rollbar) {
        this._rollbar = rollbar;
    }
    BugTrackerService.prototype.updateUserData = function (payload) {
        return this._rollbar.configure({ payload: payload });
    };
    BugTrackerService.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return this._rollbar.log(args);
    };
    BugTrackerService.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return this._rollbar.info(args);
    };
    BugTrackerService.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return this._rollbar.warn(args);
    };
    BugTrackerService.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return this._rollbar.error(args);
    };
    BugTrackerService.prototype.critical = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return this._rollbar.critical(args);
    };
    return BugTrackerService;
}());
exports.BugTrackerService = BugTrackerService;
