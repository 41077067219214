"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isObject(val) {
    return val != null && typeof val === 'object' && Array.isArray(val) === false;
}
exports.isObject = isObject;
;
exports.objectToArray = function (object) { return Object.keys(object)
    .map(function (prop) { return object[prop]; }); };
exports.prop = function (property) { return function (obj) { return obj[property]; }; };
exports.isPresent = function (x) { return !!x; };
