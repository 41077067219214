"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TaboolaService = /** @class */ (function () {
    function TaboolaService() {
    }
    TaboolaService.prototype.push = function (value) {
        window['_tfa'] = window['_tfa'] || [];
        window['_tfa'].push(value);
    };
    return TaboolaService;
}());
exports.TaboolaService = TaboolaService;
