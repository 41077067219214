"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_service_1 = require("../auth.service/auth.service");
var constants_1 = require("../../../constants");
var api_service_1 = require("../api.service/api.service");
var api_path_1 = require("../api.service/api-path");
var operators_1 = require("rxjs/operators");
var of_1 = require("rxjs/internal/observable/of");
var isErrorWithToken = function (error) {
    return ['one_off_authorization_token_invalid'].includes(error.code);
};
var ɵ0 = isErrorWithToken;
exports.ɵ0 = ɵ0;
var AuthorizedUserGuard = /** @class */ (function () {
    function AuthorizedUserGuard(_authService, _atlazApi, _router) {
        this._authService = _authService;
        this._atlazApi = _atlazApi;
        this._router = _router;
    }
    AuthorizedUserGuard.prototype.canActivate = function (route, state) {
        var oneOffAuthorizationToken = route.queryParams['oneOffAuthorizationToken'];
        var changeEmailToken = route.queryParams['changeEmailToken'];
        if (oneOffAuthorizationToken && !this._authService.isLoggedIn) {
            return this.oneOffAuthorization(oneOffAuthorizationToken);
        }
        else if (changeEmailToken && !this._authService.isLoggedIn) {
            return this.changeEmailAuthorization(changeEmailToken);
        }
        else {
            return this.authHook();
        }
    };
    AuthorizedUserGuard.prototype.canLoad = function (route) {
        return this.authHook();
    };
    AuthorizedUserGuard.prototype.oneOffAuthorization = function (oneOffAuthorizationToken) {
        return this.authByToken('oneOffAuthorizationToken', oneOffAuthorizationToken);
    };
    AuthorizedUserGuard.prototype.changeEmailAuthorization = function (newEmailConfirmToken) {
        return this.authByToken('changeEmailToken', newEmailConfirmToken);
    };
    AuthorizedUserGuard.prototype.authByToken = function (tokenType, token) {
        var _this = this;
        var creds = (_a = {},
            _a[tokenType] = token,
            _a.credentialsType = tokenType,
            _a);
        return this._atlazApi
            .post([api_path_1.AUTH, { expand: ['usersCompanies', 'usersCompanies.company'] }], creds)
            .pipe(operators_1.map(function (resp) {
            _this._authService.authorize(resp);
            return _this._authService.isLoggedIn$;
        }), operators_1.catchError(function (e) {
            try {
                console.log(JSON.stringify(e));
            }
            catch (e) { }
            var error = e['errors']
                ? e['errors'].find(isErrorWithToken)
                : e['error']['errors']
                    ? e['error']['errors'].find(isErrorWithToken)
                    : null;
            var message = error ? error.detail : 'Access Denied';
            _this._router.navigate(['403'], { queryParams: { message: message } });
            return of_1.of(false);
        }), operators_1.mapTo(true), operators_1.take(1));
        var _a;
    };
    AuthorizedUserGuard.prototype.authHook = function () {
        var _this = this;
        return this._authService.isLoggedIn$.pipe(operators_1.take(1), operators_1.tap(function (authorized) {
            if (!authorized) {
                var returnPath = window.location.pathname;
                _this._router.navigate(['/', constants_1.ROUTER_PATH.LOGIN], {
                    queryParams: { return_path: returnPath }
                });
            }
        }));
    };
    return AuthorizedUserGuard;
}());
exports.AuthorizedUserGuard = AuthorizedUserGuard;
