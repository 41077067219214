import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  template: '<a-forbidden [message]="message"></a-forbidden>'
})
export class ForbiddenPageComponent implements OnInit {

  public message;
  constructor(private _route: ActivatedRoute) { }

  ngOnInit() {
    this.message = this._route.snapshot.queryParams['message'];
  }
}
