"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var url_1 = require("../../../util/url");
var auth_service_1 = require("../../../core/services/auth.service/auth.service");
var constants_1 = require("../../../constants");
var operators_1 = require("rxjs/operators");
var of_1 = require("rxjs/internal/observable/of");
var ChooseCompanyGuard = /** @class */ (function () {
    function ChooseCompanyGuard(_authService, _router) {
        this._authService = _authService;
        this._router = _router;
    }
    ChooseCompanyGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this._authService.activeUser$.pipe(operators_1.map(function (user) { return user.getCompanies(); }), operators_1.switchMap(function (companies) {
            var notDelCompanies = Array.isArray(companies)
                ? companies.filter(function (item) { return !item.attributes.deleted; })
                : [];
            if (!companies || !companies.length) {
                _this.redirectToCreateCompany();
                return of_1.of(false);
            }
            else if (notDelCompanies.length === 0) {
                _this._router.navigate([constants_1.ROUTER_PATH.PROFILE]);
                return of_1.of(false);
            }
            else if (notDelCompanies.length === 1) {
                window.location.href =
                    url_1.companyPortalUrl(notDelCompanies[0]) +
                        (next.queryParams['furtherPath']
                            ? '/' + next.queryParams['furtherPath']
                            : '');
                return of_1.of(false);
            }
            else {
                return of_1.of(true);
            }
        }), operators_1.take(1));
    };
    ChooseCompanyGuard.prototype.redirectToCreateCompany = function () {
        this._router.navigate([constants_1.ROUTER_PATH.SIGN_UP, constants_1.ROUTER_PATH.CREATE_COMPANY]);
    };
    return ChooseCompanyGuard;
}());
exports.ChooseCompanyGuard = ChooseCompanyGuard;
