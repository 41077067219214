"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("./api.service/api.service");
var operators_1 = require("rxjs/operators");
var of_1 = require("rxjs/internal/observable/of");
exports.DEFAULT_COUNTRY_ISO2 = 'US';
var GeoipService = /** @class */ (function () {
    function GeoipService(api) {
        this.api = api;
    }
    GeoipService.prototype.getCountryIso2 = function () {
        return this.api.get('geoip').pipe(operators_1.map(function (res) { return res.data.attributes.country_iso2 || exports.DEFAULT_COUNTRY_ISO2; }), operators_1.catchError(function (e) {
            console.log(e, 'error when get country iso2 by ip');
            return of_1.of(exports.DEFAULT_COUNTRY_ISO2);
        }), operators_1.publishReplay(1), operators_1.refCount());
    };
    return GeoipService;
}());
exports.GeoipService = GeoipService;
