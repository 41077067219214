import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { Company } from '../../models/company';
import { AuthService } from '../auth.service/auth.service';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ChooseCompanyResolver implements Resolve<Company[]> {
  constructor(private _authService: AuthService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Company[]> | Promise<Company[]> | Company[] {
    return this._authService.activeUser$.pipe(
      map(user => user.getCompanies()),
      take(1)
    );
  }
}
