"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app-header.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../atlaz-gui/components/a-context-menu/a-context-menu.component.ngfactory");
var i3 = require("../../atlaz-gui/components/a-context-menu/a-context-menu.component");
var i4 = require("../../atlaz-gui/components/a-context-menu/a-context-menu-item.component.ngfactory");
var i5 = require("../../atlaz-gui/components/a-context-menu/a-context-menu-item.component");
var i6 = require("@angular/router");
var i7 = require("../../atlaz-gui/components/a-avatar/a-avatar.component.ngfactory");
var i8 = require("../../atlaz-gui/components/a-avatar/a-avatar.component");
var i9 = require("@angular/common");
var i10 = require("./app-header.component");
var styles_AppHeaderComponent = [i0.styles];
var RenderType_AppHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppHeaderComponent, data: {} });
exports.RenderType_AppHeaderComponent = RenderType_AppHeaderComponent;
function View_AppHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["a-context-menu", ""], ["class", "profile_menu"], ["tabindex", "-1"]], null, [[null, "close"], [null, "click"], ["document", "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } if (("close" === en)) {
        var pd_2 = (_co.onToggleMenu() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_AContextMenuComponent_0, i2.RenderType_AContextMenuComponent)), i1.ɵdid(1, 1097728, null, 0, i3.AContextMenuComponent, [i1.ElementRef], null, { close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["a-context-menu-item", ""], ["routerLinkActive", "active"]], [[2, "divider", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_AContextMenuItemComponent_0, i4.RenderType_AContextMenuItemComponent)), i1.ɵdid(3, 49152, null, 0, i5.AContextMenuItemComponent, [], null, null), i1.ɵdid(4, 16384, [[1, 4]], 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(5, 1720320, null, 2, i6.RouterLinkActive, [i6.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, 0, ["Profile"])), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "div", [["a-context-menu-item", ""]], [[2, "divider", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_AContextMenuItemComponent_0, i4.RenderType_AContextMenuItemComponent)), i1.ɵdid(10, 49152, null, 0, i5.AContextMenuItemComponent, [], null, null), i1.ɵdid(11, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, 0, ["Log Out"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.toUserProfile; _ck(_v, 4, 0, currVal_1); var currVal_2 = "active"; _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.toLogout; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).getDivider; _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 10).getDivider; _ck(_v, 9, 0, currVal_3); }); }
function View_AppHeaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "header_wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "right_block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "user_menu_block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "user_avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a-avatar", [], null, null, null, i7.View_AAvatarComponent_0, i7.RenderType_AAvatarComponent)), i1.ɵdid(7, 49152, null, 0, i8.AAvatarComponent, [], { user: [0, "user"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppHeaderComponent_1)), i1.ɵdid(9, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "clear"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.isUserMenuVisible; _ck(_v, 9, 0, currVal_1); }, null); }
exports.View_AppHeaderComponent_0 = View_AppHeaderComponent_0;
function View_AppHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_AppHeaderComponent_0, RenderType_AppHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i10.AppHeaderComponent, [], null, null)], null, null); }
exports.View_AppHeaderComponent_Host_0 = View_AppHeaderComponent_Host_0;
var AppHeaderComponentNgFactory = i1.ɵccf("app-header", i10.AppHeaderComponent, View_AppHeaderComponent_Host_0, { user: "user" }, {}, []);
exports.AppHeaderComponentNgFactory = AppHeaderComponentNgFactory;
