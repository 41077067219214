"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("../../auth.service/auth.service");
var platform_1 = require("../../../../util/platform");
var object_1 = require("../../../../util/object");
var url_1 = require("../../../../util/url");
var operators_1 = require("rxjs/operators");
var compareObjectKeys = function (keys) { return function (a, b) {
    return keys.some(function (key) { return a[key] === b[key]; });
}; };
var ɵ0 = compareObjectKeys;
exports.ɵ0 = ɵ0;
var SegmentBaseService = /** @class */ (function () {
    function SegmentBaseService(_authService) {
        var _this = this;
        this._authService = _authService;
        this._authService.activeUser$
            .pipe(operators_1.filter(object_1.isPresent), 
        // to init crashTracker first
        operators_1.debounceTime(0))
            .subscribe(function (user) {
            var companies = user.getCompanies();
            _this.getInstance().identify(user.id, {
                name: user.attributes.fullname,
                createdAt: user.attributes.createdAt,
                email: user.attributes.email,
                companies: companies
                    .map(function (company) { return company.attributes.name; })
                    .join('; '),
                companySize: companies
                    .map(function (company) {
                    return company.attributes.name + ": " + (company.attributes.companySize ||
                        'unspecified');
                })
                    .join('; '),
                softwarePreferences: companies
                    .map(function (company) {
                    return company.attributes.name + ": " + (company.attributes
                        .softwarePreferences || 'unspecified');
                })
                    .join('; ')
            });
        });
    }
    SegmentBaseService.prototype.track = function (eventName, data) {
        if (data === void 0) { data = {}; }
        var utm = url_1.getUtmFromCurrentUri();
        this.getInstance().track(eventName, __assign({}, data, this.getUserInfo(), utm));
    };
    SegmentBaseService.prototype.getUserInfo = function () {
        return {
            userId: this._authService.activeUser
                ? this._authService.activeUser.id
                : null,
            email: this._authService.activeUser && this._authService.activeUser.attributes
                ? this._authService.activeUser.attributes.email
                : '',
            client: 'Web App',
            os: platform_1.platform.os.toString(),
            browser: platform_1.platform.name + ' ' + platform_1.platform.version
        };
    };
    SegmentBaseService.prototype.boardCreated = function (type) {
        this.track('BoardCreated', {
            type: type
        });
    };
    SegmentBaseService.prototype.onboardingProjectCreated = function () {
        this.track('OnboardingProjectCreated');
    };
    SegmentBaseService.prototype.onboardingMembersInvited = function () {
        this.track('OnboardingMembersInvited');
    };
    SegmentBaseService.prototype.projectCreated = function () {
        this.track('ProjectCreated');
    };
    SegmentBaseService.prototype.collectionCreated = function () {
        this.track('CollectionCreated');
    };
    SegmentBaseService.prototype.taskCreated = function () {
        this.track('TaskCreated');
    };
    SegmentBaseService.prototype.commentCreated = function (isReply) {
        this.track('TaskCreated', {
            level: isReply ? 'second' : 'first'
        });
    };
    SegmentBaseService.prototype.invitationSent = function () {
        this.track('InvitationSent');
    };
    SegmentBaseService.prototype.startTrelloImport = function (boardsCount, tasksCount) {
        this.track('TrelloImportStarted', {
            boardsCount: boardsCount,
            tasksCount: tasksCount
        });
    };
    SegmentBaseService.prototype.startJiraImport = function (projectsCount, tasksCount) {
        this.track('JiraImportStarted', {
            projectsCount: projectsCount,
            tasksCount: tasksCount
        });
    };
    return SegmentBaseService;
}());
exports.SegmentBaseService = SegmentBaseService;
