"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var ForbiddenPageComponent = /** @class */ (function () {
    function ForbiddenPageComponent(_route) {
        this._route = _route;
    }
    ForbiddenPageComponent.prototype.ngOnInit = function () {
        this.message = this._route.snapshot.queryParams['message'];
    };
    return ForbiddenPageComponent;
}());
exports.ForbiddenPageComponent = ForbiddenPageComponent;
