<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" style="position:absolute;z-index:9999;top:50%;left:50%;margin-left:-40px;margin-top:-40px;width:80px;height:80px" white-space="pre">
  <style>
    @keyframes spin { 0% {transform-origin: center; transform: rotate(0deg); } 100% {transform-origin: center; transform: rotate(360deg); } }
  </style>
  <g style="animation: spin 1s linear infinite;">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM78 40C78 60.9868 60.9868 78 40 78C19.0132 78 2 60.9868 2 40C2 19.0132 19.0132 2 40 2C60.9868 2 78 19.0132 78 40Z" fill="black" fill-opacity="0.05" style="mix-blend-mode:multiply"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V2C20.9868 2 38 19.0132 38 40H40C40 17.9086 22.0914 0 0 0Z" transform="translate(40)" fill="#3392E6"/>
  </g>
  <path opacity="0.55" d="M15.28 11.1373L4 0L0 3.92157H4.96V21.2549L15.28 11.1373Z" transform="translate(20.08 32.3137)" fill="#3392E6"/>
  <path opacity="0.7" d="M0 0H20.9598V15.6861H0V0Z" transform="translate(25.2632 31.3709) rotate(-45)" fill="#3392E6"/>
  <path opacity="0.85" d="M29.92 10.4314L19.2 0L0 18.8235H29.92V10.4314Z" transform="translate(25.04 36.9412)" fill="#3392E6"/>
  <path d="M9.6 16.3137V7.37255H14.56L7.12 0L0 6.98039L9.6 16.3137Z" transform="translate(45.36 28.8627)" fill="#3392E6"/>
</svg>
