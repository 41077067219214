"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var check_touch_directive_1 = require("./check-touch.directive");
var general_error_message_component_1 = require("./general-error-message/general-error-message.component");
var SHARED_DECLARATIONS = [
    check_touch_directive_1.CheckTouchDirective,
    general_error_message_component_1.GeneralErrorMessageComponent,
];
var AFormsModule = /** @class */ (function () {
    function AFormsModule() {
    }
    return AFormsModule;
}());
exports.AFormsModule = AFormsModule;
