import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { scan } from 'rxjs/operators';

const searchText = (text, key) =>
  key !== 'backspace' ? text + key : text.slice(0, -1);
const isBackspace = keyCode => keyCode === 8;

const printableCode = keyCode =>
  (keyCode > 47 && keyCode < 58) || // number keys
  keyCode == 32 || // spacebar & return key(s) (if you want to allow carriage returns)
  (keyCode > 64 && keyCode < 91) || // letter keys
  (keyCode > 95 && keyCode < 112) || // numpad keys
  (keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
  (keyCode > 218 && keyCode < 223); // [\]' (in order)

@Component({
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() items: any[] = [];
  @Input() tabIndex = -1;
  @Input() placeholder: string;
  // don't use me yet
  // @Input() filterFn: <T>(items: T[], search: string) => T[] = (items, search) => items.filter(item => item.name.indexOf(search) > -1);

  @Input() selectedItem;

  @Output() selectItem = new EventEmitter();

  @ViewChild('select_input') selectTitle: ElementRef;

  @HostBinding('attr.tabindex') tabindex = this.tabIndex;

  public searchString$ = new Subject();
  public visibleItems: { id: string; name: string }[];
  public showDropdown = false;

  @Input() filterFn: <T>(items: T[], search: string) => T[] = n => n;
  @HostListener('keydown', ['$event'])
  onFilter(event) {
    if (printableCode(event.keyCode) || isBackspace(event.keyCode)) {
      this.searchString$.next(event.key.toLowerCase());
    }
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (
      event.target === this._eref.nativeElement ||
      this.selectTitle.nativeElement.contains(event.target)
    ) {
      this.onToggleDropdown();
    }
  }

  constructor(private _eref: ElementRef) {}

  ngOnInit() {
    this.searchString$.pipe(scan(searchText, '')).subscribe(searchText => {
      console.log(searchText, 'sText');
      this.visibleItems = this.filterFn(this.items, searchText);
    });
  }

  ngOnChanges() {
    this.visibleItems = this.items || [];
  }

  ngAfterViewInit() {
    // don't use me yet
    // this._eref.nativeElement.focus(true);
  }

  public onSelectItem(item) {
    this.selectedItem = item;
    this.selectItem.emit(this.selectedItem);
    this.showDropdown = false;
    return false;
  }

  onToggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }
}
