"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var CheckEmailComponent = /** @class */ (function () {
    function CheckEmailComponent(_cd) {
        this._cd = _cd;
        this.status = 'sent';
        this.showFooter = false;
        this.sendIntervalInMinutes = 2;
    }
    CheckEmailComponent.prototype.ngOnChanges = function (change) {
        var _this = this;
        if (change.hasOwnProperty('status') &&
            change.status.currentValue !== 'error') {
            this.title = this.getTitle();
        }
        if (change.status.currentValue === 'error') {
            this.countdown$ = rxjs_1.timer(0, 1000).pipe(operators_1.map(function (x) { return 120 - x; }), operators_1.takeWhile(function (x) { return x > -1; }), operators_1.map(function (x) {
                return Math.floor(x / 60) +
                    ':' +
                    (x % 60 > 9 ? x % 60 : x % 60 !== 0 ? '0' + x % 60 : '00');
            }));
            this.sub = this.countdown$.subscribe({
                complete: function () {
                    _this.status = 'resent';
                    _this._cd.detectChanges();
                }
            });
        }
    };
    CheckEmailComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    Object.defineProperty(CheckEmailComponent.prototype, "isSendError", {
        get: function () {
            return this.status === 'error';
        },
        enumerable: true,
        configurable: true
    });
    CheckEmailComponent.prototype.getTitle = function () {
        return !!this.titles && !!this.status ? this.titles[this.status] : '';
    };
    return CheckEmailComponent;
}());
exports.CheckEmailComponent = CheckEmailComponent;
