import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AButtonComponent } from './components/a-button/a-button.component';
import { ALoaderComponent } from './components/a-loader/a-loader.component';
import { AAvatarComponent } from './components/a-avatar/a-avatar.component';
import { AContextMenuItemComponent } from './components/a-context-menu/a-context-menu-item.component';
import { AContextMenuComponent } from './components/a-context-menu/a-context-menu.component';
import { APopupComponent } from './components/a-popup/a-popup.component';


const components = [
  AButtonComponent,
  ALoaderComponent,
  AAvatarComponent,
  AContextMenuComponent,
  AContextMenuItemComponent,
  APopupComponent
];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: components,
    exports: components
})
export class AtlazWebGuiModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AtlazWebGuiModule
        };
    }
}
