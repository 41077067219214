"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var index_1 = require("./index");
var company_1 = require("./company");
var UsersCompany = /** @class */ (function (_super) {
    __extends(UsersCompany, _super);
    function UsersCompany() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UsersCompany.prototype.getCompany = function () {
        return index_1.getOne(this.originalResponse, 'company', company_1.Company);
    };
    return UsersCompany;
}(index_1.BaseModel));
exports.UsersCompany = UsersCompany;
