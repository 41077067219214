"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]{padding:10px 0;position:absolute;background:#fff;border-radius:2px;box-shadow:0 0 4px 0 rgba(0,0,0,.4);z-index:9999}.slim[_nghost-%COMP%]{width:160px}[_nghost-%COMP%]:not(.slim){width:241px}.right[_nghost-%COMP%]{right:0}.left[_nghost-%COMP%]{left:0}.top[_nghost-%COMP%]{top:44px}[_nghost-%COMP%]:not(.top){top:0}[_nghost-%COMP%]::before{position:fixed;content:'';top:0;right:0;left:0;bottom:0;cursor:default}[_nghost-%COMP%]   .content[_ngcontent-%COMP%]{position:relative}"];
exports.styles = styles;
