import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'firstname-field',
  templateUrl: './firstname-field.component.html'
})
export class FirstnameFieldComponent {

  @Input() field: FormControl;
  @Input() focus: boolean;
  @Input() placeholder = 'John';

}
