import { Injectable } from '@angular/core';
import { AtlazApiService } from './api.service/api.service';
import { Observable } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

export const DEFAULT_COUNTRY_ISO2 = 'US';

@Injectable()
export class GeoipService {
  constructor(private api: AtlazApiService) {}

  getCountryIso2(): Observable<string> {
    return this.api.get('geoip').pipe(
      map(
        (res: any) => res.data.attributes.country_iso2 || DEFAULT_COUNTRY_ISO2
      ),
      catchError(e => {
        console.log(e, 'error when get country iso2 by ip');
        return of(DEFAULT_COUNTRY_ISO2);
      }),
      publishReplay(1),
      refCount()
    );
  }
}
