"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var constants_1 = require("../../../constants");
var api_service_1 = require("../../../core/services/api.service/api.service");
var auth_service_1 = require("../../../core/services/auth.service/auth.service");
var api_path_1 = require("../../../core/services/api.service/api-path");
var operators_1 = require("rxjs/operators");
var of_1 = require("rxjs/internal/observable/of");
var LogoutGuard = /** @class */ (function () {
    function LogoutGuard(_atlazApi, _authService, _router) {
        this._atlazApi = _atlazApi;
        this._authService = _authService;
        this._router = _router;
    }
    LogoutGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this._atlazApi.deleteRequest(api_path_1.AUTH).pipe(operators_1.catchError(function (e) {
            return of_1.of(false);
        }), operators_1.map(function (_) {
            _this._authService.activeUser = null;
            _this._authService.isLoggedIn$.next(false);
        }), operators_1.tap(function (_) {
            _this._router.navigate(['/', constants_1.ROUTER_PATH.LOGIN]);
        }), operators_1.mapTo(false));
    };
    return LogoutGuard;
}());
exports.LogoutGuard = LogoutGuard;
