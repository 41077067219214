import { Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { ROLLBAR_TOKEN } from './rollbar';

@Injectable()
export class BugTrackerService {
  // this service shouldn't have any dependencies to avoid circular dependencies issue
  private _rollbar: Rollbar;

  constructor(@Inject(ROLLBAR_TOKEN) rollbar) {
    this._rollbar = rollbar;
  }

  updateUserData(payload: any) {
    return this._rollbar.configure({ payload });
  }

  log(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    return this._rollbar.log(args);
  }
  info(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    return this._rollbar.info(args);
  }
  warn(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    return this._rollbar.warn(args);
  }
  error(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    return this._rollbar.error(args);
  }
  critical(...args: Rollbar.LogArgument[]): Rollbar.LogResult {
    return this._rollbar.critical(args);
  }
}
