import { Injectable } from '@angular/core';
import { AtlazApiService } from '../api.service/api.service';
import { JsonApiSingeModelResponse } from '../api.service/http-response';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class InviteLinkService {
  constructor(private _apiService: AtlazApiService) {}

  validateInviteLink(token: string) {
    return this._apiService
      .get('invitations-link/' + token)
      .pipe(
        map((resp: JsonApiSingeModelResponse<any>) => resp.data),
        publishReplay(1),
        refCount()
      );
  }
}
