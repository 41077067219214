"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("../api.service/api.service");
var operators_1 = require("rxjs/operators");
var InviteLinkService = /** @class */ (function () {
    function InviteLinkService(_apiService) {
        this._apiService = _apiService;
    }
    InviteLinkService.prototype.validateInviteLink = function (token) {
        return this._apiService
            .get('invitations-link/' + token)
            .pipe(operators_1.map(function (resp) { return resp.data; }), operators_1.publishReplay(1), operators_1.refCount());
    };
    return InviteLinkService;
}());
exports.InviteLinkService = InviteLinkService;
