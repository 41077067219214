"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../../../../environments/environment");
var fakeInstance = function () { return null; };
var ɵ0 = fakeInstance;
exports.ɵ0 = ɵ0;
var debugInstance = function () {
    var arg = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arg[_i] = arguments[_i];
    }
    return console.log('FbPixel', arg);
};
var ɵ1 = debugInstance;
exports.ɵ1 = ɵ1;
var FbPixelService = /** @class */ (function () {
    function FbPixelService() {
        if (environment_1.environment.production) {
            if (window['fbq']) {
                this.instance = window['fbq'];
            }
            else {
                console.warn('dataLayer is missed');
                this.instance = fakeInstance;
            }
        }
        else {
            this.instance = debugInstance;
        }
    }
    Object.defineProperty(FbPixelService.prototype, "fbq", {
        get: function () {
            return this.instance;
        },
        enumerable: true,
        configurable: true
    });
    return FbPixelService;
}());
exports.FbPixelService = FbPixelService;
