import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { User } from '../../models/user';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service/auth.service';
import { take } from 'rxjs/operators';

@Injectable()
export class ActiveUserResolver implements Resolve<User> {
  constructor(private _authService: AuthService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<User> | Promise<User> | User {
    return this._authService.activeUser$.pipe(take(1));
  }
}
