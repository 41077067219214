
import { BaseModel, getOne } from './index';
import { User } from './user';

export class Credentials extends BaseModel {

  getUser(): User {
    return <User>getOne(this.originalResponse, 'user', User);
  }
}
