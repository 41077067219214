"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var form_service_1 = require("../../core/services/form.service/form.service");
var AccountFormComponent = /** @class */ (function () {
    function AccountFormComponent(_formService) {
        this._formService = _formService;
        this.isError = false;
        this.errors = [];
    }
    AccountFormComponent.prototype.ngOnInit = function () { };
    return AccountFormComponent;
}());
exports.AccountFormComponent = AccountFormComponent;
