"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./choose-compnay.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../layout/app-header/app-header.component.ngfactory");
var i3 = require("../../layout/app-header/app-header.component");
var i4 = require("@angular/common");
var i5 = require("./choose-compnay.component");
var i6 = require("@angular/router");
var styles_ChooseCompanyComponent = [i0.styles];
var RenderType_ChooseCompanyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChooseCompanyComponent, data: {} });
exports.RenderType_ChooseCompanyComponent = RenderType_ChooseCompanyComponent;
function View_ChooseCompanyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "company_item"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "ico_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "company_item_name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "ico_login_arrow"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyUrls[_v.context.$implicit.id]; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.attributes.name[0]; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.attributes.name; _ck(_v, 4, 0, currVal_2); }); }
function View_ChooseCompanyComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_AppHeaderComponent_0, i2.RenderType_AppHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.AppHeaderComponent, [], { user: [0, "user"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "company_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select Company"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChooseCompanyComponent_1)), i1.ɵdid(5, 802816, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.companies; _ck(_v, 5, 0, currVal_1); }, null); }
exports.View_ChooseCompanyComponent_0 = View_ChooseCompanyComponent_0;
function View_ChooseCompanyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "choose-compnay", [], null, null, null, View_ChooseCompanyComponent_0, RenderType_ChooseCompanyComponent)), i1.ɵdid(1, 114688, null, 0, i5.ChooseCompanyComponent, [i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ChooseCompanyComponent_Host_0 = View_ChooseCompanyComponent_Host_0;
var ChooseCompanyComponentNgFactory = i1.ɵccf("choose-compnay", i5.ChooseCompanyComponent, View_ChooseCompanyComponent_Host_0, {}, {}, []);
exports.ChooseCompanyComponentNgFactory = ChooseCompanyComponentNgFactory;
