import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective implements AfterViewInit {

  @Input() focus: boolean;

  constructor(private _element: ElementRef) {
  }

  ngAfterViewInit() {
    if (this.focus) {
      this._element.nativeElement.focus();
    }
  }
}
