import { BaseModel, getOne } from './index';
import { Company } from './company';

export class UsersCompany extends BaseModel {

  getCompany(): Company {
    return getOne(this.originalResponse, 'company', Company);
  }

}
