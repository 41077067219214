"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var LastNameFieldComponent = /** @class */ (function () {
    function LastNameFieldComponent() {
        this.placeholder = 'Johnson';
    }
    return LastNameFieldComponent;
}());
exports.LastNameFieldComponent = LastNameFieldComponent;
