"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".header_wrapper[_ngcontent-%COMP%]{display:block;position:relative;height:45px;max-width:100%;padding:10px 20px 5px;text-align:center;min-width:950px;background:#1e88e5}.logo[_ngcontent-%COMP%]{margin-left:auto;cursor:pointer;display:inline-block;width:150px;height:36px;background:url(/assets/img/logo_hygger.svg) 50%/150px 36px no-repeat}.right_block[_ngcontent-%COMP%]{position:absolute;right:0;top:10px;margin:0}.user_menu_block[_ngcontent-%COMP%]{margin-right:10px;cursor:pointer}.user_menu_block[_ngcontent-%COMP%]:after{content:'';clear:both;display:table}.user_menu_block[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin-top:10px;display:inline-block;color:#fff;font:400 16px Roboto,sans-serif}.user_menu_block[_ngcontent-%COMP%]   .profile_menu[_ngcontent-%COMP%]{position:absolute;z-index:100;top:31px;right:5px}.user_avatar[_ngcontent-%COMP%]{float:left;margin-right:10px}.user_avatar[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:40px;height:40px}.clear[_ngcontent-%COMP%]{clear:both}"];
exports.styles = styles;
