import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

interface Instance {
  push: Function;
}

const fakeInstance = {
  push: () => null
};

const debugInstance = {
  push: (event) => console.log('GoogleAnalyticsService', event)
};

@Injectable()
export class GoogleAnalyticsService {

  private instance: Instance;

  constructor() {

    if (environment.production) {
      if (window[ 'dataLayer' ]) {
        this.instance = window[ 'dataLayer' ];
      } else {
        console.warn('dataLayer is missed');
        this.instance = fakeInstance;
      }
    } else {
      this.instance = debugInstance;
    }
  }

  get getInstance() {
    return this.instance;
  }

}
