"use strict";
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var api_service_1 = require("../api.service/api.service");
var user_1 = require("../../models/user");
var http_1 = require("../../interfaces/http");
var credentials_1 = require("../../models/credentials");
var models_1 = require("../../models");
var bug_tracker_service_1 = require("../bug-tracker.service/bug-tracker.service");
var operators_1 = require("rxjs/operators");
var of_1 = require("rxjs/internal/observable/of");
function getStoredAuthData() {
    var cache = localStorage.getItem('auth');
    var data;
    try {
        data = JSON.parse(cache);
    }
    finally {
        if (!data) {
            data = {};
        }
    }
    return data;
}
var AuthService = /** @class */ (function () {
    function AuthService(_atlazApi, _bagTracker) {
        var _this = this;
        this._atlazApi = _atlazApi;
        this._bagTracker = _bagTracker;
        this.isLoggedIn$ = new rxjs_1.ReplaySubject(1);
        this.isLoggedIn = false;
        this.activeUser$ = new rxjs_1.ReplaySubject(1);
        this.activeUser = null;
        this.authorizedUsers$ = new rxjs_1.ReplaySubject(1);
        this.companyUsers = [];
        this.fetchAuthorizedUsers();
        this.isLoggedIn$.subscribe(function (x) { return (_this.isLoggedIn = x); });
        this.activeUser$.subscribe(function (x) { return (_this.activeUser = x); });
        this.activeUser$.subscribe(function (activeUser) {
            _this._bagTracker.updateUserData({
                person: __assign({}, activeUser.attributes, { id: activeUser.id })
            });
        });
    }
    AuthService.prototype.storeAuthData = function (user) {
        localStorage.setItem('auth', JSON.stringify({
            accessToken: this.accessToken,
            userId: user.id
        }));
        this.setLoggedUser(user);
    };
    AuthService.prototype.authorize = function (resp) {
        var cred = new credentials_1.Credentials(resp);
        this.accessToken = cred.attributes.accessToken;
        this.storeAuthData(cred.getUser());
    };
    AuthService.prototype.fetchAuthorizedUsers = function () {
        var _this = this;
        this._atlazApi
            .get('users', {
            expand: ['usersCompanies', 'usersCompanies.company']
        })
            .pipe(operators_1.map(function (resp) { return models_1.getModeList(resp, user_1.User); }))
            .subscribe(function (users) {
            _this.authorizedUsers$.next(users);
            _this.storeAuthData(users[0]);
            _this.companyUsers = users[0].included.filter(function (inc) { return inc.type === 'usersCompany'; });
        }, function (error) {
            if (error.status === http_1.HTTP_CODES.UNAUTHORIZED) {
                _this.isLoggedIn$.next(false);
            }
            else {
                console.error(error);
            }
        });
    };
    AuthService.prototype.setLoggedUser = function (user) {
        this.activeUser$.next(user);
        this.isLoggedIn$.next(true);
    };
    AuthService.prototype.checkSignUp = function (token) {
        return of_1.of(true);
    };
    return AuthService;
}());
exports.AuthService = AuthService;
