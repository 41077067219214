"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var function_1 = require("./util/function");
var rawCodes = require("./../../assets/phone-codes.json");
var of_1 = require("rxjs/internal/observable/of");
var operators_1 = require("rxjs/operators");
var codes = rawCodes;
var PhoneCodesService = /** @class */ (function () {
    function PhoneCodesService() {
    }
    PhoneCodesService.prototype.getPhoneCodes = function () {
        return of_1.of(codes).pipe(operators_1.map(function_1.sortBy(function (item) { return item.name.toLowerCase(); })));
    };
    return PhoneCodesService;
}());
exports.PhoneCodesService = PhoneCodesService;
