"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../shared/40x-page/a-not-found/a-not-found.component.ngfactory");
var i2 = require("../../../shared/40x-page/a-not-found/a-not-found.component");
var i3 = require("./not-found-page.component");
var styles_NotFoundPageComponent = [];
var RenderType_NotFoundPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundPageComponent, data: {} });
exports.RenderType_NotFoundPageComponent = RenderType_NotFoundPageComponent;
function View_NotFoundPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a-not-found", [], null, null, null, i1.View_NotFoundComponent_0, i1.RenderType_NotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i2.NotFoundComponent, [], null, null)], null, null); }
exports.View_NotFoundPageComponent_0 = View_NotFoundPageComponent_0;
function View_NotFoundPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotFoundPageComponent_0, RenderType_NotFoundPageComponent)), i0.ɵdid(1, 49152, null, 0, i3.NotFoundPageComponent, [], null, null)], null, null); }
exports.View_NotFoundPageComponent_Host_0 = View_NotFoundPageComponent_Host_0;
var NotFoundPageComponentNgFactory = i0.ɵccf("ng-component", i3.NotFoundPageComponent, View_NotFoundPageComponent_Host_0, {}, {}, []);
exports.NotFoundPageComponentNgFactory = NotFoundPageComponentNgFactory;
