export interface HttpQueryParam {
  id?: number;
  sort?: string;
  expand?: string | string[];
  limit?: string | number;
  offset?: string | number;
  [param: string]: any;
}

export interface ServerErrorResponse {
  code: string;
  detail: string;
  message: string;

  errors?: ServerError[];
}

export interface ServerError {
  code: string;
  detail: string;
  field: string;
  message: string;
  errors?: ServerError[];
}

export const HTTP_CODES = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  ACCESS_DENIED: 403
};
