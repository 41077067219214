import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '../../../core/services/auth.service/auth.service';
import { ROUTER_PATH } from '../../../constants';
import { take } from 'rxjs/operators';

@Injectable()
export class RootPathGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) {}

  authHook(initialQuery) {
    this._authService.isLoggedIn$
      .pipe(take(1))
      .subscribe(authorized =>
        this._router.navigate(
          [authorized ? ROUTER_PATH.CHOOSE_COMPANY : ROUTER_PATH.LOGIN],
          { queryParams: initialQuery }
        )
      );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.authHook(route.queryParams);

    return false;
  }
}
