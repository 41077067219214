"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./google-sso.component");
var i2 = require("ng-gapi/lib/GoogleAuthService");
var i3 = require("../../core/services/auth.service/auth.service");
var i4 = require("../../core/services/api.service/api.service");
var i5 = require("@angular/router");
var i6 = require("../../core/services/intergations/segment/segment.service");
var styles_GoogleSsoComponent = [];
var RenderType_GoogleSsoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GoogleSsoComponent, data: {} });
exports.RenderType_GoogleSsoComponent = RenderType_GoogleSsoComponent;
function View_GoogleSsoComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
exports.View_GoogleSsoComponent_0 = View_GoogleSsoComponent_0;
function View_GoogleSsoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_GoogleSsoComponent_0, RenderType_GoogleSsoComponent)), i0.ɵdid(1, 114688, null, 0, i1.GoogleSsoComponent, [i0.NgZone, i2.GoogleAuthService, i3.AuthService, i4.AtlazApiService, i5.ActivatedRoute, i6.SegmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_GoogleSsoComponent_Host_0 = View_GoogleSsoComponent_Host_0;
var GoogleSsoComponentNgFactory = i0.ɵccf("ng-component", i1.GoogleSsoComponent, View_GoogleSsoComponent_Host_0, {}, {}, []);
exports.GoogleSsoComponentNgFactory = GoogleSsoComponentNgFactory;
