"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var FirstnameFieldComponent = /** @class */ (function () {
    function FirstnameFieldComponent() {
        this.placeholder = 'John';
    }
    return FirstnameFieldComponent;
}());
exports.FirstnameFieldComponent = FirstnameFieldComponent;
