import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountFormComponent } from './account-form/account-form.component';
import { AtlazWebGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { PhoneCodesModule } from '../phone-codes/phone-codes.module';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordFieldComponent } from './account-form/password-field/password-field.component';
import { RouterModule } from '@angular/router';
import { EmailFieldComponent } from './account-form/email-field/email-field.component';
import { CheckEmailComponent } from './check-email/check-email.component';
import { FocusDirective } from './focus.directive';
import { FirstnameFieldComponent } from './account-form/firstname-field/firstname-field.component';
import { LastNameFieldComponent } from './account-form/lastname-field/lastname-field.component';
import { AFormsModule } from './to-publish/a-forms/a-forms.module';
import { LeftContainerComponent } from './containers/left-container/left-container.component';
import { HeaderContainerComponent } from './containers/header-container/header-container.component';
import { LayoutModule } from '../layout/layout.module';
import { NotFoundComponent } from './40x-page/a-not-found/a-not-found.component';
import { ForbiddenComponent } from './40x-page/a-forbidden/a-forbidden.component';
import { ABadRequestComponent } from './40x-page/a-bad-request/a-bad-request.component';
import { ScrollToTopModule } from './scroll-to-top.module';
import { PaymentRequiredComponent } from './40x-page/a-payment-required/a-payment-required.component';
import { PrivacyAgreeFieldComponent } from './account-form/privacy-agree-field/privacy-agree-field.component';
import { HttpClientModule } from '@angular/common/http';
import { ClickOutDirective } from './click-out/click-out.directive';

const SHARED_DECLARATIONS = [
  AccountFormComponent,
  FormSelectComponent,
  EmailFieldComponent,
  PasswordFieldComponent,
  CheckEmailComponent,
  FocusDirective,
  ClickOutDirective,
  FirstnameFieldComponent,
  LastNameFieldComponent,
  LeftContainerComponent,
  HeaderContainerComponent,
  NotFoundComponent,
  ForbiddenComponent,
  PaymentRequiredComponent,
  ABadRequestComponent,
  PrivacyAgreeFieldComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    AFormsModule,
    ScrollToTopModule
  ],
  declarations: [...SHARED_DECLARATIONS],
  exports: [
    ...SHARED_DECLARATIONS,
    CommonModule,
    AtlazWebGuiModule,
    PhoneCodesModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    LayoutModule,
    AFormsModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
