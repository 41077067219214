"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var constants_1 = require("../../../constants");
var PasswordFieldComponent = /** @class */ (function () {
    function PasswordFieldComponent() {
        this.hintType = 'forgot';
        this.label = 'Password';
        this.placeholder = 'Your Password';
        this.inputId = 'pass';
        this.passwordType = 'password';
        this.toResetPass = ['/', constants_1.ROUTER_PATH.FORGOT, constants_1.ROUTER_PATH.SEND_EMAIL];
    }
    Object.defineProperty(PasswordFieldComponent.prototype, "isHiddenPassword", {
        get: function () {
            return this.passwordType === 'password';
        },
        enumerable: true,
        configurable: true
    });
    PasswordFieldComponent.prototype.onTogglePassword = function () {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    };
    return PasswordFieldComponent;
}());
exports.PasswordFieldComponent = PasswordFieldComponent;
