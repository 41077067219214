"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
// it's function because when you save pass, min length = 6, when try to login = 5
exports.passwordValidator = function (minLength) {
    if (minLength === void 0) { minLength = 6; }
    return forms_1.Validators.compose([
        forms_1.Validators.required,
        forms_1.Validators.pattern(/^\S+$/),
        forms_1.Validators.minLength(minLength)
    ]);
};
exports.emailValidator = forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email]);
exports.firstNameValidator = forms_1.Validators.compose([
    forms_1.Validators.required,
    forms_1.Validators.maxLength(50)
]);
exports.lastNameValidator = exports.firstNameValidator;
function callbackValidator(callbackFn) {
    return function (c) {
        return callbackFn(c.value)
            ? null
            : { compare: {
                    valid: false
                }
            };
    };
}
exports.callbackValidator = callbackValidator;
exports.notEqual = function (a) { return function (b) { return a !== b; }; };
exports.notEqualValidator = function (valueToCompare) { return callbackValidator(exports.notEqual(valueToCompare)); };
