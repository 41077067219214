"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("../40x-page.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./a-payment-required.component");
var styles_PaymentRequiredComponent = [i0.styles];
var RenderType_PaymentRequiredComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentRequiredComponent, data: {} });
exports.RenderType_PaymentRequiredComponent = RenderType_PaymentRequiredComponent;
function View_PaymentRequiredComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "error_page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "error_page_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Users limit is reached!"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "error_page_img"], ["src", "/assets/img/403-error.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "error_page_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry!"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "error_page_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 7, 0, currVal_0); }); }
exports.View_PaymentRequiredComponent_0 = View_PaymentRequiredComponent_0;
function View_PaymentRequiredComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a-payment-required", [], null, null, null, View_PaymentRequiredComponent_0, RenderType_PaymentRequiredComponent)), i1.ɵdid(1, 49152, null, 0, i2.PaymentRequiredComponent, [], null, null)], null, null); }
exports.View_PaymentRequiredComponent_Host_0 = View_PaymentRequiredComponent_Host_0;
var PaymentRequiredComponentNgFactory = i1.ɵccf("a-payment-required", i2.PaymentRequiredComponent, View_PaymentRequiredComponent_Host_0, { message: "message" }, {}, []);
exports.PaymentRequiredComponentNgFactory = PaymentRequiredComponentNgFactory;
