import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckTouchDirective } from './check-touch.directive';
import { GeneralErrorMessageComponent } from './general-error-message/general-error-message.component';

const SHARED_DECLARATIONS = [
  CheckTouchDirective,
  GeneralErrorMessageComponent,
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...SHARED_DECLARATIONS
  ],
  exports: [
    ...SHARED_DECLARATIONS
  ]
})
export class AFormsModule { }
