"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%] > div[_ngcontent-%COMP%]{text-align:center;padding:10px;width:calc(100% - 50px);margin:10px auto 20px;color:#d50000;font:400 16px Roboto}.in-form-error[_nghost-%COMP%] > div[_ngcontent-%COMP%]{text-align:left;padding:10px;width:auto;margin:0}"];
exports.styles = styles;
