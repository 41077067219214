import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { companyPortalUrl } from '../../../util/url';
import { AuthService } from '../../../core/services/auth.service/auth.service';
import { ROUTER_PATH } from '../../../constants';
import { map, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class ChooseCompanyGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._authService.activeUser$.pipe(
      map(user => user.getCompanies()),
      switchMap(companies => {
        const notDelCompanies = Array.isArray(companies)
          ? companies.filter(item => !item.attributes.deleted)
          : [];
        if (!companies || !companies.length) {
          this.redirectToCreateCompany();
          return of(false);
        } else if (notDelCompanies.length === 0) {
          this._router.navigate([ROUTER_PATH.PROFILE]);
          return of(false);
        } else if (notDelCompanies.length === 1) {
          window.location.href =
            companyPortalUrl(notDelCompanies[0]) +
            (next.queryParams['furtherPath']
              ? '/' + next.queryParams['furtherPath']
              : '');
          return of(false);
        } else {
          return of(true);
        }
      }),
      take(1)
    );
  }

  private redirectToCreateCompany() {
    this._router.navigate([ROUTER_PATH.SIGN_UP, ROUTER_PATH.CREATE_COMPANY]);
  }
}
