"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../environments/environment");
var Rollbar = require("rollbar");
var rollbar_1 = require("./services/bug-tracker.service/rollbar");
function RollbarFactory() {
    return environment_1.environment.rollbarConfig
        ? new Rollbar(environment_1.environment.rollbarConfig)
        : new rollbar_1.RollbarDymmy();
}
exports.RollbarFactory = RollbarFactory;
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
exports.CoreModule = CoreModule;
