import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '../auth.service/auth.service';
import { InviteLinkService } from '../invite-link/invite-link.service';
import { goToAtlazWeb } from '../../../util/url';
import { AtlazApiService } from '../api.service/api.service';
import { of } from 'rxjs/internal/observable/of';
import { catchError, debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { JsonApiSingeModelResponse } from '../api.service/http-response';
import { JobStatusService } from '../job-status.service/job-status.service';

@Injectable()
export class InviteLinkGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _atlazApi: AtlazApiService,
    private _jobs: JobStatusService,
    private _inviteLinkService: InviteLinkService,
    private _router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest(
      this._inviteLinkService.validateInviteLink(route.params['token']).pipe(
        catchError(e => {
          if (e.status === 402) {
            this._router.navigate(['402'], {
              queryParams: {
                message: `The company you're trying to join to has reached free users limit. Please contact one who's invited you.`
              }
            });
          } else {
            this._router.navigate(['404'], {
              queryParams: { message: 'Invalid Link' }
            });
          }
          return observableThrowError(e);
        })
      ),
      this._authService.isLoggedIn$
    ).pipe(
      switchMap(([company, isLogged]) => {
        if (isLogged) {
          return this._atlazApi
            .post('invitations-link', { token: route.params['token'] })
            .pipe(
              switchMap(this._jobs.trackJobStatus),
              switchMap((res: JsonApiSingeModelResponse<any>) => {
                if (res.data.attributes.success) {
                  goToAtlazWeb(company);
                } else {
                  this._router.navigate(['403'], {
                    queryParams: {
                      message: `The payment for your account has failed. Please, contact Company Admin or Company Owner.`
                    }
                  });
                }
                return of(false);
              }),
              catchError(() => {
                this._router.navigate(['400'], {
                  queryParams: {
                    message:
                      'Some error occurred during confirmation. Please refresh the page. If problem persists, please contact support.'
                  }
                });
                return of(false);
              }),
              switchMap(() => of(false).pipe(debounceTime(10000)))
            );
        }
        return of(true);
      }),
      catchError(e => {
        if (e.status === 402) {
          this._router.navigate(['402'], {
            queryParams: {
              message: `The company you're trying to join to has reached free users limit. Please contact one who's invited you.`
            }
          });
        } else {
          this._router.navigate(['404'], {
            queryParams: { message: 'Invalid Link' }
          });
        }
        return of(false);
      }),
      take(1)
    );
  }
}
