"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LeftContainerComponent = /** @class */ (function () {
    function LeftContainerComponent() {
    }
    LeftContainerComponent.prototype.ngOnInit = function () {
    };
    return LeftContainerComponent;
}());
exports.LeftContainerComponent = LeftContainerComponent;
