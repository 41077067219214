"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".ico_text[_ngcontent-%COMP%]{width:40px;height:40px;box-sizing:border-box;border-radius:4px;background:#cdd9dd;margin:9px;text-transform:capitalize;font-weight:500;font-size:18px;font-family:Roboto;color:#fff;text-align:center;line-height:40px;float:left}.company_title[_ngcontent-%COMP%]{display:block;font-weight:500;font-size:18px;font-family:Roboto;color:#000;margin:30px 0;text-align:center}.company_item[_ngcontent-%COMP%]{position:relative;width:480px;border:1px solid #d0d7db;margin:10px auto;display:block;height:60px;box-sizing:border-box;border-radius:3px;cursor:pointer;background:#fff;transition:box-shadow .1s}.company_item[_ngcontent-%COMP%]:after{content:'';display:table;clear:both}.company_item[_ngcontent-%COMP%]:hover{box-shadow:0 2px 4px 0 rgba(0,0,0,.2)}.company_item_name[_ngcontent-%COMP%]{font-weight:400;font-size:18px;font-family:Roboto;color:#000;line-height:58px;max-width:360px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;margin-left:10px;display:inline-block}"];
exports.styles = styles;
