"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortBy = function (prop) { return function (items) { return (items.sort(function (a, b) {
    var aField = prop(a);
    var bField = prop(b);
    if (aField > bField) {
        return 1;
    }
    if (aField < bField) {
        return -1;
    }
    // a должно быть равным b
    return 0;
})); }; };
