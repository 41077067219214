"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var defaultPadding = 5;
var AContextMenuComponent = /** @class */ (function () {
    function AContextMenuComponent(_eref) {
        this._eref = _eref;
        this.position = 'left';
        this.close = new core_1.EventEmitter();
    }
    AContextMenuComponent.prototype.doClose = function () {
        this.close.emit(null);
    };
    AContextMenuComponent.prototype.onClick = function (event) {
        if (event.target === this._eref.nativeElement) {
            event.stopPropagation();
            event.stopImmediatePropagation();
            this.doClose();
            return false;
        }
    };
    AContextMenuComponent.prototype.onEsc = function () {
        event.stopPropagation();
        event.stopImmediatePropagation();
        this.doClose();
        return false;
    };
    AContextMenuComponent.prototype.doCheckPosition = function () {
        var _a = this.contextElement.nativeElement.getBoundingClientRect(), bottom = _a.bottom, right = _a.right, top = _a.top, left = _a.left;
        if (top - defaultPadding < 0
            || right + defaultPadding > window.innerWidth
            || left - defaultPadding < 0
            || bottom + defaultPadding > window.innerHeight) {
            this.contextElement.nativeElement.scrollIntoView([]);
        }
    };
    AContextMenuComponent.prototype.ngAfterContentInit = function () {
        this.doCheckPosition();
    };
    return AContextMenuComponent;
}());
exports.AContextMenuComponent = AContextMenuComponent;
