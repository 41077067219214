"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../shared/40x-page/a-forbidden/a-forbidden.component.ngfactory");
var i2 = require("../../../shared/40x-page/a-forbidden/a-forbidden.component");
var i3 = require("./forbidden-page.component");
var i4 = require("@angular/router");
var styles_ForbiddenPageComponent = [];
var RenderType_ForbiddenPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForbiddenPageComponent, data: {} });
exports.RenderType_ForbiddenPageComponent = RenderType_ForbiddenPageComponent;
function View_ForbiddenPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a-forbidden", [], null, null, null, i1.View_ForbiddenComponent_0, i1.RenderType_ForbiddenComponent)), i0.ɵdid(1, 49152, null, 0, i2.ForbiddenComponent, [], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_ForbiddenPageComponent_0 = View_ForbiddenPageComponent_0;
function View_ForbiddenPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ForbiddenPageComponent_0, RenderType_ForbiddenPageComponent)), i0.ɵdid(1, 114688, null, 0, i3.ForbiddenPageComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ForbiddenPageComponent_Host_0 = View_ForbiddenPageComponent_Host_0;
var ForbiddenPageComponentNgFactory = i0.ɵccf("ng-component", i3.ForbiddenPageComponent, View_ForbiddenPageComponent_Host_0, {}, {}, []);
exports.ForbiddenPageComponentNgFactory = ForbiddenPageComponentNgFactory;
