"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var a_button_component_1 = require("./components/a-button/a-button.component");
var a_loader_component_1 = require("./components/a-loader/a-loader.component");
var a_avatar_component_1 = require("./components/a-avatar/a-avatar.component");
var a_context_menu_item_component_1 = require("./components/a-context-menu/a-context-menu-item.component");
var a_context_menu_component_1 = require("./components/a-context-menu/a-context-menu.component");
var a_popup_component_1 = require("./components/a-popup/a-popup.component");
var components = [
    a_button_component_1.AButtonComponent,
    a_loader_component_1.ALoaderComponent,
    a_avatar_component_1.AAvatarComponent,
    a_context_menu_component_1.AContextMenuComponent,
    a_context_menu_item_component_1.AContextMenuItemComponent,
    a_popup_component_1.APopupComponent
];
var AtlazWebGuiModule = /** @class */ (function () {
    function AtlazWebGuiModule() {
    }
    AtlazWebGuiModule.forRoot = function () {
        return {
            ngModule: AtlazWebGuiModule
        };
    };
    return AtlazWebGuiModule;
}());
exports.AtlazWebGuiModule = AtlazWebGuiModule;
