import { Injectable } from '@angular/core';
import { AtlazApiService } from '../api.service/api.service';
import { JsonApiSingeModelResponse } from '../api.service/http-response';
import {timer} from 'rxjs/internal/observable/timer';
import {catchError, filter, startWith, switchMap, takeWhile} from 'rxjs/operators';
import {interval} from 'rxjs/internal/observable/interval';
import {NEVER} from 'rxjs/internal/observable/never';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';

export const QUEUE_JOB = 'queue-job';
export const QUEUE_JOB_PL = 'queue-jobs';

@Injectable()
export class JobStatusService {
  constructor(private _api: AtlazApiService) {}

  public isJob = (resp: JsonApiSingeModelResponse<any>): boolean =>
    resp.data && !Array.isArray(resp.data) && resp.data.type === QUEUE_JOB;

  public trackJobStatus = (response: JsonApiSingeModelResponse<any>) => {
    const job$ = new ReplaySubject(1);
    const job = response.data;
    let done = false;
    interval(3000)
      .pipe(
      takeWhile(() => !done),
      startWith(0),
      switchMap(_ =>
        this._api
          .get([QUEUE_JOB_PL, job.id])
          .pipe(
            filter(
              (resp: JsonApiSingeModelResponse<any>) =>
                resp.data.attributes.status === 'done'
            ),
            catchError(() => NEVER)
          )
      ),
      takeWhile(() => !done)
      )
      .subscribe(resp => {
        done = true;
        job$.next(resp);
        job$.complete();
      });

    timer(60 * 1000)
      .pipe(takeWhile(() => !done))
      .subscribe(() => {
        done = true;
        job$.error({ code: 'timeout', job: job, message: 'Timeout error.' });
        job$.complete();
      });

    return job$;
  };
}
