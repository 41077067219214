import { Component } from '@angular/core';

@Component({
  selector: 'a-not-found',
  templateUrl: './a-not-found.component.html',
  styleUrls: ['../40x-page.component.scss']
})
export class NotFoundComponent {

}
