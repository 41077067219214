"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RelationType;
(function (RelationType) {
    RelationType[RelationType["OneToMany"] = 1] = "OneToMany";
    RelationType[RelationType["ManyToMany"] = 2] = "ManyToMany";
})(RelationType = exports.RelationType || (exports.RelationType = {}));
exports.getRelationType = function (relation) {
    return Array.isArray(relation.data)
        ? RelationType.ManyToMany
        : RelationType.OneToMany;
};
var checkRelation = function (relation, expectedType) {
    return exports.getRelationType(relation) === expectedType;
};
var ɵ0 = checkRelation;
exports.ɵ0 = ɵ0;
exports.isOneToMany = function (relation) {
    return checkRelation(relation, RelationType.OneToMany);
};
exports.isManyToMany = function (relation) {
    return checkRelation(relation, RelationType.ManyToMany);
};
exports.getRelatedModels = function (model, relationName, ModelClass) {
    var relations = model.data.relationships[relationName]['data'];
    var result = [];
    var includedModels = model.included || [];
    var matchRelation = function (included, relation) {
        if (included.id === relation.id && included.type === relation.type) {
            result.push(included);
            return true;
        }
    };
    if (Array.isArray(relations)) {
        includedModels.forEach(function (included) {
            return relations.some(function (rel) { return matchRelation(included, rel); });
        });
    }
    else {
        includedModels.some(function (included) { return matchRelation(included, relations); });
    }
    return result.map(function (data) { return new ModelClass({ data: data, included: model.included }); });
};
exports.getOne = function (model, relationName, ModelClass) { return exports.getRelatedModels(model, relationName, ModelClass)[0]; };
exports.getMany = function (model, relationName, ModelClass) { return exports.getRelatedModels(model, relationName, ModelClass); };
var BaseModel = /** @class */ (function () {
    function BaseModel(response) {
        this.originalResponse = response;
    }
    Object.defineProperty(BaseModel.prototype, "id", {
        get: function () {
            return this.originalResponse.data.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseModel.prototype, "type", {
        get: function () {
            return this.originalResponse.data.type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseModel.prototype, "attributes", {
        get: function () {
            return this.originalResponse.data.attributes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseModel.prototype, "relationships", {
        get: function () {
            return this.originalResponse.data.relationships;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseModel.prototype, "included", {
        get: function () {
            return this.originalResponse.included;
        },
        enumerable: true,
        configurable: true
    });
    return BaseModel;
}());
exports.BaseModel = BaseModel;
exports.getModeList = function (resp, ModelClass) {
    return resp.data.map(function (oneModelData) {
        return new ModelClass({
            data: oneModelData,
            included: resp.included
        });
    });
};
