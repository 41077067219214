import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service/auth.service';
import { ROUTER_PATH } from '../../../constants';
import { AtlazApiService } from '../api.service/api.service';
import { AUTH } from '../api.service/api-path';
import { JsonApiSingeModelResponse } from '../api.service/http-response';
import { catchError, map, mapTo, take, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

const isErrorWithToken = error =>
  ['one_off_authorization_token_invalid'].includes(error.code);

@Injectable()
export class AuthorizedUserGuard implements CanActivate, CanLoad {
  constructor(
    private _authService: AuthService,
    private _atlazApi: AtlazApiService,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const oneOffAuthorizationToken =
      route.queryParams['oneOffAuthorizationToken'];
    const changeEmailToken = route.queryParams['changeEmailToken'];

    if (oneOffAuthorizationToken && !this._authService.isLoggedIn) {
      return this.oneOffAuthorization(oneOffAuthorizationToken);
    } else if (changeEmailToken && !this._authService.isLoggedIn) {
      return this.changeEmailAuthorization(changeEmailToken);
    } else {
      return this.authHook();
    }
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.authHook();
  }

  private oneOffAuthorization(oneOffAuthorizationToken: string) {
    return this.authByToken(
      'oneOffAuthorizationToken',
      oneOffAuthorizationToken
    );
  }

  private changeEmailAuthorization(newEmailConfirmToken: string) {
    return this.authByToken('changeEmailToken', newEmailConfirmToken);
  }

  private authByToken(tokenType: string, token: string) {
    const creds = {
      [tokenType]: token,
      credentialsType: tokenType
    };
    return this._atlazApi
      .post(
        [AUTH, { expand: ['usersCompanies', 'usersCompanies.company'] }],
        creds
      )
      .pipe(
        map((resp: JsonApiSingeModelResponse<any>) => {
          this._authService.authorize(resp);
          return this._authService.isLoggedIn$;
        }),
        catchError((e: Response) => {
          try {
            console.log(JSON.stringify(e));
          } catch (e) {}
          const error = e['errors']
            ? e['errors'].find(isErrorWithToken)
            : e['error']['errors']
              ? e['error']['errors'].find(isErrorWithToken)
              : null;
          const message = error ? error.detail : 'Access Denied';
          this._router.navigate(['403'], { queryParams: { message } });
          return of(false);
        }),
        mapTo(true),
        take(1)
      );
  }

  private authHook() {
    return this._authService.isLoggedIn$.pipe(
      take(1),
      tap(authorized => {
        if (!authorized) {
          const returnPath = window.location.pathname;
          this._router.navigate(['/', ROUTER_PATH.LOGIN], {
            queryParams: { return_path: returnPath }
          });
        }
      })
    );
  }
}
