"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var url_1 = require("../../util/url");
var router_1 = require("@angular/router");
var ChooseCompanyComponent = /** @class */ (function () {
    function ChooseCompanyComponent(_route) {
        this._route = _route;
        this.companyUrls = {};
    }
    ChooseCompanyComponent.prototype.ngOnInit = function () {
        this._companies = this._route.snapshot.data['companies'];
        this.companies = Array.isArray(this._companies)
            ? this._companies.filter(function (item) { return !item.attributes.deleted; })
            : [];
        this.user = this._route.snapshot.data['user'];
        var pathInWebApp = this._route.snapshot.queryParams['furtherPath']
            ? '/' + this._route.snapshot.queryParams['furtherPath']
            : '';
        if (pathInWebApp) {
            var notDemoCompanies = this.companies.filter(function (company) { return !company.attributes.isDemo; });
            if (notDemoCompanies.length === 1) {
                window.location.href =
                    url_1.companyPortalUrl(notDemoCompanies[0]) + pathInWebApp;
            }
        }
        this.companyUrls = this.companies.reduce(function (acc, company) {
            acc[company.id] = url_1.companyPortalUrl(company) + pathInWebApp;
            return acc;
        }, {});
    };
    return ChooseCompanyComponent;
}());
exports.ChooseCompanyComponent = ChooseCompanyComponent;
