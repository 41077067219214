"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Rollbar = require("rollbar");
var core_1 = require("@angular/core");
exports.ROLLBAR_TOKEN = new core_1.InjectionToken('AtlazRollbar');
var logger = function (level, history) { return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    switch (level) {
        case 'warn':
        case 'warning':
            args.forEach(function (v) { return console.warn(v); });
            break;
        case 'critical':
            console.warn('critical');
            args.forEach(function (v) { return console.error(v); });
            break;
        case 'error':
            args.forEach(function (v) { return console.error(v); });
            break;
        default:
            args.forEach(function (v) { return console.log(v); });
    }
    var logResult = {
        level: level,
        uuid: 'u',
        args: args
    };
    history.push(logResult);
    return logResult;
}; };
var ɵ0 = logger;
exports.ɵ0 = ɵ0;
var RollbarDymmy = /** @class */ (function () {
    function RollbarDymmy(options) {
        this.history = [];
        this.log = logger('log', this.history);
        this.debug = logger('debug', this.history);
        this.info = logger('info', this.history);
        this.warn = logger('warn', this.history);
        this.warning = logger('warning', this.history);
        this.error = logger('error', this.history);
        this.critical = logger('critical', this.history);
    }
    RollbarDymmy.init = function (options) {
        return new RollbarDymmy(options);
    };
    RollbarDymmy.prototype.global = function (options) {
        return this;
    };
    RollbarDymmy.prototype.configure = function (options) {
        return this;
    };
    RollbarDymmy.prototype.lastError = function () {
        if (this.history.length) {
            return this.history[this.history.length - 1].args[0];
        }
        return null;
    };
    RollbarDymmy.prototype.captureEvent = function (metadata, level) {
        return {
            level: level,
            type: 'dymmy',
            timestamp_ms: 0,
            body: {},
            source: ''
        };
    };
    RollbarDymmy.prototype.lambdaHandler = function (handler) {
        return function (event, context, callback) { return undefined; };
    };
    return RollbarDymmy;
}());
exports.RollbarDymmy = RollbarDymmy;
