import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PhoneCode } from './phone-codes';
import { sortBy } from './util/function';

import * as rawCodes from './../../assets/phone-codes.json';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';
const codes: PhoneCode[] = <any>rawCodes;

@Injectable()
export class PhoneCodesService {
  public getPhoneCodes(): Observable<PhoneCode[]> {
    return of(codes).pipe(
      map(sortBy((item: PhoneCode) => item.name.toLowerCase()))
    );
  }
}
