"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var EmailFieldComponent = /** @class */ (function () {
    function EmailFieldComponent() {
        this.placeholder = 'name@domain.com';
        this.label = 'Email';
        this.inputId = 'mail';
        this.showDone = false;
    }
    EmailFieldComponent.prototype.ngOnChanges = function () {
        if (this.disabled) {
            this.field.disable();
        }
    };
    return EmailFieldComponent;
}());
exports.EmailFieldComponent = EmailFieldComponent;
