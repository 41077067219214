import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'left-container',
  template: '<ng-content></ng-content>',
  styleUrls: ['./left-container.component.scss']
})
export class LeftContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
