<label class="form_page_wrap_label" for="{{inputId}}">{{label}}</label>
<div class="form_page_input_wrap" [class.error]="field.invalid && field.touched && field.dirty">
  <input [formControl]="field" [focus]="focus" type="email" id="{{inputId}}" class="form_page_input" [placeholder]="placeholder"
         (input)="showDone = false"
         (blur)="showDone=true"
         checkTouch
  >
  <div class="ico_input_done"
       *ngIf="showDone && field.valid && field.touched && field.dirty"></div>
  <div class="error_text">Please enter a valid email address.</div>
</div>
