import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AppHeaderComponent } from './app-header/app-header.component';
import { AtlazWebGuiModule } from '../atlaz-gui/atlaz-gui.module';

@NgModule({
  imports: [
    CommonModule,
    AtlazWebGuiModule,
    RouterModule
  ],
  declarations: [
    AppHeaderComponent
  ],
  exports: [
    AppHeaderComponent
  ]
})
export class LayoutModule { }
