"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../environments/environment");
exports.companyPortalUrl = function (company) {
    return 'https://' + company.attributes.domain + '.' + environment_1.environment.domain;
};
exports.preparePath = function (path) {
    return Array.isArray(path) ? path.join('/') : '/' + path;
};
exports.goToAtlazWeb = function (company, path) {
    if (path === void 0) { path = ''; }
    window.location.href = exports.companyPortalUrl(company) + exports.preparePath(path);
};
exports.getUtmFromCurrentUri = function () {
    return window.location.search
        .substr(1)
        .split('&')
        .reduce(function (acc, param) {
        var _a = param.split('='), key = _a[0], value = _a[1];
        if (key && value && key.indexOf('utm') > -1) {
            acc[key] = decodeURIComponent(value);
        }
        return acc;
    }, {});
};
