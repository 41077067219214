"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_service_1 = require("../../../core/services/auth.service/auth.service");
var constants_1 = require("../../../constants");
var operators_1 = require("rxjs/operators");
var RootPathGuard = /** @class */ (function () {
    function RootPathGuard(_authService, _router) {
        this._authService = _authService;
        this._router = _router;
    }
    RootPathGuard.prototype.authHook = function (initialQuery) {
        var _this = this;
        this._authService.isLoggedIn$
            .pipe(operators_1.take(1))
            .subscribe(function (authorized) {
            return _this._router.navigate([authorized ? constants_1.ROUTER_PATH.CHOOSE_COMPANY : constants_1.ROUTER_PATH.LOGIN], { queryParams: initialQuery });
        });
    };
    RootPathGuard.prototype.canActivate = function (route, state) {
        this.authHook(route.queryParams);
        return false;
    };
    return RootPathGuard;
}());
exports.RootPathGuard = RootPathGuard;
