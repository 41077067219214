import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeListComponent } from './code-list/code-list.component';
import { PhoneCodesService } from './phone-codes.service';
import { PhoneCodesComponent } from './phone-codes.component';
import { ElementFocusDirective } from './element.focus.directive';
import { ScrollToCodeDirective } from './code-list/scroll-to-code.directive';
import { ScrollToTopModule } from '../shared/scroll-to-top.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [CommonModule, HttpClientModule, ScrollToTopModule],
  declarations: [
    CodeListComponent,
    PhoneCodesComponent,
    ElementFocusDirective,
    ScrollToCodeDirective
  ],
  providers: [PhoneCodesService],
  exports: [PhoneCodesComponent]
})
export class PhoneCodesModule {}
