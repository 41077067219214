<div class="form_page_wrap">
  <div class="form_page" [ngClass]="{wide: wideForm}">
    <div class="ico_atlaz"></div>
    <h3 class="form_page_wrap_h3 center">{{title}}</h3>
    <general-error-message *ngIf="isError">
      <span *ngFor="let message of errors">{{message}}<br/></span>
    </general-error-message>
    <ng-content></ng-content>
  </div>
</div>
