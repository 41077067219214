"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var getEventPath = function (event) {
    if (event['path']) {
        return event['path'];
    }
    if (event['composedPath']) {
        return event['composedPath']();
    }
    var path = [];
    var node = event.target;
    do {
        path.push(node);
    } while ((node = node.parentElement));
    return path;
};
var ɵ0 = getEventPath;
exports.ɵ0 = ɵ0;
var clickOutStack = [];
var ClickOutDirective = /** @class */ (function () {
    function ClickOutDirective(_element) {
        this._element = _element;
        this.clickOutEvent = new core_1.EventEmitter();
        this.tabindex = -1;
    }
    ClickOutDirective.prototype.onMouseDown = function (event) {
        if (this.clickOut &&
            !getEventPath(event).includes(this._element.nativeElement)) {
            this.clickOutEvent.emit(event);
        }
    };
    ClickOutDirective.prototype.onEsc = function (event) {
        if (!this.clickOut) {
            return false;
        }
        if (this._element.nativeElement.contains(event.target) &&
            clickOutStack[clickOutStack.length - 1] === this) {
            this.clickOutEvent.emit(event);
        }
        else {
            this.setFocus();
        }
    };
    ClickOutDirective.prototype.ngAfterViewInit = function () {
        clickOutStack.push(this);
        this.setFocus();
    };
    ClickOutDirective.prototype.ngOnDestroy = function () {
        var _this = this;
        var i = clickOutStack.findIndex(function (item) { return item === _this; });
        clickOutStack.splice(i, 1);
    };
    ClickOutDirective.prototype.setFocus = function () {
        this._element.nativeElement.focus();
    };
    return ClickOutDirective;
}());
exports.ClickOutDirective = ClickOutDirective;
