import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AtlazApiService } from '../api.service/api.service';
import { HttpClient } from '@angular/common/http';
import { JsonApiSingeModelResponse } from '../api.service/http-response';
import { Company } from '../../models/company';
import { goToAtlazWeb } from '../../../util/url';
import { UsersCompany } from '../../models/usersCompany';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NEVER } from 'rxjs/internal/observable/never';
import { of } from 'rxjs/internal/observable/of';
import { JobStatusService } from '../job-status.service/job-status.service';

const props = (...props) => obj =>
  props.reduce((obj, prop) => (obj !== undefined ? obj[prop] : undefined), obj);

@Injectable()
export class InviteUserResolver
  implements Resolve<{ token: string; company: Company }> {
  public token: string;
  public company: Company;

  constructor(
    private _atlazApi: AtlazApiService,
    private _http: HttpClient,
    private _jobs: JobStatusService,
    private _router: Router
  ) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<{ company: Company; token: string }> {
    const inviteToken = route.queryParams['token'];

    return this.getInvite(inviteToken).pipe(
      catchError(e => {
        const error = e;
        if (
          error.errors &&
          error.errors.find(e => e.code === 'invitation_token_invalid')
        ) {
          this._router.navigate(['/403'], {
            queryParams: {
              message: error.errors.find(
                e => e.code === 'invitation_token_invalid'
              )['detail']
            }
          });
        } else if (e.status === 402) {
          this._router.navigate(['402'], {
            queryParams: {
              message: `The company you're trying to join to has reached free users limit. Please contact the one who's invited you.`
            }
          });
        } else {
          this._router.navigate(['404'], {
            queryParams: { message: 'Invalid Link' }
          });
        }
        return observableThrowError(e);
      }),
      switchMap((response: JsonApiSingeModelResponse<any>) => {
        const user = response.included.find(rel => rel.type === 'user');
        const company = new UsersCompany(response).getCompany();

        if (props('attributes', 'isPasswordEmpty')(user) === 0) {
          console.log('User is already exists, redirect to company url');
          this.gotToAtlazWeb(inviteToken, company);
          return NEVER;
        } else {
          console.log('Normal flow, token is valid');
          return of({
            token: inviteToken,
            company
          });
        }
      }),
      catchError(e => {
        console.log('InviteUserResolver', e);
        return of({ company: undefined, token: undefined });
      }),
      map(({ company, token }) => ({
        company: company,
        token: token
      }))
    );
  }

  private getInvite(token: string): Observable<JsonApiSingeModelResponse<any>> {
    const params = ['invitations', token];

    return <Observable<JsonApiSingeModelResponse<any>>>this._atlazApi.get(
      params,
      { expand: ['company', 'user'] }
    );
  }

  private gotToAtlazWeb(token: string, company: Company) {
    const params = ['invitations'];

    this._atlazApi
      .post(params, { token })
      .pipe(switchMap(this._jobs.trackJobStatus))
      .subscribe(
        (res: JsonApiSingeModelResponse<any>) => {
          if (res.data.attributes.success) {
            goToAtlazWeb(company);
          } else {
            this._router.navigate(['403'], {
              queryParams: {
                message: `The payment for your account has failed. Please, contact Company Admin or Company Owner.`
              }
            });
          }
        },
        e => {
          if (e.status === 402) {
            this._router.navigate(['402'], {
              queryParams: {
                message: `The company you're trying to join to has reached free users limit.
                 Please, contact Company Admin or Company Owner.`
              }
            });
          } else {
            this._router.navigate(['400'], {
              queryParams: {
                message:
                  'Some error occurred during confirmation. Please refresh the page. If problem persists, please contact support.'
              }
            });
          }
        }
      );
  }

  private getInviteMock() {
    return this._http.get('assets/invite-to-company.json');
  }
}
