"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_1 = require("../../../core/models/user");
var AAvatarComponent = /** @class */ (function () {
    function AAvatarComponent() {
    }
    Object.defineProperty(AAvatarComponent.prototype, "photoUrl", {
        get: function () {
            var userAv = this.user &&
                this.user.attributes.photo &&
                this.user.attributes.photo.previews &&
                this.user.attributes.photo.previews.w100
                ? this.user.attributes.photo.previews.w100.path
                : '';
            return this.customPath || userAv;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AAvatarComponent.prototype, "initials", {
        get: function () {
            if (this.user) {
                return ((this.user.attributes.firstname
                    ? this.user.attributes.firstname[0]
                    : '') +
                    (this.user.attributes.lastname ? this.user.attributes.lastname[0] : ''));
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    return AAvatarComponent;
}());
exports.AAvatarComponent = AAvatarComponent;
