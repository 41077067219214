import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AtlazApiService } from '../../services/api.service/api.service';
import { JsonApiSingeModelResponse } from '../../services/api.service/http-response';
import { Company } from '../../models/company';
import { goToAtlazWeb } from '../../../util/url';
import { ServerError } from '../../interfaces/http';
import { defaultIfEmpty, pluck, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'create-demo',
  template: `<a-loader *ngIf="error === undefined; else errorMessage"></a-loader>
    <ng-template #errorMessage>
      <a-bad-request [message]="error.message"></a-bad-request>
    </ng-template>
  `
})
export class CreateDemoComponent implements OnInit {
  public error: ServerError;

  constructor(
    private _route: ActivatedRoute,
    private _atlazApi: AtlazApiService
  ) {}

  ngOnInit() {
    this._route.queryParams
      .pipe(
        pluck('token'),
        take(1),
        defaultIfEmpty({ token: 'blablabla' }),
        switchMap(
          token => this._atlazApi.post('demo', { token })
          // use for test redirect
          // token => this._atlazApi.getMock('company-jsonapi.json')
        )
      )
      .subscribe(
        (resp: JsonApiSingeModelResponse<Company>) =>
          goToAtlazWeb(new Company(resp)),
        error => {
          const serverError = error;
          console.log(serverError, 'serverError');
          this.error = serverError;
        }
      );
  }
}
