import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

const isPresent = a => !!a;

@Directive({
  selector: '[checkTouch]'
})
export class CheckTouchDirective {
  private sub: Subscription;

  constructor(public element: ElementRef, private control: NgControl) {}

  @HostListener('focus')
  onFocus(): void {
    if (this.control.valid) {
      this.control.control.markAsUntouched();
    } else {
      this.sub = this.control.control.valueChanges
        .pipe(map(() => this.control.valid), filter(isPresent), take(1))
        .subscribe(() => {
          this.control.control.markAsUntouched();
          this.sub.unsubscribe();
        });
    }
  }

  @HostListener('blur')
  onBlur() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
