import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'a-loader',
  templateUrl: './a-loader.component.html',
  styleUrls: [ './a-loader.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ALoaderComponent {
}
