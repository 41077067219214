import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ROUTER_PATH } from '../../../constants';

@Component({
  selector: 'password-field',
  templateUrl: './password-field.component.html',
  styles: [
    `
    input.forgot {
      padding-right: 62px;
    }
  `
  ]
})
export class PasswordFieldComponent {
  @Input() field: FormControl;
  @Input() hintType: 'forgot' | 'visibility' = 'forgot';
  @Input() label = 'Password';
  @Input() placeholder = 'Your Password';
  @Input() focus: boolean;
  @Input() inputId = 'pass';
  @Input() disabled: boolean;

  public passwordType: 'password' | 'text' = 'password';
  public toResetPass = ['/', ROUTER_PATH.FORGOT, ROUTER_PATH.SEND_EMAIL];

  get isHiddenPassword() {
    return this.passwordType === 'password';
  }

  onTogglePassword() {
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
  }
}
