import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-payment-required',
  templateUrl: './a-payment-required.component.html',
  styleUrls: ['../40x-page.component.scss'],
})
export class PaymentRequiredComponent {
  @Input() message: string;
}
