"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".initials[_ngcontent-%COMP%]{display:block;line-height:40px;width:40px;font-size:14px;text-align:center;color:#000;background:#eceff1;font-family:Roboto;font-weight:400;text-transform:uppercase;overflow:hidden;border-radius:50%;position:absolute;top:0;z-index:1}[_nghost-%COMP%]{display:inline-block;width:40px;height:40px;max-width:40px;max-height:40px}.big[_nghost-%COMP%]{width:80px;height:80px;max-width:80px;max-height:80px}.big[_nghost-%COMP%]   .initials[_ngcontent-%COMP%]{line-height:80px;width:80px;font-size:20px}img[_ngcontent-%COMP%]{max-width:100%;border-radius:50%;position:relative;z-index:2}"];
exports.styles = styles;
