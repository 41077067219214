"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var router_1 = require("@angular/router");
var auth_service_1 = require("../auth.service/auth.service");
var invite_link_service_1 = require("../invite-link/invite-link.service");
var url_1 = require("../../../util/url");
var api_service_1 = require("../api.service/api.service");
var of_1 = require("rxjs/internal/observable/of");
var operators_1 = require("rxjs/operators");
var combineLatest_1 = require("rxjs/internal/observable/combineLatest");
var job_status_service_1 = require("../job-status.service/job-status.service");
var InviteLinkGuard = /** @class */ (function () {
    function InviteLinkGuard(_authService, _atlazApi, _jobs, _inviteLinkService, _router) {
        this._authService = _authService;
        this._atlazApi = _atlazApi;
        this._jobs = _jobs;
        this._inviteLinkService = _inviteLinkService;
        this._router = _router;
    }
    InviteLinkGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return combineLatest_1.combineLatest(this._inviteLinkService.validateInviteLink(route.params['token']).pipe(operators_1.catchError(function (e) {
            if (e.status === 402) {
                _this._router.navigate(['402'], {
                    queryParams: {
                        message: "The company you're trying to join to has reached free users limit. Please contact one who's invited you."
                    }
                });
            }
            else {
                _this._router.navigate(['404'], {
                    queryParams: { message: 'Invalid Link' }
                });
            }
            return rxjs_1.throwError(e);
        })), this._authService.isLoggedIn$).pipe(operators_1.switchMap(function (_a) {
            var company = _a[0], isLogged = _a[1];
            if (isLogged) {
                return _this._atlazApi
                    .post('invitations-link', { token: route.params['token'] })
                    .pipe(operators_1.switchMap(_this._jobs.trackJobStatus), operators_1.switchMap(function (res) {
                    if (res.data.attributes.success) {
                        url_1.goToAtlazWeb(company);
                    }
                    else {
                        _this._router.navigate(['403'], {
                            queryParams: {
                                message: "The payment for your account has failed. Please, contact Company Admin or Company Owner."
                            }
                        });
                    }
                    return of_1.of(false);
                }), operators_1.catchError(function () {
                    _this._router.navigate(['400'], {
                        queryParams: {
                            message: 'Some error occurred during confirmation. Please refresh the page. If problem persists, please contact support.'
                        }
                    });
                    return of_1.of(false);
                }), operators_1.switchMap(function () { return of_1.of(false).pipe(operators_1.debounceTime(10000)); }));
            }
            return of_1.of(true);
        }), operators_1.catchError(function (e) {
            if (e.status === 402) {
                _this._router.navigate(['402'], {
                    queryParams: {
                        message: "The company you're trying to join to has reached free users limit. Please contact one who's invited you."
                    }
                });
            }
            else {
                _this._router.navigate(['404'], {
                    queryParams: { message: 'Invalid Link' }
                });
            }
            return of_1.of(false);
        }), operators_1.take(1));
    };
    return InviteLinkGuard;
}());
exports.InviteLinkGuard = InviteLinkGuard;
