import { timer as observableTimer, Observable, Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';

import { CheckEmailStatus, CheckEmailTitles } from './check-email';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'check-email',
  templateUrl: './check-email.component.html',
  styleUrls: ['./check-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckEmailComponent implements OnChanges, OnDestroy {
  @Input() email: string;
  @Input() titles: CheckEmailTitles;
  @Input() status: CheckEmailStatus = 'sent';
  @Input() showFooter = false;

  public sendIntervalInMinutes = 2;
  public title: string;
  public countdown$: Observable<string>;
  public sub: Subscription;

  constructor(protected _cd: ChangeDetectorRef) {}

  ngOnChanges(change: SimpleChanges): void {
    if (
      change.hasOwnProperty('status') &&
      change.status.currentValue !== 'error'
    ) {
      this.title = this.getTitle();
    }
    if (change.status.currentValue === 'error') {
      this.countdown$ = observableTimer(0, 1000).pipe(
        map(x => 120 - x),
        takeWhile(x => x > -1),
        map(
          x =>
            Math.floor(x / 60) +
            ':' +
            (x % 60 > 9 ? x % 60 : x % 60 !== 0 ? '0' + x % 60 : '00')
        )
      );
      this.sub = this.countdown$.subscribe({
        complete: () => {
          this.status = 'resent';
          this._cd.detectChanges();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  get isSendError() {
    return this.status === 'error';
  }

  private getTitle() {
    return !!this.titles && !!this.status ? this.titles[this.status] : '';
  }
}
