"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var router_1 = require("@angular/router");
var api_service_1 = require("../api.service/api.service");
var http_1 = require("@angular/common/http");
var url_1 = require("../../../util/url");
var usersCompany_1 = require("../../models/usersCompany");
var operators_1 = require("rxjs/operators");
var never_1 = require("rxjs/internal/observable/never");
var of_1 = require("rxjs/internal/observable/of");
var job_status_service_1 = require("../job-status.service/job-status.service");
var props = function () {
    var props = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        props[_i] = arguments[_i];
    }
    return function (obj) {
        return props.reduce(function (obj, prop) { return (obj !== undefined ? obj[prop] : undefined); }, obj);
    };
};
var ɵ0 = props;
exports.ɵ0 = ɵ0;
var InviteUserResolver = /** @class */ (function () {
    function InviteUserResolver(_atlazApi, _http, _jobs, _router) {
        this._atlazApi = _atlazApi;
        this._http = _http;
        this._jobs = _jobs;
        this._router = _router;
    }
    InviteUserResolver.prototype.resolve = function (route, state) {
        var _this = this;
        var inviteToken = route.queryParams['token'];
        return this.getInvite(inviteToken).pipe(operators_1.catchError(function (e) {
            var error = e;
            if (error.errors &&
                error.errors.find(function (e) { return e.code === 'invitation_token_invalid'; })) {
                _this._router.navigate(['/403'], {
                    queryParams: {
                        message: error.errors.find(function (e) { return e.code === 'invitation_token_invalid'; })['detail']
                    }
                });
            }
            else if (e.status === 402) {
                _this._router.navigate(['402'], {
                    queryParams: {
                        message: "The company you're trying to join to has reached free users limit. Please contact the one who's invited you."
                    }
                });
            }
            else {
                _this._router.navigate(['404'], {
                    queryParams: { message: 'Invalid Link' }
                });
            }
            return rxjs_1.throwError(e);
        }), operators_1.switchMap(function (response) {
            var user = response.included.find(function (rel) { return rel.type === 'user'; });
            var company = new usersCompany_1.UsersCompany(response).getCompany();
            if (props('attributes', 'isPasswordEmpty')(user) === 0) {
                console.log('User is already exists, redirect to company url');
                _this.gotToAtlazWeb(inviteToken, company);
                return never_1.NEVER;
            }
            else {
                console.log('Normal flow, token is valid');
                return of_1.of({
                    token: inviteToken,
                    company: company
                });
            }
        }), operators_1.catchError(function (e) {
            console.log('InviteUserResolver', e);
            return of_1.of({ company: undefined, token: undefined });
        }), operators_1.map(function (_a) {
            var company = _a.company, token = _a.token;
            return ({
                company: company,
                token: token
            });
        }));
    };
    InviteUserResolver.prototype.getInvite = function (token) {
        var params = ['invitations', token];
        return this._atlazApi.get(params, { expand: ['company', 'user'] });
    };
    InviteUserResolver.prototype.gotToAtlazWeb = function (token, company) {
        var _this = this;
        var params = ['invitations'];
        this._atlazApi
            .post(params, { token: token })
            .pipe(operators_1.switchMap(this._jobs.trackJobStatus))
            .subscribe(function (res) {
            if (res.data.attributes.success) {
                url_1.goToAtlazWeb(company);
            }
            else {
                _this._router.navigate(['403'], {
                    queryParams: {
                        message: "The payment for your account has failed. Please, contact Company Admin or Company Owner."
                    }
                });
            }
        }, function (e) {
            if (e.status === 402) {
                _this._router.navigate(['402'], {
                    queryParams: {
                        message: "The company you're trying to join to has reached free users limit.\n                 Please, contact Company Admin or Company Owner."
                    }
                });
            }
            else {
                _this._router.navigate(['400'], {
                    queryParams: {
                        message: 'Some error occurred during confirmation. Please refresh the page. If problem persists, please contact support.'
                    }
                });
            }
        });
    };
    InviteUserResolver.prototype.getInviteMock = function () {
        return this._http.get('assets/invite-to-company.json');
    };
    return InviteUserResolver;
}());
exports.InviteUserResolver = InviteUserResolver;
