import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { ROUTER_PATH } from '../../../constants';
import { AtlazApiService } from '../../../core/services/api.service/api.service';

import { AuthService } from '../../../core/services/auth.service/auth.service';
import { AUTH } from '../../../core/services/api.service/api-path';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(
    private _atlazApi: AtlazApiService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._atlazApi.deleteRequest(AUTH).pipe(
      catchError(e => {
        return of(false);
      }),
      map(_ => {
        this._authService.activeUser = null;
        this._authService.isLoggedIn$.next(false);
      }),
      tap(_ => {
        this._router.navigate(['/', ROUTER_PATH.LOGIN]);
      }),
      mapTo(false)
    );
  }
}
