import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'lastname-field',
  templateUrl: './lastname-field.component.html'
})
export class LastNameFieldComponent {

  @Input() field: FormControl;
  @Input() focus: boolean;
  @Input() placeholder = 'Johnson';

}
