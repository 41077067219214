import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToTopDirective } from './scroll-to-top.directive';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ScrollToTopDirective
  ],
  exports: [
    ScrollToTopDirective
  ]
})
export class ScrollToTopModule {
}
