import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

const KEY_ESCAPE = 27;

@Component({
  selector: '[a-popup]',
  templateUrl: './a-popup.component.html',
  styleUrls: [ './a-popup.component.scss' ]
})
export class APopupComponent {
  @Input() title: string;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private _eref: ElementRef) {
  }

  onCancelContextPopup() {
    this.close.emit(null);
  }

  @HostListener('click', [ '$event' ])
  onClick(event) {
    if (event.target === this._eref.nativeElement) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.onCancelContextPopup();
      return false;
    }
  }

  @HostListener('keyup', [ '$event' ])
  onKeyUP(event: KeyboardEvent) {
    return this.onKeyHandler(event);
  }

  onKeyHandler(event: KeyboardEvent) {
    if (event.keyCode === KEY_ESCAPE) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.onCancelContextPopup();
      return false;
    }
  }

}
