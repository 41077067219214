import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgZone
} from '@angular/core';
import { GoogleAuthService } from 'ng-gapi';
import { AuthService } from '../../core/services/auth.service/auth.service';
import { switchMap, take, map, filter } from 'rxjs/operators';
import { User } from '../../core/models/user';
import { Observable, of as observableOf } from 'rxjs';
import { AtlazApiService } from '../../core/services/api.service/api.service';
import { JsonApiSingeModelResponse } from '../../core/services/api.service/http-response';
import { Company } from '../../core/models/company';
import { goToAtlazWeb } from '../../util/url';
import { ActivatedRoute } from '@angular/router';
import { SegmentService } from '../../core/services/intergations/segment/segment.service';
import GoogleUser = gapi.auth2.GoogleUser;

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleSsoComponent implements OnInit {
  public activeUser$: Observable<User> = this._authService.activeUser$;
  private companyId;

  constructor(
    private _ngZone: NgZone,
    private _googleAuthService: GoogleAuthService,
    private _authService: AuthService,
    private _apiService: AtlazApiService,
    private _route: ActivatedRoute,
    private _segment: SegmentService
  ) {}

  ngOnInit() {
    this.companyId = Number(this._route.snapshot.queryParams['companyId']);

    this._googleAuthService.getAuth().subscribe(auth => {
      auth.signIn().then(
        (googleUser: GoogleUser) => {
          this._ngZone.run(() => {
            this.updateAuthenticationMode({
              company: this.companyId,
              authenticationMode: 1,
              googleToken: googleUser.getAuthResponse().id_token
            });
          });
        },
        error => {
          this._ngZone.run(() => {
            console.warn('GoogleSsoComponent: Google authentication error');
            console.error(error);
          });
        }
      );
    });
  }

  private updateAuthenticationMode(payload) {
    this.activeUser$
      .pipe(
        map((user: User) => user.attributes.companyDomainId),
        switchMap(id => observableOf(id)),
        take(1),
        switchMap(id =>
          this._apiService.patch(['company-domains', id], payload)
        )
      )
      .subscribe(
        (resp: JsonApiSingeModelResponse<any>) => {
          this._segment.track('AuthenticationMode', { source: 'google' });
          this.redirect();
        },
        error => {
          console.warn('CHANGE AUTHENTICATION MODE', payload);
          console.log('PAYLOAD: ', payload);
          console.error(error);
          this.redirect();
        }
      );
  }

  private redirect() {
    this.activeUser$
      .pipe(
        switchMap((user: User) => user.getCompanies()),
        filter((company: Company) => company.id === this.companyId),
        take(1)
      )
      .subscribe((company: Company) => {
        goToAtlazWeb(company, '/company-settings/general');
      });
  }
}
