import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { AccountModule } from './account/account.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AtlazApiService,
  bodyParser,
  confirmNewEmail
} from './core/services/api.service/api.service';
import { AuthService } from './core/services/auth.service/auth.service';
import { User } from './core/models/user';
import { isPresent } from './util/object';
import { ROUTER_PATH } from './constants';
import { filter, pluck, switchMap, take, tap } from 'rxjs/operators';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { GoogleApiModule, NG_GAPI_CONFIG } from 'ng-gapi';

export function redirectToSignUp(router: Router) {
  return (route: ActivatedRouteSnapshot) => {
    router.navigate(['sign-up', 'invite'], { ...route.queryParams });
    return true;
  };
}

export function redirectToProfileEmail(
  router: Router,
  atlazApi: AtlazApiService,
  authService: AuthService
) {
  return (route: ActivatedRouteSnapshot) => {
    const token = route.queryParams['changeEmailToken'];
    return authService.activeUser$.pipe(
      filter(isPresent),
      pluck('id'),
      take(1),
      switchMap(userId =>
        atlazApi.patch(
          [
            'users',
            userId,
            { expand: ['usersCompanies', 'usersCompanies.company'] }
          ],
          token,
          bodyParser,
          confirmNewEmail
        )
      ),
      tap(res => {
        authService.activeUser$.next(new User(res));
        router.navigate([ROUTER_PATH.PROFILE, 'email'], {
          fragment: 'changeSuccess'
        });
      })
    );
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AccountModule.forRoot(),
    AppRoutingModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: {
        client_id: environment.googleClientId,
        discoveryDocs: [
          'https://analyticsreporting.googleapis.com/$discovery/rest?version=v4'
        ],
        ux_mode: 'popup',
        scope: ['https://www.googleapis.com/auth/userinfo.profile'].join(' ')
      }
    })
  ],
  providers: [
    {
      provide: 'redirectToInvite',
      useFactory: redirectToSignUp,
      deps: [Router]
    },
    {
      provide: 'redirectToProfileEmail',
      useFactory: redirectToProfileEmail,
      deps: [Router, AtlazApiService, AuthService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
