"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AContextMenuItemComponent = /** @class */ (function () {
    function AContextMenuItemComponent() {
        this.divider = false;
    }
    Object.defineProperty(AContextMenuItemComponent.prototype, "getDivider", {
        get: function () {
            return this.divider;
        },
        enumerable: true,
        configurable: true
    });
    AContextMenuItemComponent.prototype.onClick = function (event) {
        if (this.divider) {
            event.stopPropagation();
            event.stopImmediatePropagation();
            return false;
        }
    };
    return AContextMenuItemComponent;
}());
exports.AContextMenuItemComponent = AContextMenuItemComponent;
