"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("../api.service/api.service");
var timer_1 = require("rxjs/internal/observable/timer");
var operators_1 = require("rxjs/operators");
var interval_1 = require("rxjs/internal/observable/interval");
var never_1 = require("rxjs/internal/observable/never");
var ReplaySubject_1 = require("rxjs/internal/ReplaySubject");
exports.QUEUE_JOB = 'queue-job';
exports.QUEUE_JOB_PL = 'queue-jobs';
var JobStatusService = /** @class */ (function () {
    function JobStatusService(_api) {
        var _this = this;
        this._api = _api;
        this.isJob = function (resp) {
            return resp.data && !Array.isArray(resp.data) && resp.data.type === exports.QUEUE_JOB;
        };
        this.trackJobStatus = function (response) {
            var job$ = new ReplaySubject_1.ReplaySubject(1);
            var job = response.data;
            var done = false;
            interval_1.interval(3000)
                .pipe(operators_1.takeWhile(function () { return !done; }), operators_1.startWith(0), operators_1.switchMap(function (_) {
                return _this._api
                    .get([exports.QUEUE_JOB_PL, job.id])
                    .pipe(operators_1.filter(function (resp) {
                    return resp.data.attributes.status === 'done';
                }), operators_1.catchError(function () { return never_1.NEVER; }));
            }), operators_1.takeWhile(function () { return !done; }))
                .subscribe(function (resp) {
                done = true;
                job$.next(resp);
                job$.complete();
            });
            timer_1.timer(60 * 1000)
                .pipe(operators_1.takeWhile(function () { return !done; }))
                .subscribe(function () {
                done = true;
                job$.error({ code: 'timeout', job: job, message: 'Timeout error.' });
                job$.complete();
            });
            return job$;
        };
    }
    return JobStatusService;
}());
exports.JobStatusService = JobStatusService;
