import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { isObject, objectToArray } from '../../../util/object';
import { environment } from '../../../../environments/environment';
import { JsonApiResponse } from './http-response';
import { catchError, map } from 'rxjs/operators';

export const CHECK_EMAIL = 'check-email';
const getExperimentalApiUrl = () => environment.apiUrl;

export const patchPath = (path, tail?): any[] => {
  path = Array.isArray(path) ? [...path] : [path];
  if (tail) {
    path.push(tail);
  }
  return path;
};

const pathFromArray = (slugs: any[]) => {
  const clearSlugs = [];
  const params = {};
  slugs.forEach(slug => {
    isObject(slug) ? Object.assign(params, slug) : clearSlugs.push(slug);
  });
  const strParams =
    '?' +
    Object.keys(params)
      .map(
        key =>
          Array.isArray(params[key])
            ? key === 'expand'
              ? key + '=' + encodeURIComponent(params[key].join(','))
              : params[key]
                  .map(value => key + '[]=' + encodeURIComponent(value))
                  .join('&')
            : key + '=' + encodeURIComponent(params[key])
      )
      .join('&');
  return clearSlugs.join('/') + (strParams.length > 1 ? strParams : '');
};

export type ResponseParser = <T>(res: HttpResponse<any>) => T;

export const bodyParser: ResponseParser = (
  res: HttpResponse<JsonApiResponse<any>>
): any => {
  if (res && res['included'] && !Array.isArray(res['included'])) {
    return { ...res, ...{ included: objectToArray(res['included']) } };
  }
  return res;
};

export const noParse = res => res;

export const commonPatchParser = payload =>
  Object.keys(payload).reduce((acc, prop) => {
    const value = payload[prop];
    let replaceValue = true;
    if (isObject(value)) {
      if (Array.isArray(value['add'])) {
        replaceValue = false;
        value['add'].forEach(v =>
          acc.push({ op: 'add', path: '/' + prop + '/-', value: v })
        );
      }
      if (Array.isArray(value['remove'])) {
        replaceValue = false;
        value['remove'].forEach(v =>
          acc.push({ op: 'remove', path: '/' + prop + '/' + v })
        );
      }
    }
    if (replaceValue) {
      acc.push({ op: 'replace', path: '/' + prop, value: value });
    }

    return acc;
  }, []);

export const removeFieldParser = fieldName => [
  {
    op: 'remove',
    path: '/' + fieldName
  }
];

export const replaceWithTest = (
  testFields: string | string[],
  replaceFields
) => (payload: { test: string; replace: string }) => {
  testFields = Array.isArray(testFields) ? testFields : [testFields];
  const testOp = testFields.reduce((acc, fieldName) => {
    const value = payload[fieldName];
    acc.push({ op: 'test', path: '/' + fieldName, value: value });
    return acc;
  }, []);

  replaceFields = Array.isArray(replaceFields)
    ? replaceFields
    : [replaceFields];

  const replaceOp = replaceFields.reduce((acc, fieldName) => {
    const value = payload[fieldName];
    acc.push({ op: 'replace', path: '/' + fieldName, value: value });
    return acc;
  }, []);

  testFields.forEach(name => delete payload[name]);
  replaceFields.forEach(name => delete payload[name]);

  return [...testOp, ...replaceOp, ...commonPatchParser(payload)];
};

export const onlyTestPayload = field => value => [
  {
    op: 'test',
    path: '/' + field,
    value: value
  }
];
export const confirmNewEmail = onlyTestPayload('newEmailConfirmToken');

export const changeEmailPatchParser = replaceWithTest(
  ['password', 'recaptcha'],
  'email'
);

/**
 * @param path
 * @param params
 * @returns {string}
 */
const createUrl = (path: any[] | string, params = {}): string => {
  if (params['id']) {
    path = patchPath(path, params['id']);
  }

  path = patchPath(path, {});

  return getExperimentalApiUrl() + pathFromArray(path);
};

/**
 * @experimental
 *
 * @version it's draft version for task
 *
 * should be full implemented in the future
 * @link https://hq.atlaz.io/boards/1(popup:tasks/65867)
 */
@Injectable()
export class AtlazApiService {
  public accessToken = '';

  constructor(private _http: HttpClient) {}

  private catcher = (method: string, payload?: any) => response => {
    console.log('API RESPONSE: ', JSON.stringify(response));
    if (response.statusCode === 401) {
      payload
        ? console.warn('API', method.toUpperCase(), payload)
        : console.warn('API', method.toUpperCase());
    } else {
      payload
        ? console.error('API', method.toUpperCase(), payload)
        : console.error('API', method.toUpperCase());
    }
    return observableThrowError(response);
  };

  public get(path: any[] | string, params = {}) {
    path = patchPath(path);
    const queryParams = Object.assign({}, params);

    if (queryParams.hasOwnProperty('id')) {
      delete queryParams['id'];
    }
    path.push(queryParams);

    const url = createUrl(path, queryParams);

    return this._http
      .get(
        url,
        this.getOptions({
          'Content-Type': 'application/json',
          'X-HTTP-Method-Override': 'GET'
        })
      )
      .pipe(catchError(this.catcher('get')));
  }

  public post(
    path,
    payload: any = {},
    parseFn: ResponseParser = noParse,
    parseRequestFn = noParse
  ) {
    const url = createUrl(path);

    const postPayload = parseRequestFn(payload);

    return this._http
      .post(
        url,
        postPayload,
        this.getOptions({
          'Content-Type': 'application/json',
          'X-HTTP-Method-Override': 'POST'
        })
      )
      .pipe(catchError(this.catcher('post', postPayload)), map(parseFn));
  }

  public patch(
    path,
    payload: any = {},
    parseResponseFn: ResponseParser = noParse,
    parseRequestFn = commonPatchParser
  ) {
    const url = createUrl(path, payload);
    if (payload.hasOwnProperty('id')) {
      payload = { ...payload };
      delete payload.id;
    }

    const patchPayload = parseRequestFn(payload);

    return this._http
      .patch(
        url,
        patchPayload,
        this.getOptions({
          'Content-Type': 'application/json-patch+json',
          'X-HTTP-Method-Override': 'PATCH'
        })
      )
      .pipe(
        catchError(this.catcher('patch', patchPayload)),
        map(parseResponseFn)
      );
  }

  public deleteRequest(path, parseFn: ResponseParser = noParse) {
    const url = createUrl(path);

    return this._http
      .delete(
        url,
        this.getOptions({
          'Content-Type': 'application/json',
          'X-HTTP-Method-Override': 'DELETE'
        })
      )
      .pipe(catchError(this.catcher('delete')), map(parseFn));
  }

  public getMock(path): Observable<any> {
    const url = `assets/${path}`;
    return this._http.get(url);
  }

  private getOptions(customHeaders = {}) {
    const headers = new HttpHeaders({
      'Atlaz-API-Version': '2016-02-29',
      ...customHeaders
    });

    return { headers: headers, withCredentials: true };
  }
}
