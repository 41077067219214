import {
  JsonApiModelsResponse,
  JsonApiSingeModelResponse
} from '../services/api.service/http-response';
export type ModelId = string | number;

export interface Model<T> {
  id: string | number;
  type: T;

  attributes: any;
  relationships: any;
}

export type Relation<T> = {
  id: ModelId;
  type: T;
};

export type OneToMany<T> = {
  data: Relation<T>;
};

export type ManyToMany<T> = {
  data: Relation<T>[];
};

export enum RelationType {
  OneToMany = 1,
  ManyToMany = 2
}

export const getRelationType = relation =>
  Array.isArray(relation.data)
    ? RelationType.ManyToMany
    : RelationType.OneToMany;

const checkRelation = (relation, expectedType) =>
  getRelationType(relation) === expectedType;

export const isOneToMany = relation =>
  checkRelation(relation, RelationType.OneToMany);
export const isManyToMany = relation =>
  checkRelation(relation, RelationType.ManyToMany);

export const getRelatedModels = (
  model: JsonApiSingeModelResponse<any>,
  relationName: string,
  ModelClass
): BaseModel[] => {
  let relations = <Relation<any>[]>model.data.relationships[relationName][
    'data'
  ];
  const result = [];
  const includedModels = model.included || [];
  const matchRelation = (included, relation) => {
    if (included.id === relation.id && included.type === relation.type) {
      result.push(included);
      return true;
    }
  };
  if (Array.isArray(relations)) {
    includedModels.forEach(included =>
      relations.some(rel => matchRelation(included, rel))
    );
  } else {
    includedModels.some(included => matchRelation(included, relations));
  }

  return result.map(data => new ModelClass({ data, included: model.included }));
};

export const getOne = (
  model: JsonApiSingeModelResponse<any>,
  relationName: string,
  ModelClass
) => getRelatedModels(model, relationName, ModelClass)[0];
export const getMany = (
  model: JsonApiSingeModelResponse<any>,
  relationName: string,
  ModelClass
) => getRelatedModels(model, relationName, ModelClass);

export class BaseModel {
  protected originalResponse: JsonApiSingeModelResponse<any>;

  constructor(response) {
    this.originalResponse = response;
  }

  get id() {
    return this.originalResponse.data.id;
  }

  get type() {
    return this.originalResponse.data.type;
  }

  get attributes() {
    return this.originalResponse.data.attributes;
  }

  get relationships() {
    return this.originalResponse.data.relationships;
  }

  get included() {
    return this.originalResponse.included;
  }
}

export const getModeList = (resp: JsonApiModelsResponse<any>, ModelClass) =>
  resp.data.map(
    oneModelData =>
      new ModelClass({
        data: oneModelData,
        included: resp.included
      })
  );
