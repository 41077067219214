"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FocusDirective = /** @class */ (function () {
    function FocusDirective(_element) {
        this._element = _element;
    }
    FocusDirective.prototype.ngAfterViewInit = function () {
        if (this.focus) {
            this._element.nativeElement.focus();
        }
    };
    return FocusDirective;
}());
exports.FocusDirective = FocusDirective;
