"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../../environments/environment");
var UploadFileService = /** @class */ (function () {
    function UploadFileService() {
    }
    UploadFileService.prototype.makeFileRequest = function (params, files) {
        var url = environment_1.environment.apiUrl + 'files';
        var result = {};
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            var xhr = new XMLHttpRequest();
            formData.append('file', files, files.name);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 201) {
                        resolve(JSON.parse(xhr.response));
                    }
                    else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Atlaz-API-Version', '2016-02-29');
            xhr.withCredentials = true;
            xhr.send(formData);
        });
    };
    return UploadFileService;
}());
exports.UploadFileService = UploadFileService;
