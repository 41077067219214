"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["@media screen and (max-height:600px){.form_page[_ngcontent-%COMP%]{margin-top:0}.form_message[_ngcontent-%COMP%]{margin-top:35px}.form_message_wrap[_ngcontent-%COMP%], .form_page_wrap[_ngcontent-%COMP%]{justify-content:flex-start;min-height:500px}}.google_button[_ngcontent-%COMP%]{font-size:16px;height:40px;line-height:40px;background-color:#fff;color:#505f79;cursor:pointer;display:inline-block;border-radius:3px;border:none;position:relative;box-shadow:0 3px 5px 0 rgba(36,50,66,.2);transition:box-shadow .15s;margin:8px 0 20px}.google_button[_ngcontent-%COMP%]:hover{box-shadow:0 8px 9px 0 rgba(36,50,66,.12)}.google_logo[_ngcontent-%COMP%]{background:url(/assets/img/google-oauth.svg) top left no-repeat;display:inline-block;vertical-align:middle;width:26px;height:26px;position:absolute;left:32px;top:8px}"];
exports.styles = styles;
