"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ABadRequestComponent = /** @class */ (function () {
    function ABadRequestComponent() {
    }
    ABadRequestComponent.prototype.ngOnInit = function () {
    };
    return ABadRequestComponent;
}());
exports.ABadRequestComponent = ABadRequestComponent;
