import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable()
export class UploadFileService {

  makeFileRequest(params: Array<string>, files: File): Promise<any> {

    let url = environment.apiUrl + 'files';

    let result = {};

    return new Promise((resolve, reject) => {
      let  formData = new FormData();
      let xhr = new XMLHttpRequest();

      formData.append('file', files, files.name);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('POST', url, true);

      xhr.setRequestHeader('Atlaz-API-Version', '2016-02-29');

      xhr.withCredentials = true;
      xhr.send(formData);
    });
  }

}
