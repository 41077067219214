import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { companyPortalUrl } from '../../util/url';
import { ActivatedRoute } from '@angular/router';
import { Company } from '../../core/models/company';
import { User } from '../../core/models/user';

@Component({
  selector: 'choose-compnay',
  templateUrl: './choose-compnay.component.html',
  styleUrls: ['./choose-compnay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseCompanyComponent implements OnInit {
  private _companies: Company[];
  public companies: Company[];
  public companyUrls = {};
  public user: User;

  constructor(private _route: ActivatedRoute) {}

  ngOnInit() {
    this._companies = <Company[]>this._route.snapshot.data['companies'];
    this.companies = Array.isArray(this._companies)
      ? this._companies.filter(item => !item.attributes.deleted)
      : [];
    this.user = <User>this._route.snapshot.data['user'];
    const pathInWebApp = this._route.snapshot.queryParams['furtherPath']
      ? '/' + this._route.snapshot.queryParams['furtherPath']
      : '';
    if (pathInWebApp) {
      const notDemoCompanies = this.companies.filter(
        company => !company.attributes.isDemo
      );
      if (notDemoCompanies.length === 1) {
        window.location.href =
          companyPortalUrl(notDemoCompanies[0]) + pathInWebApp;
      }
    }
    this.companyUrls = this.companies.reduce((acc, company) => {
      acc[company.id] = companyPortalUrl(company) + pathInWebApp;
      return acc;
    }, {});
  }
}
