import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { ROLLBAR_TOKEN } from './rollbar';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  private _rollbar: Rollbar;

  constructor(@Inject(ROLLBAR_TOKEN) rollbar) {
    this._rollbar = rollbar;
  }

  handleError(err: any): void {
    console.error(err.originalError || err);
    this._rollbar.error(err.originalError || err);
  }
}
