import {
  Component, ChangeDetectionStrategy, Input, HostBinding, HostListener
} from '@angular/core';

@Component({
  selector: '[a-context-menu-item]',
  template: `
    <ng-content *ngIf="!divider"></ng-content>`,
  styleUrls: [ './a-context-menu-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AContextMenuItemComponent {

  @Input() divider = false;

  @HostBinding('class.divider') get getDivider() {
    return this.divider;
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    if (this.divider) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    }
  }

}
