import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '../../../core/services/auth.service/auth.service';
import { ROUTER_PATH } from '../../../constants';
import { isPresent } from '../../../util/object';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable()
export class LoginFormGuard implements CanActivate {
  public returnUrl = '';
  furtherPathQP;

  private returnPath = [ROUTER_PATH.CHOOSE_COMPANY];

  constructor(private _authService: AuthService, private _router: Router) {}

  authHook() {
    this._authService.isLoggedIn$
      .pipe(filter(isPresent), take(1))
      .subscribe(authorized => this.redirect());
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authService.isLoggedIn$.pipe(
      take(1),
      tap(_ => this.initReturn(route)),
      map(isLogged => (isLogged ? this.redirect() : true))
    );
  }

  private redirect(): false {
    if (this.returnUrl) {
      window.location.href = this.returnUrl;
    } else {
      this._router.navigate(this.returnPath, {
        queryParams: this.furtherPathQP
      });
    }
    return false;
  }

  private initReturn(route: ActivatedRouteSnapshot) {
    if (
      route.queryParams &&
      route.queryParams['return_url'] &&
      !route.queryParams['return_url'].includes('javascript')
    ) {
      const returnPath = route.queryParams['return_path'];
      this.returnUrl = route.queryParams['return_url'];
      if (returnPath) {
        this.returnUrl +=
          (this.returnUrl.indexOf('#') > -1 ? '&' : '#') +
          'return_path=' +
          encodeURIComponent(returnPath);
      }
    } else if (route.queryParams && route.queryParams['return_path']) {
      this.returnPath = [route.queryParams['return_path']];
    } else if (route.queryParams && route.queryParams['furtherPath']) {
      this.furtherPathQP = { furtherPath: route.queryParams['furtherPath'] };
    }
  }
}
