import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../../core/models/user';
import { ROUTER_PATH } from '../../constants';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent {
  @Input() user: User;

  public isUserMenuVisible = false;
  public toUserProfile = ['/', ROUTER_PATH.PROFILE];
  public toLogout = ['/', ROUTER_PATH.LOGOUT];

  public onToggleMenu() {
    this.isUserMenuVisible = !this.isUserMenuVisible;
  }
}
