"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("../auth.service/auth.service");
var operators_1 = require("rxjs/operators");
var ChooseCompanyResolver = /** @class */ (function () {
    function ChooseCompanyResolver(_authService) {
        this._authService = _authService;
    }
    ChooseCompanyResolver.prototype.resolve = function (route, state) {
        return this._authService.activeUser$.pipe(operators_1.map(function (user) { return user.getCompanies(); }), operators_1.take(1));
    };
    return ChooseCompanyResolver;
}());
exports.ChooseCompanyResolver = ChooseCompanyResolver;
