import { Injectable } from '@angular/core';
import { AuthService } from '../../auth.service/auth.service';
import { User } from '../../../models/user';
import { platform } from '../../../../util/platform';
import { isPresent } from '../../../../util/object';
import { getUtmFromCurrentUri } from '../../../../util/url';
import { debounceTime, filter } from 'rxjs/operators';

interface SegmentInstance {
  track: Function;
  identify: Function;
}

const compareObjectKeys = (keys: string[]) => (a: any, b: any) =>
  keys.some(key => a[key] === b[key]);

@Injectable()
export abstract class SegmentBaseService {
  constructor(protected _authService: AuthService) {
    this._authService.activeUser$
      .pipe(
        filter(isPresent),
        // to init crashTracker first
        debounceTime(0)
      )
      .subscribe((user: User) => {
        const companies = user.getCompanies();
        this.getInstance().identify(user.id, {
          name: user.attributes.fullname,
          createdAt: user.attributes.createdAt,
          email: user.attributes.email,
          companies: companies
            .map(company => company.attributes.name)
            .join('; '),
          companySize: companies
            .map(
              company =>
                `${company.attributes.name}: ${company.attributes.companySize ||
                  'unspecified'}`
            )
            .join('; '),
          softwarePreferences: companies
            .map(
              company =>
                `${company.attributes.name}: ${company.attributes
                  .softwarePreferences || 'unspecified'}`
            )
            .join('; ')
        });
      });
  }

  abstract getInstance(): SegmentInstance;

  track(eventName, data = {}) {
    const utm = getUtmFromCurrentUri();
    this.getInstance().track(eventName, {
      ...data,
      ...this.getUserInfo(),
      ...utm
    });
  }

  getUserInfo() {
    return {
      userId: this._authService.activeUser
        ? this._authService.activeUser.id
        : null,
      email:
        this._authService.activeUser && this._authService.activeUser.attributes
          ? this._authService.activeUser.attributes.email
          : '',
      client: 'Web App',
      os: platform.os.toString(),
      browser: platform.name + ' ' + platform.version
    };
  }

  boardCreated(type: string) {
    this.track('BoardCreated', {
      type
    });
  }

  onboardingProjectCreated() {
    this.track('OnboardingProjectCreated');
  }

  onboardingMembersInvited() {
    this.track('OnboardingMembersInvited');
  }

  projectCreated() {
    this.track('ProjectCreated');
  }

  collectionCreated() {
    this.track('CollectionCreated');
  }

  taskCreated() {
    this.track('TaskCreated');
  }

  commentCreated(isReply) {
    this.track('TaskCreated', {
      level: isReply ? 'second' : 'first'
    });
  }

  invitationSent() {
    this.track('InvitationSent');
  }

  startTrelloImport(boardsCount, tasksCount) {
    this.track('TrelloImportStarted', {
      boardsCount,
      tasksCount
    });
  }

  startJiraImport(projectsCount, tasksCount) {
    this.track('JiraImportStarted', {
      projectsCount,
      tasksCount
    });
  }
}
